import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ProjectStudy, StudyRole, User, UserList } from "../../types/project";
import useProjectData from "../../hooks/useProjectData";
import { useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, 
    DialogContentText,
    MenuItem,
    Grid,
    Alert as MuiAlert,
    Paper as MuiPaper,
    TextField as MuiTextField,
    Typography,
    Divider as MuiDivider,
} from "@mui/material";
import { Trash2 as Trash } from 'react-feather'
import { Add as AddIcon, Save as SaveIcon } from "@mui/icons-material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import { Formik } from "formik";
import { PermissionLevel } from '../../utils/constants';

const Divider = styled(MuiDivider)(spacing);

const Alert = styled(MuiAlert)(spacing);

type UserProp = {
    user: User,
    index: number,
    updateUser: (user: User, index: number) => void,
}

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const UserInfoRow: React.FC<UserProp> = ({ user, index, updateUser }) => {
    const [open, setOpen] = React.useState(false);
    const [newStudy, setNewStudy] = React.useState({study: "", permissionLevel: 1});
    const { projectSettings } = useProjectData();

    const addStudyButtonClicked = () => {
        if (newStudy.study === "") return;
        let duplicate = false;
        user.roles.forEach((role: StudyRole) => {
            if (role.study === newStudy.study) duplicate = true;
        })
        if (duplicate) return;
        user.roles.push({...newStudy})
        updateUser(user ,index);
    }
    
    const deleteStudyButtonClicked = (studyIndex: number) => () => {
        user.roles.splice(studyIndex, 1);
        updateUser(user, index);
    };

    const studyKeySelected = (event: any) => {
        setNewStudy({...newStudy, study: event.target.value})
    };

    const studyPermissionLevelSelected = (event: any) => {
        setNewStudy({...newStudy, permissionLevel: event.target.value})
    };

    const getStudyName = (studyKey: string) => {
        let studyName = studyKey;
        projectSettings?.studies?.forEach((study: ProjectStudy) => {
            if (study.key === studyKey) {
                studyName = study.name;
            }
        })
        return studyName;
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {user.username}
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{PermissionLevel[user.permissionLevel] === undefined ? "Unknown" : PermissionLevel[user.permissionLevel]}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Study</TableCell>
                                        <TableCell align="center">Role</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {user.roles.map((role: StudyRole, roleIndex: number) => (
                                        <TableRow key={role.study}>
                                            <TableCell align="center">
                                                {getStudyName(role.study)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {PermissionLevel[role.permissionLevel]}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton 
                                                    aria-label="delete" 
                                                    sx={{mt: '1px', ml:'25px'}} 
                                                    onClick={deleteStudyButtonClicked(roleIndex)}>
                                                    <Trash />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                type="study"
                                                name="study"
                                                label="Study"
                                                value={newStudy.study}
                                                onChange={studyKeySelected}
                                                size="small"
                                                fullWidth
                                                my={3}
                                                select
                                            >
                                                {projectSettings?.studies.filter((s) => (s.key !== "" && s.name !== "")).map((study, index) => (
                                                    <MenuItem key={index} value={study.key}>{study.name}</MenuItem>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                        <TableCell>     
                                            <TextField
                                                type="permissionLevel"
                                                name="permissionLevel"
                                                label="Permission Level"
                                                value={newStudy.permissionLevel}
                                                onChange={studyPermissionLevelSelected}
                                                size="small"
                                                fullWidth
                                                my={3}
                                                select
                                            >
                                                {(Object.keys(PermissionLevel) as Array<keyof typeof PermissionLevel>).filter(p => (Number.isNaN(+p) && p !== 'Invalid')).map((level, index) => (
                                                    <MenuItem key={index} value={PermissionLevel[level]}>{level}</MenuItem>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="primary" onClick={addStudyButtonClicked}>
                                                <AddIcon />
                                                New Study
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

type UserTableProps = {
    userList: UserList,
}

const Paper = styled(MuiPaper)(spacing);

const ManageUsersTable: React.FC<UserTableProps> = ({userList}) => {
    const { sendInvite, projectSettings, updateProjectUsers } = useProjectData();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [users, setUsers] = React.useState(userList);
    const [usersHaveChanged, setUsersHaveChanged] = React.useState(false);

    useEffect(() => {
        setUsers(userList);
    }, [userList]);

    const handleSendInvite = async(email: string, permissionLevel: PermissionLevel) => {
        // before sending invite check if the invited user exists in the user list. if not only then send invite
        if (!userList?.filter(user => user.email === email).length) {
            const newUser = {
                email: email,
                permissionLevel: permissionLevel
            }
            return await sendInvite(projectSettings?.key!, newUser);
        }
        else {
            // show message that invited user is already added
            setOpenDialog(true);
        }
    }

    const handleClose = () => {
        setOpenDialog(false);
    };

    const updateUser = (user: User, index: number) => {
        if (!users || index < 0 || index >= users.length) return;
        setUsers([...users.slice(0, index), user, ...users.slice(index + 1)]);
        setUsersHaveChanged(true);
    }

    const saveUserList = () => {
        if (users != null && projectSettings != null) {
            updateProjectUsers(projectSettings.key, users).then(() => {
                setUsersHaveChanged(false);
            }).catch(() => {
                // TODO: Handle this error (:P)
                // Maybe show an error popup or something
            });
        }
    }

    if (users) {
        return (
            <React.Fragment>
                <Typography variant="h3" gutterBottom display="inline">
                    Manage Users
                </Typography>           
                { usersHaveChanged && 
                    <Paper sx={{ borderTop: '1px solid cornflowerblue', borderRadius: '0px', position: 'fixed', bottom: 0, right: 16, zIndex: 100, left: { xs: 0, md: 258 }}} elevation={3}>
                            <Button
                                sx={{width:'200px', ml: `calc(50% - 100px)`, mt: '25px', mb: '25px' }}
                                variant="contained"
                                color="primary"
                                onClick={saveUserList}
                                startIcon={<SaveIcon sx={{ mr: 1 }} />} >
                                Save changes 
                            </Button>
                    </Paper>
                }
                <Divider my={6} />
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead sx={{
                                    backgroundColor: "cornflowerblue"
                                }}>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell width="30%">Username</TableCell>
                                        <TableCell width="50%">Email Address</TableCell>
                                        <TableCell width="20%">Role</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user, index) => (
                                        <UserInfoRow key={user.username} user={user} index={index} updateUser={updateUser} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Invite a new user
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={{
                                email: "",
                                permissionLevel: PermissionLevel.Participant,
                                submit: false,
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email("Must be a valid email")
                                    .max(255, "Maximum 255 characters")
                                    .required("Email is required"),
                                permissionLevel: Yup.number().required("Permission Level is required")
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                try {
                                    setSubmitting(true);
                                    const pendingInvite = await handleSendInvite(values.email, values.permissionLevel);
                                    if (pendingInvite) {
                                        setErrors({ submit: "User has already been invited" });
                                        setStatus({ success: false });
                                    }
                                    else {
                                        setStatus({ success: true });
                                    }
                                    setSubmitting(false);
                                } catch (error: any) {
                                    const message = error.raw || "Something went wrong";
                                    setStatus({ success: false });
                                    setErrors({ submit: message });
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({
                                errors,
                                status,
                                handleBlur,
                                handleSubmit,
                                handleChange,
                                touched,
                                values,
                                isValid,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    {errors.submit && (
                                        <Alert mt={2} mb={3} severity="warning">
                                        {errors.submit}
                                        </Alert>
                                    )}
                                    {status?.success && (
                                        <Alert mt={2} mb={3} severity="success">
                                            Sent Invite Successfully
                                        </Alert>
                                    )}
                                    <TextField
                                        name="email"
                                        label="Email"
                                        value={values.email}
                                        size="small"
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        my={2}
                                    />
                                    <TextField
                                        type="permissionLevel"
                                        name="permissionLevel"
                                        label="Permission Level"
                                        value={values.permissionLevel}
                                        size="small"
                                        error={Boolean(touched.permissionLevel && errors.permissionLevel)}
                                        fullWidth
                                        helperText={touched.permissionLevel && errors.permissionLevel}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        my={3}
                                        select
                                    >
                                        {(Object.keys(PermissionLevel) as Array<keyof typeof PermissionLevel>).filter(p => (Number.isNaN(+p) && p !== 'Invalid')).map((level, index) => (
                                            <MenuItem key={index} value={PermissionLevel[level]}>{level}</MenuItem>
                                        ))}
                                    </TextField>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={!isValid}
                                    >
                                        Send Invite
                                    </Button>
                                    <Dialog
                                        open={openDialog}
                                        onClose={handleClose}
                                    >
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                This user is already a member!
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose}>Ok</Button>
                                        </DialogActions>
                                    </Dialog>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
    else {
        return (
            <React.Fragment>
                <Typography variant="h3" gutterBottom display="inline">
                    Manage Users
                </Typography>      
                <Divider my={6} />
                </React.Fragment>
        )
    }
}

export default ManageUsersTable;