import { createContext, ReactNode, useReducer } from "react";

import { ActionMap } from "../types/auth";
import {
    NavigationContextType,
    NavigationState,
} from "../types/navigation";

const SELECT_SESSION = "SELECT_SESSION";
const SELECT_PARTICIPANT = "SELECT_PARTICIPANT";
const SELECT_PROJECT = "SELECT_PROJECT";

type NavigationActionTypes = {
    [SELECT_PROJECT]: {
        project: string;
        role: number;
    };
    [SELECT_PARTICIPANT]: {
        participant: string;
    };
    [SELECT_SESSION]: {
        session: string;
    };
};

const initialState: NavigationState = {
    role: -1,
    project: "",
    participant: "",
    session: "",
};

const NavigationReducer = (
    state: NavigationState,
    action: ActionMap<NavigationActionTypes>[keyof ActionMap<NavigationActionTypes>]
) => {
    switch (action.type) {
        case SELECT_PROJECT:
            return {
                ...state,
                project: action.payload.project,
                role: action.payload.role,
                participant: "",
                session: "",
            };
        case SELECT_PARTICIPANT:
            return {
                ...state,
                participant: action.payload.participant,
                session: "",
            };
        case SELECT_SESSION:
            return {
                ...state,
                session: action.payload.session,
            };
        default:
            return state;
    }
};

const NavigationContext = createContext<NavigationContextType | null>(null);

function NavigationProvider({ children }: { children: ReactNode }) {

    const [state, dispatch] = useReducer(NavigationReducer, initialState);

    // sets selected project name on the sidebar
    const setProject = (project: string, role: number) => {
        if (project === state.project && state.participant === "" && state.session === "") return;
        dispatch({
            type: SELECT_PROJECT,
            payload: {
                project: project,
                role: role,
            },
        });
    };

    const setParticipant = (participant: string) => {
        dispatch({
            type: SELECT_PARTICIPANT,
            payload: {
                participant: participant,
            },
        });
    };

    const setSession = (session: string) => {
        dispatch({
            type: SELECT_SESSION,
            payload: {
                session: session,
            },
        });
    };

    return (
        <NavigationContext.Provider
            value={{
                ...state,
                setProject,
                setParticipant,
                setSession,
            }}
        >
            {children}
        </NavigationContext.Provider>
    )
}

export { NavigationContext, NavigationProvider };
