import { SidebarItemsType } from "../../types/sidebar";
import { PermissionLevel } from '../../utils/constants';
import {
  Grid,
  Clipboard,
  User,
  BarChart2,
} from "react-feather";

const generateNavItems = (project: string, role: number, participant: string, session: string) => {
  
  let pagesSection: SidebarItemsType[] = [
    {
      href: "/projects/overview",
      icon: Grid,
      title: "Projects",
    },
  ];

  if (project && role >= PermissionLevel.Clinician) {
      let pageSection = {
        href: "/project",
        icon: Clipboard,
        title: project,
        children: [
          {
            href: "/project/overview",
            title: "Overview",
          },
        ]
      }
      if (role === PermissionLevel.Admin) {
          pageSection.children.push(
            {
                href: "/project/settings",
                title: "Settings",
            },
              {
                  href: "/project/manage-users",
                  title: "Manage Users",
              }
          )
      }
      if (role >= PermissionLevel.Clinician) {
        pageSection.children.push(
            {
                href: "/project/data",
                title: "Data",
            }
        );
      }
    pagesSection.push(pageSection)
  }

  if (participant) {
    let participantPages = {
        href: "/participant",
        icon: User,
        title: participant,
        children: [
          {
            href: "/participant/overview",
            title: "Overview",
          },
        ]
    }
    if (role >= PermissionLevel.Clinician) {
        participantPages.children.push({
            href: "/participant/addsession",
            title: "Add Session",
        })
    }
    pagesSection.push(participantPages)
  }

  if (session) {
    pagesSection.push(
      {
        href: "/session",
        icon: BarChart2,
        title: session,
        children: [
          {
            href: "/session/overview",
            title: "Overview",
          },
        ]
      }
    )
  }

  let navItems = [
    {
      title: "",
      pages: pagesSection,
    },
  ];

  return navItems;
};

export default generateNavItems;
