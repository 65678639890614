import React from "react";
import styled from "styled-components/macro";

import {
  Card as MuiCard,
  Button as MuiButton,
  Grid,
} from "@mui/material";
import { ArrowDownward as ArrowDownwardIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { downloadDataFile, downloadBlobFile } from "../../utils/io";

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

type FileDownloadCardProps = {
    filename: string;
    href?: string;
    type: "blob" | "file";
    project?: string;
}

const FileDownloadCard: React.FC<FileDownloadCardProps> = ({filename, href="", type, project=""}) => {
    const downloadClicked = () => {
        if (type === "blob") {
            downloadBlobFile(filename, href);
        }
        else {
            downloadDataFile(filename, project);
        }
    }

    return (
        <Card>
            <Grid container spacing={6}>
                <Grid item xs={8}>
                    {filename}
                </Grid>
                <Grid item xs={4}>
                    <Button mr={2} color="primary" variant="contained" onClick={downloadClicked}>
                        Download&nbsp;
                        <ArrowDownwardIcon />
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}

export default FileDownloadCard;
