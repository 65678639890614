import React, { useContext } from "react";
import styled, {ThemeContext} from "styled-components/macro";
import Chart from "react-chartjs-2";
import {CardContent, CardHeader as MuiCardHeader, Divider} from "@mui/material";
import { DoughnutOptions } from '../../types/project';
import { ChartData, ChartSeries } from '../../types/chart';
import FileDownloadButton from '../data/FileDownloadButton';
import { createBlobUrl, convertDoughnutChartToCSV } from '../../utils/io';
import { getDateTimeString } from '../../utils/datetime';

type WrapperProps = {
    height: number;
}

const ChartWrapper = styled.div<WrapperProps>`
  height: ${(props) => props.height}px;
`;

const CardHeader = styled(MuiCardHeader)`
  min-height: 50px;
  background: ${(props) => props.theme.seasPalette.blue};
  color: #FFF;
`;

type DoughnutChartProps = {
    height: number,
    doughnutOptions: DoughnutOptions,
    chartData: ChartData,
    isFiltered: ( id: string ) => boolean,
}

const generateDoughnutChartData = (data: ChartData, doughnutOptions: DoughnutOptions, colors: string[], isFiltered: ( id: string ) => boolean) => {
    data = data || [];
    let chartData: { labels: string[], data: number[], colors: string[] } = {
        labels: [],
        data: [],
        colors: [],
    };
    for (let i = 0; i < data.length; i++) {
        const dataPoint: ChartSeries = data[i];
        if (isFiltered(dataPoint.name)) continue;
        const dataPointPlots = dataPoint.plots;
        let plotMetrics = dataPointPlots.filter((plotToFilter: any) => {
            if (plotToFilter[doughnutOptions.metric.id]) {
                return true;
            }
            return false;
        }).map((eachPlot: any) => eachPlot[doughnutOptions.metric.id]);
        if (plotMetrics.length === 0) continue;
        // default value of chartData is sum of the metric values
        let chartSectorData = plotMetrics.reduce((partialSum: any, a: any) => partialSum + a, 0);
        if (doughnutOptions.metric.type === 'average') {
            chartSectorData = chartSectorData / plotMetrics.length;
        }
        const sectorLabel = dataPoint.name;
        if (!sectorLabel) continue;
        chartData.labels.push(sectorLabel);
        chartData.data.push(chartSectorData);
        chartData.colors.push(colors[i%colors.length]);
    }
    return {
        labels: chartData.labels,
        datasets: [
            {
                data: chartData.data,
                backgroundColor: chartData.colors,
                borderColor: "transparent",
            },
        ],
        xAxisType: 'category'
    };
};

function DoughnutChart({ height, doughnutOptions, chartData, isFiltered }: DoughnutChartProps) {
    const theme = useContext(ThemeContext);
    const chartTitle = doughnutOptions?.metric?.name;
    const doughnutChartData = generateDoughnutChartData(chartData, doughnutOptions, theme.charts.colors, isFiltered);
    const doughnutChartOptions = {
        animation: {
            duration: 0
        },
        maintainAspectRatio: false,
        cutout: "65%",
        plugins: {
            legend: {
                display: true,
            },
        },
    };

    // Data File Info
    const filename = doughnutOptions?.sectors + "-" + doughnutOptions?.metric?.key + "-" + getDateTimeString() + ".csv";
    const href = createBlobUrl(convertDoughnutChartToCSV(doughnutChartData, doughnutOptions));

    return (
        <React.Fragment>
            <CardHeader
                title={chartTitle}
                action={<FileDownloadButton filename={filename} href={href}/>}
            />
            <Divider />
            <CardContent>
                <ChartWrapper height={height}>
                    <Chart type="doughnut" data={doughnutChartData} options={doughnutChartOptions} />
                </ChartWrapper>
            </CardContent>

        </React.Fragment>
    );
}

export default DoughnutChart;
