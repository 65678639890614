import React from "react";

// Layouts
import BaseLayout from "./layouts/Base";
import DashboardLayout from "./layouts/Dashboard";

import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Page404 from "./pages/auth/Page404";
import Projects from "./pages/projects/Projects";
import ProjectData from "./pages/project/ProjectData";
import ProjectOverview from "./pages/project/ProjectOverview";
import ParticipantOverview from "./pages/participant/ParticipantOverview";
import ParticipantAddSessionPage from "./pages/participant/AddSessionPage";
import SessionOverview from "./pages/session/SessionOverview";
import ProjectSettings from "./pages/project/ProjectSettings";
import ManageUsers from "./pages/project/ManageUsers";
import Invite from "./pages/project/Invite";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Context Providers
import { NavigationProvider } from "./contexts/NavigationContext";
import { ProjectProvider } from "./contexts/ProjectContext";
import { ParticipantProvider } from "./contexts/ParticipantContext";
import { SessionProvider } from "./contexts/SessionContext";

const routes = [
    {
        path: "/",
        element: <BaseLayout />,
        children: [
            {
                path: "",
                element: <SignIn />,
            },
            {
                path: "/signup",
                element: <SignUp />,
            },
            {
                path: "/invitation",
                element: (
                    <ProjectProvider>
                        <Invite />
                    </ProjectProvider>
                ),
            },
        ]
    },
    {
        path: "projects",
        element: (
            <AuthGuard>
                <NavigationProvider>
                    <ProjectProvider>
                        <DashboardLayout />
                    </ProjectProvider>
                </NavigationProvider>
            </AuthGuard>
        ),
        children: [
            {
                path: "overview",
                element: <Projects />,
            },
        ],
    },
    {
        path: "project",
        element: (
            <AuthGuard>
                <NavigationProvider>
                    <ProjectProvider>
                        <ParticipantProvider>
                            <DashboardLayout />
                        </ParticipantProvider>
                    </ProjectProvider>
                </NavigationProvider>
            </AuthGuard>
        ),
        children: [
            {
                path: "overview",
                element: <ProjectOverview />,
            },
            {
                path: "settings",
                element: <ProjectSettings />,
            },
            {
                path: "manage-users",
                element: <ManageUsers />,
            },
            {
                path: "data",
                element: <ProjectData />,
            },
        ],
    },
    {
        path: "participant",
        element: (
            <AuthGuard>
                <NavigationProvider>
                    <ProjectProvider>
                        <ParticipantProvider>
                            <SessionProvider>
                                <DashboardLayout />
                            </SessionProvider>
                        </ParticipantProvider>
                    </ProjectProvider>
                </NavigationProvider>
            </AuthGuard>
        ),
        children: [
            {
                path: "overview",
                element: <ParticipantOverview />,
            },
            {
                path: "addsession",
                element: <ParticipantAddSessionPage />,
            }
        ],
    },
    {
        path: "session",
        element: (
            <AuthGuard>
                <NavigationProvider>
                    <ProjectProvider>
                        <ParticipantProvider>
                            <SessionProvider>
                                <DashboardLayout />
                            </SessionProvider>
                        </ParticipantProvider>
                    </ProjectProvider>
                </NavigationProvider>
            </AuthGuard>
        ),
        children: [
            {
                path: "overview",
                element: <SessionOverview />,
            },
        ],
    },
    {
        path: "*",
        element: <BaseLayout />,
        children: [
            {
                path: "*",
                element: <Page404 />,
            },
        ],
    },
];

export default routes;
