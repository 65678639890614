import React, { useContext } from "react";
import styled, {ThemeContext} from "styled-components/macro";
import Chart from "react-chartjs-2";
import {
    CardContent,
    CardHeader as MuiCardHeader,
    Divider,
} from "@mui/material";
import { ChartOptions } from '../../types/project';
import { ChartData, ChartSeries } from '../../types/chart';
import FileDownloadButton from '../data/FileDownloadButton';
import { createBlobUrl, convertChartToCSV } from '../../utils/io';
import { getDateTimeString } from '../../utils/datetime';

type WrapperProps = {
  height: number;
}
const ChartWrapper = styled.div<WrapperProps>`
  height: ${(props) => props.height}px;
`;

type BarChartProps = {
    height: number,
    chartOptions: ChartOptions,
    chartData: ChartData,
    isFiltered: (id: string) => boolean;
}

const CardHeader = styled(MuiCardHeader)`
  min-height: 50px;
  background: ${(props) => props.theme.seasPalette.blue};
  color: #FFF;
`;

const generateBarChartData = (data: ChartData, chartOptions: ChartOptions, colors: string[], isFiltered: (id: string) => boolean) => {
    let dataSets = [];
    let labels: string[] = [];
    const getSeriesData = (series: ChartSeries) => {
        let seriesData: any = [];
        if (!series || !series.plots) {
            return seriesData;
        }
        for (let j = 0; j < series?.plots.length; j++) {
            let dataPoint = series.plots[j];
            if (isFiltered(dataPoint.participantID)) continue;
            const domainValue = dataPoint[chartOptions.xAxis];
            if (!domainValue && domainValue !== 0) continue;
            if (labels.indexOf(domainValue) === -1) labels.push(domainValue);
            seriesData.push({
                x: domainValue,
                y: dataPoint[chartOptions.yAxis.id]
            });
        }
        return seriesData;
    }
    data = data || [];
    for (let i = 0; i < data.length; i++) {
        let series = data[i];
        if(chartOptions.series === 'participantID' && isFiltered(series.name)) continue;
        const seriesData = getSeriesData(series);
        if (seriesData.length > 0) {
            dataSets.push({
                label: series.name,
                backgroundColor: colors[i % colors.length],
                borderColor: colors[i % colors.length],
                hoverBackgroundColor: colors[i % colors.length],
                hoverBorderColor: colors[i % colors.length],
                data: seriesData,
                barPercentage: 0.75,
                categoryPercentage: 0.5,
            });
        }
    }

    return {
        labels: labels,
        datasets: dataSets,
        xAxisType: 'category',
    }
}

const BarChart = ({height, chartOptions, chartData, isFiltered}: BarChartProps) => {
    const theme = useContext(ThemeContext);
    const barChartData = generateBarChartData(chartData, chartOptions, theme.charts.colors, isFiltered);
    const xAxisLabel = chartOptions.xAxis;
    const yAxisLabel = chartOptions.yAxis.name;
    const chartTitle = yAxisLabel;
    const barChartOptions = {
        animation: {
            duration: 0
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
            },
        },
        scales: {
            y: {
                grid: {
                    display: true,
                },
                stacked: false,
                beginAtZero: true,
                ticks: {
                    stepSize: 2,
                },
                title: {
                    display: true,
                    text: yAxisLabel
                }
            },
            x: {
                stacked: false,
                grid: {
                    color: "transparent",
                },
                title: {
                    display: true,
                    text: xAxisLabel
                }
            },
        },
    };

    // Data File Info
    const filename = chartOptions?.yAxis?.key + "-" + chartOptions?.xAxis + "-" + chartOptions?.series + "-" + getDateTimeString() + ".csv";
    const href = createBlobUrl(convertChartToCSV(barChartData, chartOptions));

    return (
        <React.Fragment>
            <CardHeader
                title={chartTitle}
                action={<FileDownloadButton filename={filename} href={href}/>}
            />
            <Divider />
            <CardContent>
                <ChartWrapper height={height}>
                  <Chart type='bar' data={barChartData} options={barChartOptions} />
                </ChartWrapper>
            </CardContent>
        </React.Fragment>
    );
};

export default BarChart;
