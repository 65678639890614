import { createContext, ReactNode, useReducer } from "react";

import { ActionMap } from "../types/auth";
import {
    ParticipantContextType,
    ParticipantState,
    Session,
} from "../types/participant";
import { ChartData } from "../types/chart";

import axios from "../utils/axios";
import { StatisticsAPIRequest } from '../types/project';

const SELECT_PARTICIPANT = "SELECT_PARTICIPANT";
const LOAD_SESSIONS = "LOAD_SESSIONS";
const LOAD_CHART_ONE_DATA = "LOAD_CHART_ONE_DATA";
const LOAD_CHART_TWO_DATA = "LOAD_CHART_TWO_DATA";
const LOADING_SESSIONS = "LOADING_SESSIONS";
const LOADING_CHART_ONE_DATA = "LOADING_CHART_ONE_DATA";
const LOADING_CHART_TWO_DATA = "LOADING_CHART_TWO_DATA";

type ParticipantActionTypes = {
    [SELECT_PARTICIPANT]: {
        selectedParticipant: string;
    };
    [LOAD_SESSIONS]: {
        sessionList: Session[];
    };
    [LOAD_CHART_ONE_DATA]: {
        chartData: ChartData;
    };
    [LOAD_CHART_TWO_DATA]: {
        chartData: ChartData;
    };
    [LOADING_SESSIONS]: undefined;
    [LOADING_CHART_ONE_DATA]: undefined;
    [LOADING_CHART_TWO_DATA]: undefined;
};

const initialState: ParticipantState = {
    selectedParticipant: "",
    sessionList: null,
    participantChartOneData: null,
    participantChartTwoData: null,
    sessionListLoading: false,
    participantChartOneDataLoading: false,
    participantChartTwoDataLoading: false,
};

const ParticipantReducer = (
    state: ParticipantState,
    action: ActionMap<ParticipantActionTypes>[keyof ActionMap<ParticipantActionTypes>]
) => {
    switch (action.type) {
        case SELECT_PARTICIPANT:
            return {
                ...state,
                selectedParticipant: action.payload.selectedParticipant,
                sessionList: null,
                participantStats: null,
                participantChartOneData: null,
                participantChartTwoData: null
            };
        case LOAD_SESSIONS:
            return {
                ...state,
                sessionList: action.payload.sessionList,
                sessionListLoading: false,
            };
        case LOAD_CHART_ONE_DATA:
            return {
                ...state,
                participantChartOneData: action.payload.chartData,
                participantChartOneDataLoading: false,
            };
        case LOAD_CHART_TWO_DATA:
            return {
                ...state,
                participantChartTwoData: action.payload.chartData,
                participantChartTwoDataLoading: false,
            };
        case LOADING_SESSIONS:
            return {
                ...state,
                sessionListLoading: true,
            };
        case LOADING_CHART_ONE_DATA:
            return {
                ...state,
                participantChartOneDataLoading: true,
            };
        case LOADING_CHART_TWO_DATA:
            return {
                ...state,
                participantChartTwoDataLoading: true,
            };
        default:
            return state;
    }
};

const ParticipantContext = createContext<ParticipantContextType | null>(null);

function ParticipantProvider({ children }: { children: ReactNode }) {

    const [state, dispatch] = useReducer(ParticipantReducer, initialState);

    const selectParticipant = (participant: string) => {
        if (state.selectedParticipant !== participant) {
            dispatch({
                type: SELECT_PARTICIPANT,
                payload: {
                    selectedParticipant: participant,
                },
            });
        }
    };

    const loadSessions = async (project: string, participant: string) => {
        dispatch({ type: LOADING_SESSIONS });
        const response = await axios.get("/api/session/listSessionsForParticipant", {
            params: { project: project, participantID: participant, metrics: [ "assistedSteps" ] }
        });
        const { sessions } = response.data;
        dispatch({
            type: LOAD_SESSIONS,
            payload: {
                sessionList: sessions,
            }
        })
    }

    const loadParticipantChartOneData = async (request: StatisticsAPIRequest) => {
        dispatch({ type: LOADING_CHART_ONE_DATA });
        const response = await axios.get("/api/project/statistics", { params: { ...request } });
        const { data } = response.data;
        dispatch({
            type: LOAD_CHART_ONE_DATA,
            payload: {
                chartData: data,
            },
        });
    };

    const loadParticipantChartTwoData = async (request: StatisticsAPIRequest) => {
        dispatch({ type: LOADING_CHART_TWO_DATA });
        const response = await axios.get("/api/project/statistics", { params: { ...request } });
        const { data } = response.data;
        dispatch({
            type: LOAD_CHART_TWO_DATA,
            payload: {
                chartData: data,
            },
        });
    };

    return (
        <ParticipantContext.Provider
            value={{
                ...state,
                selectParticipant,
                loadSessions,
                loadParticipantChartOneData,
                loadParticipantChartTwoData,
            }}
        >
            {children}
        </ParticipantContext.Provider>
    )
};

export { ParticipantContext, ParticipantProvider };