import React, { useState } from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";

import { Hidden, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  background: #F7F9FC;//${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard: React.FC = ({ children }) => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Root>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
            <Hidden lgUp implementation="js">
                <Sidebar
                    PaperProps={{ style: { width: drawerWidth } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                />
            </Hidden>
            <Hidden mdDown implementation="css">
                <Sidebar
                    PaperProps={{ style: { width: drawerWidth } }}
                />
            </Hidden>
        </Drawer>
        <AppContent>
            <Navbar onDrawerToggle={handleDrawerToggle} />
            <MainContent p={12} sx={{ width: { xs: '100%', md: `calc(100vw - ${drawerWidth}px)` }}} >
                {children}
                <Outlet />
            </MainContent>
        </AppContent>
        </Root>
    );
};

export default Dashboard;
