import React from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import getTheme from "./theme";
import routes from "./routes";

import { AuthProvider } from "./contexts/JWTContext";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

function App() {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
        <Helmet
            titleTemplate="%s | Harvard Move Lab"
            defaultTitle="Harvard Move Lab Data Portal"
        />
        <StylesProvider jss={jss}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={getTheme()}>
                <ThemeProvider theme={getTheme()}>
                    <AuthProvider>{content}</AuthProvider>
                </ThemeProvider>
                </MuiThemeProvider>
            </StyledEngineProvider>
            </LocalizationProvider>
        </StylesProvider>
    </HelmetProvider>
  );
}

export default App;
