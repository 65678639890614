import {PaletteOptions} from "@mui/material/styles/createPalette";
const customBlue = {
    50: "#E9F0FB",
    100: "#C8DAF4",
    200: "#A3C1ED",
    300: "#7EA8E5",
    400: "#6395E0",
    500: "#4782DA",
    600: "#407AD6",
    700: "#376FD0",
    800: "#2F65CB",
    900: "#2052C2 ",
};
const palette: PaletteOptions = {
    mode: "light",
    primary: {
        main: customBlue[700],
        contrastText: "#FFF",
        light: customBlue[200],
        dark: customBlue[800],
    },
    secondary: {
        main: customBlue[500],
        contrastText: "#FFF",
        light: customBlue[200],
        dark: customBlue[800],
    },
    background: {
        default: "#F7F9FC",
        paper: "#FFF",
    },
}
export default palette;