import { green, grey } from "@mui/material/colors";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2 ",
};

const seasPalette = {
  crimson: "#a51c30",
  red: "#ed1b34",
  salmon: "#ec8f9c",
  black: "#000000",
  grey: "#93a1ad",
  green: "#4db848",
  limeGreen: "#cbdb2a",
  blue: "#4e88c7",
  skyBlue: "#95b5df",
  warmYellow: "#fcb315",
  yellow: "#ffde2d",
  turquoise: "#00aaad",
  aquamarine: "#77ced9",
  purple: "#946eb7",
  lavender: "#bb89ca",
};

const defaultVariant = {
  name: "DEFAULT",
  palette: {
    mode: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
      light: customBlue[200],
      dark: customBlue[800],
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
      light: customBlue[200],
      dark: customBlue[800],
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
  },
  charts: {
    colors: [
      seasPalette.crimson,
      seasPalette.blue,
      seasPalette.limeGreen,
      seasPalette.warmYellow,
      seasPalette.lavender,
      seasPalette.aquamarine,
      seasPalette.grey,
      seasPalette.skyBlue,
    ],
  },
  seasPalette: seasPalette,
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[100],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#233044",
    header: {
      color: grey[200],
      background: "#233044",
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#1E2A38",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
};

export default defaultVariant;
