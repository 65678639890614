import React, { useState, ChangeEvent } from "react";

import styled from "styled-components/macro";
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Grid,
    Box,
    FormControl,
    MenuItem,
    CardHeader,
    Typography,
    TextField,
    Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import useProjectData from '../../hooks/useProjectData';
import { TemplateMetricResponse } from '../../types/project';
import useParticipantData from '../../hooks/useParticipantData';

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const SuccessMessage = styled(Typography)`
    color: ${(props) => props.theme.seasPalette.green}
`;

const ErrorMessage = styled(Typography)`
    color: ${(props) => props.theme.seasPalette.red}
`;

const Divider = styled(MuiDivider)(spacing);

type SessionInfoProps = {
    metrics: any,
    updateMetric: (index: number, metric: TemplateMetricResponse) => void
}
const SessionInfo: React.FC<SessionInfoProps> = ({ metrics, updateMetric }) => {
    
    const handleMetricChange = (event: ChangeEvent<HTMLInputElement>) => {
        let metric = metrics[parseInt(event.target.name)];
        metric.value = event.target.value;
        updateMetric(parseInt(event.target.name), metric);
    }
    const metricObject = metrics.map((metric: TemplateMetricResponse, index: number) => {
        return (
            <Grid container item xs={12} key={index}>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column">
                        <Typography variant="subtitle2">
                            {metric.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    {
                        metric.type === 'select' && 
                        <FormControl sx={{ m: 1, minWidth: 180 }}>
                            <TextField
                                id={""+index}
                                onChange={handleMetricChange}
                                size="small"
                                value={metric.value}
                                name={""+index}
                                select >
                                {metric.options?.map((option: string, index: number) => (
                                    <MenuItem key={index} value={option}>{option}</MenuItem>
                                ))}
                                value={metric.value}
                            </TextField>
                        </FormControl>
                    }
                    {
                        metric.type === 'text' && 
                        <FormControl sx={{ m: 1, minWidth: 180 }}>
                            <TextField
                                type="text"
                                onChange={handleMetricChange}
                                name={""+index}
                                id={""+index}
                                value={metric.value}
                                variant="outlined" />
                        </FormControl>
                    }
                    {
                        metric.type === 'number' && 
                        <FormControl sx={{ m: 1, minWidth: 180 }}>
                            <TextField
                                type="number"
                                onChange={handleMetricChange}
                                name={""+index}
                                id={""+index}
                                value={metric.value}
                                variant="outlined" />
                        </FormControl>
                    }
                    {
                        metric.type === 'time' && 
                        <FormControl sx={{ m: 1, minWidth: 180 }}>
                            <TextField
                                type="time"
                                onChange={handleMetricChange}
                                name={""+index}
                                id={""+index}
                                value={metric.value}
                                variant="outlined" />
                        </FormControl>
                    }
                    {
                        metric.type === 'date' && 
                        <FormControl sx={{ m: 1, minWidth: 180 }}>
                            <TextField
                                type="date"
                                onChange={handleMetricChange}
                                name={""+index}
                                id={""+index}
                                value={metric.value}
                                variant="outlined" />
                        </FormControl>
                    }
                </Grid>
            </Grid>
        )
    })

    return (
        <React.Fragment>
            {metricObject}
        </React.Fragment>
    )
}

function AddSessionPage() {
    const { selectedProject, projectSettings, uploadSession } = useProjectData();
    const { selectedParticipant } = useParticipantData();
    const templates = projectSettings?.templates || [];
    const [templateIndex, setTemplateIndex] = useState(-1);
    const [sessionType, setSessionType] = useState("");
    const [sessionMetrics, setSessionMetrics] = useState([] as TemplateMetricResponse[]);
    const [submissionEvent, setSubmissionEvent] = useState("");
    const [submissionMessage, setSubmissionMessage] = useState("");
    function handleSessionTypeChange(event: React.ChangeEvent<any>) {
        const sessionTemplate = templates[event.target.value];
        setTemplateIndex(event.target.value);
        setSessionType(sessionTemplate.sessionType);
        let newMetrics: TemplateMetricResponse[] = [
            {
                key: 'date',
                name: 'Date',
                type: 'date',
                options: [],
                value: '',
            },
            {
                key: 'time',
                name: 'Time',
                type: 'time',
                options: [],
                value: '',
            },
            {
                key: 'deviceID',
                name: 'Device',
                type: 'select',
                options: projectSettings!.devices || [],
                value: '',
            },
        ];
        let metricResponses: TemplateMetricResponse[] = sessionTemplate.metrics.map((metric, index) => { return {...metric, value: ''} });
        newMetrics.push(...metricResponses)
        setSessionMetrics(newMetrics);
    }
    function submitSession(event: React.MouseEvent<HTMLButtonElement>) {
        setSubmissionMessage("");
        setSubmissionEvent("");
        // Format submission
        let newSession: Record<string, any> = {
            participantID: selectedParticipant,
            dataFiles: [],
            sessionType: sessionType,
        };
        for (let i = 0; i < sessionMetrics.length; i++) {
            let metric = sessionMetrics[i];
            if (metric.type === 'time') {
                // reformat time value
                newSession['time'] = metric.value.replace(':', '-') + "-00";
            }
            else {
                // Only add values that exist
                if (metric.value !== undefined && metric.value !== '' && metric.value !== null) newSession[metric.key] = metric.value;
            }
        }
        sendSessionToDatabase(newSession);
    }
    const sendSessionToDatabase = async (session: any) => {
        uploadSession(selectedProject, session)
        .then((result) => {
            setSubmissionEvent("success");
            setSubmissionMessage("Submission Succeeded!")
        })
        .catch((result) => {
            setSubmissionEvent("error");
            setSubmissionMessage("Submission Failed")
        });
    }
    function updateMetric(index: number, metric: TemplateMetricResponse) {
        let newState: TemplateMetricResponse[];
        newState = [ ...sessionMetrics.slice(0, index), metric, ...sessionMetrics.slice(index + 1) ];
        setSessionMetrics(newState);
    }
    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                Add A New Session
            </Typography>
            <Divider mt={2} mb={8}/>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card sx={{ border: "1px solid lightgrey" }}>
                        <CardContent>            
                            <Grid container spacing={6}>
                                <Grid item xs={2}>
                                    <Box
                                        height="100%"
                                        display="flex"
                                        justifyContent="center"
                                        flexDirection="column" >
                                        <Typography variant="subtitle2">
                                            SessionType:
                                        </Typography>
                                    </Box>
                                </Grid>    
                                <Grid item xs={10}>
                                    <FormControl sx={{ m: 1, minWidth: 160 }}>
                                        <TextField
                                            id="session-type"
                                            value={templateIndex}
                                            onChange={handleSessionTypeChange}
                                            fullWidth
                                            label="Session Type"
                                            size="small"
                                            select >
                                            <MenuItem value={-1}>{""}</MenuItem>
                                            {templates.map((template, index) => <MenuItem key={index} value={index}>{template.sessionType}</MenuItem>)}
                                        </TextField>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {
                    sessionType !== "" && 
                    <Grid item xs={12}>
                        <Card sx={{ border: "1px solid lightgrey" }}>
                            <CardHeader style={{backgroundColor: "aliceblue"}} title={sessionType} />
                            <CardContent>
                                <SessionInfo metrics={sessionMetrics} updateMetric={updateMetric}/>
                            </CardContent>
                        </Card>    
                    </Grid>
                }

                {
                    sessionType !== "" && 
                    <Grid item xs={12}>
                        <Card sx={{ border: "1px solid lightgrey", display:'flex', justifyContent: 'center' }}>
                            <CardContent>
                                <Button variant='outlined' onClick={submitSession}>
                                    Submit
                                </Button>
                                { submissionEvent === 'error' && <ErrorMessage>{submissionMessage}</ErrorMessage> }
                                { submissionEvent === 'success' && <SuccessMessage>{submissionMessage}</SuccessMessage> }
                            </CardContent>
                        </Card>    
                    </Grid>
                }
            
            </Grid>
        </React.Fragment>
    )
}

export default AddSessionPage;