import React from "react";
import styled from "styled-components/macro";

import {
  Button as MuiButton,
} from "@mui/material";
import { ArrowDownward as ArrowDownwardIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { downloadBlobFile } from "../../utils/io";

const Button = styled(MuiButton)(spacing);

type FileDownloadButtonProps = {
    filename: string;
    href: string;
};

const FileDownloadButton: React.FC<FileDownloadButtonProps> = ({ filename, href }) => {

    const downloadClicked = () => {
        downloadBlobFile(filename, href);
    }

    return (
        <Button mr={2} color="primary" variant="contained" onClick={downloadClicked} sx={{border: '1px solid white'}}>
            Download&nbsp;
            <ArrowDownwardIcon />
        </Button>
    )
}

export default FileDownloadButton;
