import React, { useEffect } from "react";
import styled  from "styled-components/macro";
import {
    Card as MuiCard,
    CardActionArea,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Grid,
    Box,
    Button,
    Typography,
} from "@mui/material";
import { toAmPmTimeString, toReadableDateString } from "../../utils/datetime";
import { Add as PlusIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useNavigate } from 'react-router-dom';
import useNavigationData from "../../hooks/useNavigation";
import useParticipantData from '../../hooks/useParticipantData';
import useProjectData from '../../hooks/useProjectData';
import useSessionData from '../../hooks/useSessionData';
import DataArea from "../../components/common/DataArea";
import { Session, SessionList } from '../../types/participant';
import { DataAreaSettings, ParticipantOverviewSettings } from '../../types/project';
import { convertDataAreaToStatisticsAPIRequest as createChartAPIRequest } from "../../utils/charts";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const Divider = styled(MuiDivider)(spacing);

const SectionTitle = styled.div`
  background: ${(props) => props.theme.seasPalette.blue};
  color: #FFF;
  margin: 24px -48px 0px -48px;
  padding: 24px 0px 24px 48px;
`;

type SessionCardProps = {
    session: Session,
    handleClick: (date: string, time: string) => void;
};

const SessionCard: React.FC<SessionCardProps> = ({
    session,
    handleClick,
}) => {
    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleClick(session.date, session.time);
    };
    const metrics = session.metrics || [];
    const sessionMetrics = metrics.map((metric, index) => {
        return (
            <Typography variant="h6" mb={3} key={index}>
                <Box fontWeight="fontWeightRegular">{metric.name}: {metric.value}</Box>
            </Typography>
        );
    });

    return (
        <Card>
            <CardActionArea onClick={onClick}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {toAmPmTimeString(session.time)}
                    </Typography>
                    <br />
                    <Typography variant="h6" mb={3}>
                        <Box fontWeight="fontWeightRegular">Device: {session.deviceID}</Box>
                    </Typography>
                    <Typography variant="h6" mb={3}>
                        <Box fontWeight="fontWeightRegular">Type: {session.sessionType}</Box>
                    </Typography>
                    {sessionMetrics}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

type SessionGridProps = {
    sessions: SessionList;
    handleClick: (date: string, time: string) => void;
}

const SessionGrid: React.FC<SessionGridProps> = ({
    sessions,
    handleClick,
}) => {
    let sessionGrid;
    if (sessions && sessions.length > 0) {
        sessions = sessions.sort((a: Session, b: Session) => {
            return a.time.localeCompare(b.time);
        });
        sessionGrid = sessions.map((session, index) => {
            return (
                <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                    <SessionCard
                        session={session}
                        handleClick={handleClick}
                    />
                </Grid>
            );
        })
    }
    else {
        sessionGrid = (
            <Grid item xs={12} lg={12} xl={12}>
                <Card>
                    <CardContent>{"No sessions to show!"}</CardContent>
                </Card>
            </Grid>
        );
    }
    return (
        <Grid container spacing={6} sx={{p: 5}}>
            {sessionGrid}
        </Grid>
    )
}

function groupSessionsIntoTrainingDays(sessions: SessionList) {
    if (!sessions) return {};
    let trainingDays: Record<string, SessionList> = {};
    for (let i = 0; i < sessions.length; i++) {
        let session = sessions[i];
        let date = session.date;
        let sessionType = session.sessionType;
        if (date && sessionType !== 'Startup') {
            if (trainingDays[date] === null || trainingDays[date] === undefined) {
                trainingDays[date] = [ session ];
            }
            else {
                trainingDays[date]!.push(session)
            }
        }
    }
    return trainingDays;
}

function ParticipantOverview() {
    const navigate = useNavigate();
    const { setParticipant } = useNavigationData();
    const { selectSession } = useSessionData();
    const { selectedProject, projectSettings } = useProjectData();
    const { 
        selectedParticipant, 
        sessionList,
        sessionListLoading,
        loadSessions,
        participantChartOneData, 
        participantChartTwoData,
        participantChartOneDataLoading, 
        participantChartTwoDataLoading,
        loadParticipantChartOneData, 
        loadParticipantChartTwoData 
    } = useParticipantData();

    const handleClick = (date: string, time: string) => {
      selectSession(date, time);
      navigate("/session/overview");
    }

    useEffect(() => {
        setParticipant(selectedParticipant);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      const load = async () => {
          await loadSessions(selectedProject, selectedParticipant);
      }
    if (!sessionList && !sessionListLoading) {
        load();
    }
    }, [selectedProject, selectedParticipant, loadSessions, sessionList, sessionListLoading]);

    const addNewSessionClicked = () => {
        // Go to add session page
        navigate("/participant/addsession");
    }

    useEffect(() => {
        const load = async () => {
            const chartOneOptions = projectSettings!.settings.participantOverview.dataAreaOne.options;
            let apiRequestOptions = createChartAPIRequest(selectedProject, chartOneOptions);
            apiRequestOptions.participantIDs = [selectedParticipant];
            await loadParticipantChartOneData(apiRequestOptions);
        };
        if (!participantChartOneData && !participantChartOneDataLoading) {
            load();
        }
    }, [projectSettings, loadParticipantChartOneData, participantChartOneData, participantChartOneDataLoading, selectedProject, selectedParticipant]);

    useEffect(() => {
        const load = async () => {
            const chartTwoOptions = projectSettings!.settings.participantOverview.dataAreaTwo.options;
            let apiRequestOptions = createChartAPIRequest(selectedProject, chartTwoOptions);
            apiRequestOptions.participantIDs = [selectedParticipant];
            await loadParticipantChartTwoData(apiRequestOptions);
        };
        if (!participantChartTwoData && !participantChartTwoDataLoading) {
            load();
        }
    }, [projectSettings, participantChartTwoData, participantChartTwoDataLoading, selectedProject, selectedParticipant, loadParticipantChartTwoData]);

    const trainingDays: Record<string, SessionList> = groupSessionsIntoTrainingDays(sessionList);
    let sessionGrid = Object.keys(trainingDays).sort().map((date, index) => {
        return (
            <Box sx={{ mt: 10, border: "1px solid lightgrey", width: '100%' }} key={index}>
                <Typography sx={{backgroundColor: "aliceblue", p: 10}} variant="h3">
                    {toReadableDateString(date)}
                </Typography>
                <SessionGrid sessions={trainingDays[date]} handleClick={handleClick} />
            </Box>
        )
    })

    const dataAreasList = projectSettings ? Object.keys(projectSettings!.settings.participantOverview).filter(k => k.includes('dataArea')) as (keyof ParticipantOverviewSettings)[] : [];
    const dataAreas = dataAreasList.map((area, index) => {
        let dataAreaSettings: DataAreaSettings = projectSettings!.settings.participantOverview[area] as DataAreaSettings;
        switch (area) {
            case 'dataAreaOne':
                return <DataArea 
                            key={index}
                            dataAreaSettings={dataAreaSettings}
                            chartData={participantChartOneData || []} />
            case 'dataAreaTwo':
                return <DataArea 
                            key={index}
                            dataAreaSettings={dataAreaSettings}
                            chartData={participantChartTwoData || []} />
            default:
                return <React.Fragment key={index} />
        }  
    });

    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                {selectedParticipant}
                <Button
                    sx={{ px: '10px', ml: '20px', mt: '25px', mb: '25px' }}
                    variant="contained"
                    color="primary"
                    onClick={addNewSessionClicked}
                    startIcon={<PlusIcon sx={{ mr: 1 }} />} >
                    Add New Session
                </Button>
            </Typography>

            <Divider my={6} />

            <Grid container spacing={6}>
                { dataAreas }
            </Grid>
            <SectionTitle>
                <Typography variant='h1'>
                    Training Days
                </Typography>
            </SectionTitle>
            <Grid container spacing={6}>
                {sessionGrid}
            </Grid>
        </React.Fragment>
    );
}

export default ParticipantOverview;
