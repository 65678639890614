import React, { useContext } from "react";
import {
    CardContent,
    Card as MuiCard,
    Paper,
    Box,
    CardHeader as MuiCardHeader
} from "@mui/material";
import { DataGrid, GridColDef, } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import { ThemeContext } from "styled-components/macro";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MetricSchema } from "../../types/project";
import { TableOptions } from '../../types/project';
import { ChartData } from '../../types/chart';
import FileDownloadButton from '../data/FileDownloadButton';
import { createBlobUrl, convertTableToCSV } from '../../utils/io';
import { getDateTimeString } from '../../utils/datetime';

const Card = styled(MuiCard)(spacing);

type WrapperProps = {
    height: number;
}

const TableWrapper = styled.div<WrapperProps>`
  height: ${(props) => props.height}px;
  width: 100%;
`;

const CardHeader = styled(MuiCardHeader)`
  min-height: 50px;
  background: ${(props) => props.theme.seasPalette.blue};
  color: #FFF;
`;

const convertToSmallSizeData = (data: any) => {
    data = data || [];
    let newRows = [];
    let newRowId = 0;
    for (let i = 0; i < data.length; i++) {
        const series = data[i];
        // Add participant ROW
        newRows.push({
            metric: 'participantID',
            value: series.participantID,
            id: newRowId,
        })
        newRowId++;
        for (const [key, value] of Object.entries(series)) {
            if (key === 'id' || key === 'participantID') continue;
            newRows.push({
                metric: key,
                value: value,
                id: newRowId,
            })
            newRowId++;
        }
    }
    return newRows;
}

const getColumns = (metrics: any, smallSize: boolean) => {
    let columns: GridColDef[];
    if (smallSize) {
        columns = [
            {   
                field: 'metric',
                headerName: 'Metric',
                flex: 1,
                sortable: false,
                resizable: false,
                valueGetter: (params) => {
                    if (params.row.metric === 'participantID') {
                        return params.row.value;
                    }
                    return params.value;
                }
            },
            {   
                field: 'value',
                headerName: 'Value',
                flex: 1,
                sortable: false,
                resizable: false,
                valueGetter: (params) => {
                    if (params.row.metric === 'participantID') {
                        return "";
                    }
                    return params.value;
                },
                renderCell: (params: any) =>  {
                    if (params.row.metric === 'participantID') return <React.Fragment></React.Fragment>
                    return (
                        <React.Fragment>
                            {parseFloat(params.value).toFixed(2)}
                        </React.Fragment>
                    )
                }
            }
        ]
    }
    else {
        columns = [{
            field: 'participantID',
            headerName: 'Participant',
            sortable: true,
            minWidth: 150,
            flex: 1,
        }];

        //.toFixed(2); // This regex deletes trailing zeroes if desired: .replace(/\.?0*$/,'');
        for (let i = 0; i < metrics.length; i++) {
            let metric = metrics[i];
            columns.push({
                field: metric.name,
                headerName: metric.name,
                sortable: true,
                minWidth: 150,
                flex: 1,
                //sortComparator: (a,b) =>  parseFloat(a) - parseFloat(b),
                renderCell: (params: any) =>  (
                    <React.Fragment>
                        {params.value.toFixed(2)}
                    </React.Fragment>
                )
            })
        }
    }
    return columns;
}

type DataTableProps = {
    height: number,
    tableOptions: TableOptions,
    chartData: ChartData,
}

const generateTableData = (data: ChartData, tableOptions: TableOptions) => {
    data = data || [];
    const metrics: MetricSchema[] = tableOptions.metrics;
    let dataSets = [];
    for (let i = 0; i < data.length; i++) {
        const series = data[i].plots;
        let seriesData: any = {};
        seriesData.participantID = data[i].name;
        seriesData.id = i;
        for (let j = 0; j < metrics.length; j++) {
            const metric = metrics[j];
            let filteredArray = series.filter((x: any) =>  (x[metric.id] !== null && x[metric.id] !== undefined));
            let total = series.reduce((prev: any, curr: any) => {
                let value = parseFloat(curr[metric.id]);
                if (Number.isNaN(value)) return prev;
                return prev + value;
            }, 0);
            if (metric.type === 'average') {
                total = total/Math.max(1, filteredArray.length);
            }
            seriesData[metric.name] = total;
        }
        dataSets.push(seriesData);
    }
    return dataSets;
}


function MetricTable({ height, tableOptions, chartData}: DataTableProps) {
    const theme = useContext(ThemeContext);
    const ixXs = useMediaQuery(theme.breakpoints.down("sm"));
    const metrics = tableOptions.metrics;
    const tableData = generateTableData(chartData, tableOptions);

    let dataTable;
    if (ixXs) {
        dataTable = (
            <DataGrid
                aria-label="data-table"
                rows={convertToSmallSizeData(tableData)}
                columns={getColumns(metrics, ixXs)}
                getRowClassName={(params) => params.row.metric === 'participantID' ? 'filled-row' : ''}
                rowHeight={48}
                disableColumnMenu
                disableColumnSelector
                disableColumnFilter
                hideFooter
            />
        )
    }
    else {
        dataTable = (
            <DataGrid
                aria-label="data-table"
                rows={tableData}
                columns={getColumns(metrics, ixXs)}
                rowHeight={48}
                disableColumnMenu
                hideFooter
            />
        )
    };

    // Data File Info
    const filename = "Participant-Metrics-Table-" + getDateTimeString() + ".csv";
    const href = createBlobUrl(convertTableToCSV(tableData, tableOptions));

    return (
        <Card mb={1}>
            <CardHeader
                title={'Metric Table'}
                action={<FileDownloadButton filename={filename} href={href}/>}
            />
            <CardContent>
                <Paper>
                    <TableWrapper height={height} >
                        <Box sx={{
                            height: height,
                            '& .filled-row': {
                                bgcolor: (theme) => theme.palette.primary.light,
                            }
                        }}>
                            {dataTable}
                        </Box>
                    </TableWrapper>
                </Paper>
            </CardContent>
        </Card>
    );
}

export default MetricTable;