import React, { useEffect, useContext } from "react";
import styled from "styled-components/macro";
import {useNavigate} from "react-router-dom";
import useProjectData from "../../hooks/useProjectData";
import useAuth from "../../hooks/useAuth";
import useNavigationData from "../../hooks/useNavigation";
import { AuthUser } from "../../types/auth";
import { PermissionLevel } from "../../utils/constants";

import { ThemeContext } from "styled-components/macro";
import {
    Button,
    Card as MuiCard,
    CardActions,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Grid,
    Box,
    Typography as MuiTypography, CardActionArea,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import SimpleChart from "../../components/charts/SimpleChart";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const Divider = styled(MuiDivider)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

type ProjectProps = {
    title: string;
    projectKey: string;
    stats: { name: string, value: string }[];
    plot: any;
    userRole: PermissionLevel;
    handleClick:  (key: string, role: number, project: string) => void;
    handleSettingsClick: (key: string, role: number, project: string, ) => void;
    handleManageUsersClick: (key: string, role: number, project: string, ) => void;
};

const Project: React.FC<ProjectProps> = ({
        title,
        projectKey,
        stats,
        plot,
        userRole,
        handleClick,
        handleSettingsClick,
        handleManageUsersClick
    }) => {

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleClick(projectKey, userRole, title);
    }

    const onSettingsClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleSettingsClick(projectKey, userRole, title);
    }

    const onManageUsersClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleManageUsersClick(projectKey, userRole, title);
    }

    const projectStats = stats.map((stat, index) => {
        return (
            <Typography variant="h6" mb={3} key={index}>
                <Box fontWeight="fontWeightRegular">{stat.value} {stat.name}</Box>
            </Typography>
        );
    });

    return (
        <Card>
            <CardActionArea onClick={onClick}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <br/>
                    <Grid container spacing={6}>
                        {
                            userRole >= PermissionLevel.Clinician &&
                            (
                                <Grid item xs={4} lg={4} xl={4}>
                                    <React.Fragment>
                                        {projectStats}
                                    </React.Fragment>
                                </Grid>
                            )
                        }
                        <Grid item 
                            xs={userRole >= PermissionLevel.Clinician ? 8 : 12}  
                            lg={userRole >= PermissionLevel.Clinician ? 8 : 12}
                            xl={userRole >= PermissionLevel.Clinician ? 8 : 12}>
                            <SimpleChart height={100} title={'Training Days'} data={plot} type={'bar'}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
            {
                userRole === PermissionLevel.Admin &&
                <CardActions>
                    <Button size="small" color="primary" onClick={onSettingsClick}>
                        Settings
                    </Button>
                    <Button size="small" color="primary" onClick={onManageUsersClick}>
                        Manage Users
                    </Button>
                </CardActions>
            }
        </Card>
    );
};

function getUserRole(user: AuthUser, project: string) {
    if (user && user.roles && user.roles.length > 0) {
        for (let i = 0; i < user.roles.length; i++) {
            let role = user.roles[i];
            if (role.project === project) {
                return role.permissionLevel;
            }
        }
    }
    return -1;
}

function generateSimpleChartData(data: any, theme: any) {
    data = data || {};
    if (!data.plots || data.plots.length < 2) return [];
    // Determine how many data points and how much to compress
    let first = new Date(data.plots[0].date.replace(/-/g,'/'));
    let last = new Date();
    let dateRange = (last.getTime()-first.getTime())/(24*60*60*1000);
    let dataCollapsed;
    if (dateRange > 365) {
        dataCollapsed = collapseData(data.plots, 'year');
    }
    else if (dateRange > 50) {
        dataCollapsed = collapseData(data.plots, 'month');
    }
    else {
        dataCollapsed = collapseData(data.plots, 'date');
    }

    return {
        labels: dataCollapsed.labels,
        datasets: [{
            label: data.project,
            fill: false,
            borderColor: theme.seasPalette.skyBlue,
            backgroundColor: theme.seasPalette.skyBlue,
            data: dataCollapsed.data,
            elements: {
                point: {
                    radius: 0
                }
            }
        }],
    }
}
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function getLabel(date: Date, type: string) {
    switch (type) {
        case 'year':
            return date.getFullYear();
        case 'month':
            return monthNames[date.getMonth()]?.substring(0,3) + " '" + date.getFullYear().toString().substring(2);
        case 'date':
        default:
            return date.getDay() + " " + monthNames[date.getMonth()]?.substring(0,3)
    }
}

function collapseData(data: any, type: string) {
    const today = new Date();
    let currentDate = today;
    let labels = [];
    let currentTrainingDays = 0;
    let dataSet = [];
    let currentLabel;
    for (let i = 0; i < data.length; i++) {
        currentDate = new Date(data[i].date.replace(/-/g,'/'));
        let label = getLabel(currentDate, type);
        if (label !== currentLabel) {
            if (i !== 0) {
                // ignore first element
                if (currentTrainingDays > 0) {
                    labels.push(currentLabel);
                    dataSet.push({
                        x: currentLabel,
                        y: currentTrainingDays,
                    })
                }
            }
            currentLabel = label;
            currentTrainingDays = 0;
        }
        if (data[i].trainingDays) currentTrainingDays += data[i].trainingDays;
    }
    if (currentTrainingDays > 0) {
        labels.push(currentLabel);
        dataSet.push({
            x: currentLabel,
            y: currentTrainingDays,
        })
    }    
    while (currentDate.getTime() < today.getTime()) {
        let label = getLabel(currentDate, type);
        if (label !== currentLabel) {
            labels.push(label);
            currentLabel = label;
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return {
        labels: labels,
        data: dataSet
    };
}

function Projects() {
    const navigate = useNavigate();
    const theme = useContext(ThemeContext);
    let { setProject } = useNavigationData();
    const { loadProjects, projectList, projectListLoading, selectProject } = useProjectData();
    const { isInitialized, user } = useAuth();
    const handleClick = async (projectKey: string, role: number, projectName: string) => {
        await selectProject(projectKey, role);
        navigate('/project/overview');
    };

    const handleSettingsClick = async (projectKey: string, role: number, projectName: string) => {
        await selectProject(projectKey, role);
        navigate('/project/settings');
    };

    const handleManageUsersClick = async (projectKey: string, role: number, projectName: string) => {
        await selectProject(projectKey, role);
        navigate('/project/manage-users');
    };

    useEffect(() => {
        // Set sidebar navigation to nothing
        setProject("", -1);
    }, [setProject]);

    useEffect(() => {
        const load = async () => {
            let projectKeys: string[] = [];
            if (user && user.roles && user.roles.length > 0) {
                for (let i = 0; i < user.roles.length; i++) {
                    let role = user.roles[i];
                    projectKeys.push(role.project);
                }
            }
            if (projectList && projectList.length === 0 && projectKeys && projectKeys.length > 0 && !projectListLoading) {
                try {
                    await loadProjects(projectKeys);
                }
                catch (error: any) {
                    // Error loading projects, display something
                }
            }
        }
        load();
    }, [isInitialized, user, loadProjects, projectListLoading, projectList]);

    let projectGrid;
    if (projectList && projectList.length > 0) {
        projectGrid = projectList.map((project) => {
            return (
                <Grid item xs={12} lg={6} xl={6} key={project.name}>
                    <Project
                        title={project.name}
                        projectKey={project.key}
                        stats={[
                            { name: 'Participants', value: `${project.users?.participants}` },
                            { name: 'Total Training Days', value: `${project.trainingDays}` },
                        ]}
                        plot={generateSimpleChartData(project.plot, theme)}
                        handleClick={handleClick}
                        handleSettingsClick={handleSettingsClick}
                        handleManageUsersClick={handleManageUsersClick}
                        userRole={getUserRole(user, project.key)}
                    />
                </Grid>
            )
        });
    }
    else {
        projectGrid = (
            <Grid container spacing={6}>
                <Grid item xs={12} lg={6} xl={3}>
                    {"No projects to show"}
                </Grid>
            </Grid>
        )
    }

    return (
      <React.Fragment>
        <Typography variant="h3" gutterBottom display="inline">
          Projects
        </Typography>

        <Divider my={6} />

        <Grid container spacing={6} >
          {projectGrid}
        </Grid>

      </React.Fragment>
    );
}

export default Projects;
