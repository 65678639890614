import React from "react";
import styled from "styled-components/macro";

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { SessionData } from '../../types/session';
import FileDownloadCard from '../data/FileDownloadCard';
import { createBlobUrl } from "../../utils/io";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const Divider = styled(MuiDivider)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

type SessionDataFilesProps = {
    session: SessionData,
    project: string,
}

const SessionDataFiles: React.FC<SessionDataFilesProps>  = ({
    session,
    project,
}) => {
    
    const sessionDataUrl = createBlobUrl(JSON.stringify(session, null, 4));
    const sessionDataFilename = `${session.participantID}_${session.sessionType}_${session.deviceID}_${session.date}_${session.time}_Summary.json`;

    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Session Data
                </Typography>
                <br />
                <Typography variant="h6" mb={3}>

                    {session.dataFiles.map((file) => {
                        if (file.endsWith("SuitData")) {
                            file += ".csv.gz"
                        }
                        else if (file.endsWith("Report")) {
                            file += ".pdf"
                        }
                        return (
                            <React.Fragment key={file}>
                                <FileDownloadCard project={project} filename={file} type="file"/>
                                <Divider my={6} />
                            </React.Fragment>
                        )
                    })}
                
                    <FileDownloadCard filename={sessionDataFilename} type="blob" href={sessionDataUrl} />
                </Typography>
            </CardContent>
        </Card>
    )
}

export default SessionDataFiles;
