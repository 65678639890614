import React,  { useState, useEffect } from "react";
import { 
    FormControl, 
    MenuItem, 
    SelectChangeEvent, 
    TextField,
    Button,
    Grid,
    Divider as MuiDivider,
    IconButton,
    Typography,
} from "@mui/material";
import { Trash2 as Trash } from 'react-feather'
import styled from "styled-components/macro";
import { Box, spacing } from "@mui/system";
import { ProjectSessionTemplate, TemplateMetric } from "../../types/project";


const Divider = styled(MuiDivider)(spacing);

type TemplateSelectOptionsProps = {
    options: string[],
    optionsChanged: (newOptions: string[]) => void,
}

const TemplateSelectOptions: React.FC<TemplateSelectOptionsProps> = ({options, optionsChanged}) => {
    const [selectOptions, setSelectOptions] = useState(options);
    const handleMetricChange = async (event: React.ChangeEvent<any> | SelectChangeEvent) => {
        const { target: { name, value } } = event;
        const index = parseInt(name);
        const newState = [ ...selectOptions.slice(0, index), value, ...selectOptions.slice(index + 1) ];
        setSelectOptions(newState)
        optionsChanged(newState);
    }    
    const onAddBtnClick = () => {
        let newState;
        newState = [...selectOptions, ""];
        setSelectOptions(newState);
        optionsChanged(newState);
    };
    const handleDeleteButtonClick = (index: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const newState = [ ...selectOptions.slice(0, index), ...selectOptions.slice(index + 1) ];
        setSelectOptions(newState)
        optionsChanged(newState);
    };
    const selectOptionsComponents = selectOptions.map((item, index) => {
        return (
            <React.Fragment key={index}>
                <Grid item xs={12}>
                    <FormControl>
                        <TextField
                            id={"option"+index}
                            value={item}
                            onChange={handleMetricChange}
                            size="small"
                            name={""+index}/>
                    </FormControl>
                    <IconButton 
                        disabled={index === 0 && selectOptions.length === 1}
                        aria-label="delete" 
                        sx={{ width: 'auto'}} 
                        onClick={handleDeleteButtonClick(index)}>
                        <Trash />
                    </IconButton>
                </Grid>
            </React.Fragment>
        )
    });

    return (
        <Grid item xs={12} container>
            <Grid item xs={2}>
                Options
            </Grid>
            <Grid item xs={10} container>
                {selectOptionsComponents}
            </Grid>
            <Button onClick={onAddBtnClick}>+ Add Option</Button>
        </Grid>
    )
};


export type TemplateSettingRowProps = {
    row: TemplateMetric,
    rowIndex: number,
    updateRows: (newRow: TemplateMetric, rowIndex: number) => void,
    deleteRow: (rowIndex: number) => void,
    deleteEnabled: boolean,
    metrics: string[],
}

const TemplateSettingRow: React.FC<TemplateSettingRowProps> = ({row, rowIndex, deleteEnabled, metrics, updateRows, deleteRow}) => {
    const handleMetricChange = async (event: React.ChangeEvent<any> | SelectChangeEvent) => {
        const { target: { name, value } } = event;
        const newState = {...rowMetric, [name]: value};
        if (name === 'type') {
            if (value === 'select') {
                newState.options = [""];
            }
            else {
                newState.options = null;
            }
        }
        setRowMetric(newState)
        updateRows(newState, rowIndex);
    };
    const handleDeleteButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        deleteRow(rowIndex);
    };
    const [rowMetric, setRowMetric] = useState(row);
    useEffect(() => {
        if (row.name !== rowMetric.name || row.key !== rowMetric.key || row.options !== rowMetric.options || row.type !== rowMetric.type) {
            setRowMetric(row)
        }
    }, [setRowMetric, rowMetric, row]);
    const optionsChanged = (options: string[]) => {
        const newState = {...rowMetric, options: options};
        setRowMetric(newState)
        updateRows(newState, rowIndex);
    };
    return (
      <React.Fragment>
          <Grid container spacing={6}>
              <Grid item xs={11}>
                <Box
                    component="form"
                        sx={{
                            '& > :not(style)': { 
                                m: 1, 
                                width: {
                                    sm: '48%',
                                    md: '32%',
                                    xs: '96%'
                                }
                            },
                        }}
                    noValidate
                    autoComplete="off" >
                    <FormControl>
                        <TextField
                            id="metric"
                            value={rowMetric.key}
                            onChange={handleMetricChange}
                            label="Metric"
                            size="small"
                            name="key"
                            select >
                            { metrics.map((metric, index) => <MenuItem key={index} value={metric}>{metric}</MenuItem>) }
                        </TextField>
                    </FormControl>
                    <FormControl>
                        <TextField 
                            id="display-name"
                            label="Display Name"
                            value={rowMetric.name}
                            onChange={handleMetricChange}
                            size="small"
                            name="name" />
                    </FormControl>
                    <FormControl>
                        <TextField
                            id="metric-type"
                            value={rowMetric.type}
                            onChange={handleMetricChange}
                            label="Type"
                            size="small"
                            name="type"
                            select >
                            <MenuItem value={"number"}>Number</MenuItem>
                            <MenuItem value={"text"}>Text</MenuItem>
                            <MenuItem value={"select"}>Select</MenuItem>
                        </TextField>
                    </FormControl>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <IconButton 
                    disabled={!deleteEnabled}
                    aria-label="delete" 
                    sx={{ ml:'-25px', width: 'auto'}} 
                    onClick={handleDeleteButtonClick}>
                    <Trash />
                </IconButton>
              </Grid>
              { rowMetric.type === 'select' && <TemplateSelectOptions options={rowMetric.options || []} optionsChanged={optionsChanged}/>}
          </Grid>
      </React.Fragment>
    )
}


export type TemplateSettingProps = {
    templateSettings: ProjectSessionTemplate,
    onSettingsChange: (index: number, template: ProjectSessionTemplate) => void,
    deleteTemplate: (index: number) => void,
    templateIndex: number,
    metrics: string[],
}

const TemplateSettings: React.FC<TemplateSettingProps>  = ({templateIndex, templateSettings, metrics, deleteTemplate, onSettingsChange}) => {
    if (!templateSettings.metrics || templateSettings.metrics.length === 0) {
        templateSettings.metrics = [{ key: '', name: '', type: '', options: [] }];
    }
    const [templateMetrics, setTemplateMetrics] = React.useState(templateSettings.metrics);
    const [sessionType, setSessionType] = React.useState(templateSettings.sessionType);

    const onAddBtnClick = () => {
        let newState;
        newState = [...templateMetrics, { key: '', name: '', type: '', options: [] }];
        setTemplateMetrics(newState);
        onSettingsChange(templateIndex, { sessionType: sessionType, metrics: [...newState]});
    };

    const updateRows = (newRow: TemplateMetric, rowIndex: number) => {
        let newState;
        newState = [ ...templateMetrics.slice(0, rowIndex), newRow, ...templateMetrics.slice(rowIndex + 1) ];
        setTemplateMetrics(newState);
        onSettingsChange(templateIndex, { sessionType: sessionType, metrics: [...newState]});
    }

    const deleteRow = (rowIndex: number) => {
        // Only cant delete if there is one row left
        if (templateMetrics.length === 1) return;
        let newState;
        newState = [ ...templateMetrics.slice(0, rowIndex), ...templateMetrics.slice(rowIndex + 1) ];
        setTemplateMetrics(newState);
        onSettingsChange(templateIndex, { sessionType: sessionType, metrics: [...newState]});
    }

    const templateRows = templateMetrics.map((row, index) => {
        return (
            <Grid item xs={12} key={index}>
                <TemplateSettingRow row={row} rowIndex={index} metrics={metrics} updateRows={updateRows} deleteRow={deleteRow} deleteEnabled={index > 0 || templateMetrics.length > 1}/>
                <Divider my={3} />
            </Grid>
        )
    });

    const handleSessionTypeChange = async (event: React.ChangeEvent<any> | SelectChangeEvent) => {
        const { target: { value } } = event;
        setSessionType(value);
        onSettingsChange(templateIndex, { sessionType: value, metrics: templateMetrics });
    }
    
    const handleDeleteButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        deleteTemplate(templateIndex);
    };
    
    return (
      <React.Fragment>
            <Grid item xs={2}>
                <Box
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column" >
                    <Typography variant="subtitle2">
                        { "SessionType" }
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={8} >
                <Box
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column" >
                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                        <TextField
                            id={''+templateIndex}
                            value={sessionType}
                            onChange={handleSessionTypeChange}
                            label="SessionType"
                            name={''+templateIndex}
                            size="small" />
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={2} >
                <IconButton 
                    aria-label="delete" 
                    sx={{mt: '1px', ml:'25px'}} 
                    onClick={handleDeleteButtonClick}>
                    <Trash />
                </IconButton>
            </Grid>
            { templateRows }
            <Button onClick={onAddBtnClick}>+ Add Metric</Button>
      </React.Fragment>
    );
}

export default TemplateSettings;