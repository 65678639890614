import React, { useContext } from "react";
import styled, {ThemeContext} from "styled-components/macro";
import Chart from 'react-chartjs-2';
import {CardContent, CardHeader as MuiCardHeader, Divider} from "@mui/material";
import { ChartOptions } from '../../types/project';import { ChartData, ChartSeries } from '../../types/chart';
import FileDownloadButton from '../data/FileDownloadButton';
import { createBlobUrl, convertChartToCSV } from '../../utils/io';
import { getDateTimeString } from '../../utils/datetime';

type WrapperProps = {
    height: number;
}

const ChartWrapper = styled.div<WrapperProps>`
  height: ${(props) => props.height}px;
`;

const CardHeader = styled(MuiCardHeader)`
  min-height: 50px;
  background: ${(props) => props.theme.seasPalette.blue};
  color: #FFF;
`;

type ScatterChartProps = {
    height: number,
    chartOptions: ChartOptions,
    chartData: ChartData,
    isFiltered: ( id: string ) => boolean,
}

const generateScatterChartData = (data: ChartData, chartOptions: ChartOptions, colors: string[], isFiltered: (id: string) => boolean) => {
    data = data || [];
    let dataSets: any[] = [];
    let labels: string[] = [];
    let xAxisType = 'category';
    const getSeriesData = (series: ChartSeries) => {
        let seriesData: any = [];
        if (!series || !series.plots) {
            return seriesData;
        }
        for (let j = 0; j < series.plots?.length; j++) {
            const dataPoint = series.plots[j];
            if (isFiltered(dataPoint.participantID)) continue;
            const domainValue = dataPoint[chartOptions.xAxis];
            if ((typeof domainValue === 'number' || !Number.isNaN(parseFloat(domainValue))) && 
                chartOptions.xAxis !== "daysSinceFirstSession") {
                xAxisType = 'linear';
            }
            if (!domainValue && domainValue !== 0) continue;
            if (labels.indexOf(domainValue) === -1) labels.push(domainValue);
            const metricValue = dataPoint[chartOptions.yAxis.id];
            if (metricValue) {
                seriesData.push({
                    x: domainValue,
                    y: metricValue
                });
            }
        }
        return seriesData;
    }
    for (let i = 0; i < data.length; i++) {
        let series = data[i];
        if(chartOptions.series === 'participantID' && isFiltered(series.name)) continue;
        const seriesData = getSeriesData(series);
        if (seriesData.length > 0) {
            dataSets.push(
                {
                    label: series.name,
                    borderColor: colors[i % colors.length],
                    backgroundColor: colors[i % colors.length],
                    data: seriesData,
                }
            );  
        }
    }
    return {
        labels: labels.sort((a: string, b: string) => {
            // Check if a and b are numbers
            if ((!Number.isNaN(Number(a))) && (!Number.isNaN(Number(b)))) {
                return Number(a) < Number(b) ? -1 : 1;
            }
            return a > b ? 1 : -1;
        }),
        datasets: dataSets,
        xAxisType: xAxisType,
    }
}


const ScatterPlot = ({height, chartOptions, chartData, isFiltered }: ScatterChartProps) => {
    const theme = useContext(ThemeContext);
    const scatterChartData = generateScatterChartData(chartData, chartOptions, theme.charts.colors, isFiltered);
    const xAxisLabel = chartOptions.xAxis;
    const yAxisLabel = chartOptions.yAxis.name;
    const chartTitle = yAxisLabel;
    const scatterChartOptions = {
        animation: {
            duration: 0
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxWidth: 40,
                    boxHeight: 2,
                },
            },
        },
        scales: {
            x: {
                type: scatterChartData.xAxisType,
                grid: {
                    color: "rgba(0,0,0,0.05)",
                },
                ticks: {
                    display: true,
                },
                title: {
                    display: true,
                    text: xAxisLabel,
                },
            },
            y: {
                type: "linear",
                display: true,
                borderDash: [5, 5],
                title: {
                    display: true,
                    text: yAxisLabel,
                },
                beginAtZero: true,
            },
        }
    };

    // Data File Info
    const filename = chartOptions?.yAxis?.key + "-" + chartOptions?.xAxis + "-" + chartOptions?.series + "-" + getDateTimeString() + ".csv";
    const href = createBlobUrl(convertChartToCSV(scatterChartData, chartOptions));

    return (
        <React.Fragment>
            <CardHeader
                title={chartTitle}
                action={<FileDownloadButton filename={filename} href={href}/>}
            />
            <Divider />
            <CardContent>
                <ChartWrapper height={height}>
                    <Chart type="scatter" data={scatterChartData} options={scatterChartOptions} />
                </ChartWrapper>
            </CardContent>
        </React.Fragment>
    );
};

export default ScatterPlot;
