import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import {
  Drawer as MuiDrawer,
  ListItemButton,
} from "@mui/material";

import Logo from "../../assets/move_lab_logo_white.png";
import SidebarNav from "./SidebarNav";
import useNavigationData from "../../hooks/useNavigation";
import getSidebarItems from "./sidebarItems";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;


const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled.img`
  margin-right: ${(props) => props.theme.spacing(2)};
  margin: 20px;
  width: 131px;
  height: 37px;
  background-color: ${(props) => props.theme.sidebar.header.background};
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  showFooter?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({
  showFooter = true,
  ...rest
}) => {

    const { project, role, participant, session } = useNavigationData();
    const sidebarItems = getSidebarItems(project, role, participant, session);
    return (
        <Drawer variant="permanent" {...rest}>
            <Brand component={NavLink} to="/">
                <BrandIcon src={Logo}/>{" "}
            </Brand>
            <SidebarNav items={sidebarItems} />
        </Drawer>
    );
};

export default Sidebar;
