import { useContext } from "react";
import { NavigationContext } from "../contexts/NavigationContext";

const useNavigationData = () => {
  const context = useContext(NavigationContext);

  if (!context)
    throw new Error("NavigationContext must be placed within NavigationProvider");

  return context;
};

export default useNavigationData;
