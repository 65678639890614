import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Paper, Typography } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import Logo from "../../assets/move_lab_logo.png";
import SignInComponent from "../../components/auth/SignIn";
import SignUpComponent from "../../components/auth/SignUp";
import styled from "styled-components/macro";
import useProjectData from "../../hooks/useProjectData";

const Brand = styled.img`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 372px;
  height: 113px;
  margin-top: 100px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

enum VerificationState {
    Unknown = -2,
    Loading = -1,
    Failure = 0,
    Success = 1,
    Error = 2,
}

function Invite() {
    const { user, isAuthenticated, verifyUser, signOut, signIn } = useAuth();
    const { addUserToProject } = useProjectData();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const invitedEmail = searchParams.get("email") || "";
    const invitedHash = searchParams.get("hash") || "";
    const invitedProject = searchParams.get("project") || "";

    const [hashVerificationState, setHashVerificationState] = React.useState(VerificationState.Unknown);
    const [isUserCorrect, setIsUserCorrect] = React.useState(false);
    const [addUserStatus, setAddUserStatus] = React.useState(VerificationState.Unknown);
    const [signInShown, setSignInShown] = React.useState(true);
    // Verify Hash Value
    useEffect(() => {
        if (hashVerificationState === VerificationState.Unknown) {
            setHashVerificationState(VerificationState.Loading);
            verifyUser(invitedHash)
            .then((isVerified) => {
                if (isVerified) {
                    setHashVerificationState(VerificationState.Success);
                }
                else {
                    setHashVerificationState(VerificationState.Failure);
                }
            })
            .catch(() => {
                setHashVerificationState(VerificationState.Error);
            })
        }
    }, [invitedHash, verifyUser, hashVerificationState, setHashVerificationState]);

    // Verify User Email
    useEffect(() => {
        if (isAuthenticated && hashVerificationState === VerificationState.Success) {
            setIsUserCorrect(user?.email === invitedEmail);
        }
    }, [hashVerificationState, setIsUserCorrect, isAuthenticated, invitedEmail, user]);

    // Add User to Project
    useEffect(() => {
        if (isUserCorrect && addUserStatus === VerificationState.Unknown) {
            setAddUserStatus(VerificationState.Loading);
            const invitedUser = {...user, hash: invitedHash}
            if (invitedProject !== "") {
                addUserToProject(invitedUser, invitedProject)
                    .then((succeeded) => {
                        if (succeeded) {
                            setAddUserStatus(VerificationState.Success);
                        }
                        else {
                            setAddUserStatus(VerificationState.Failure);
                        }
                    })
                    .catch(() => {
                        setAddUserStatus(VerificationState.Error);
                    })
            }
            else {
                setAddUserStatus(VerificationState.Error);
            }
        }
    }, [user, invitedHash, addUserToProject, isUserCorrect, addUserStatus, setAddUserStatus, invitedProject]);

    const logUserOut = () => {
        signOut();
    }

    const goToProjects = () => {
        signOut();
        navigate("/");
    }

    const showSignUp = () => {
        setSignInShown(false);
    }

    const showSignIn = () => {
        setSignInShown(true);
    }

    const signUserIn = (username: string, password: string) => {
        signIn(username, password, false);
    }

    let content;
    if (hashVerificationState === VerificationState.Failure) {
        content = <Typography variant="subtitle1">Invalid Invite Code.<br/>Please click the link in your email again or contact support.</Typography>
    }
    else if (hashVerificationState === VerificationState.Loading || hashVerificationState === VerificationState.Unknown) {
        content = <Typography variant="subtitle1">Loading. Please Wait.</Typography>
    }
    else if (hashVerificationState === VerificationState.Error) {
        // The server responded with an error code when trying to verify
        content = <Typography variant="subtitle1">There was a problem verifying your invitation code.<br/>Please try again.</Typography>
    }
    else {
        // Hash is verified
        if (isAuthenticated) {
            if (isUserCorrect) { 
                // User email matches invited email
                if (addUserStatus === VerificationState.Failure) {
                    // We failed to add this user to the project for some reason. Ask them to try again
                    content = <Typography variant="subtitle1">Could not add you to the project.<br/>Please click the link in your email again or contact support.</Typography>
                }
                else if (addUserStatus === VerificationState.Loading || addUserStatus === VerificationState.Unknown) {
                    content = <Typography variant="subtitle1">Loading. Please Wait.</Typography>
                }
                else if (addUserStatus === VerificationState.Error) {
                    // Server responded with an error adding user to the project. Show an error message
                    content = <Typography variant="subtitle1">There was a problem adding you to the project.<br/>Please try again.</Typography>
                }
                else {
                    // User is added successfully! Show a success message and a button to take them to their projects overview
                    content = (
                        <React.Fragment>
                            <Typography variant="subtitle1">
                                You have been added to the project! 
                                <br />
                                Click the button below to access your projects
                            </Typography>
                            <br/>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={goToProjects} >
                                View Projects
                            </Button>
                        </React.Fragment>
                    )
                }
            }
            else {
                // User email doesn't match invited email, so show an error message and a button to log out (so they can then log in with the correct account)
                content = (
                    <React.Fragment>
                        <Typography variant="subtitle1">
                            Your user account that does not match this invite.<br/>Please click below to log out and try again.
                        </Typography>
                        <br/>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={logUserOut} >
                            Log Out
                        </Button>
                    </React.Fragment>
                )
            }
        }
        else {
            // User is not logged in, so show either Sign In or Sign Up
            // We should have a button to toggle between these components
            if (signInShown) {
                // Show Sign In component and button to switch to Sign Up
                content = (
                    <React.Fragment>
                        <SignInComponent />
                        <Button
                            fullWidth
                            color="primary"
                            onClick={showSignUp} >
                            I don't have an account
                        </Button>
                    </React.Fragment>
                )
            }
            else {
                // Show Sign Up component and button to switch to Sign In
                content = (
                    <React.Fragment>
                        <SignUpComponent email={invitedEmail} onSignUp={signUserIn} hideHome={true}/>
                        <Button
                            fullWidth
                            color="primary"
                            onClick={showSignIn} >
                            I have an account
                        </Button>
                    </React.Fragment>
                )
            }
        }
    }

    return (
        <React.Fragment>
            <Brand src={Logo}/>
            <Wrapper>
                {content}
            </Wrapper>
        </React.Fragment>
    )
}

export default Invite;