import React from 'react';
import {
    Grid,
    Typography,
    Card as MuiCard,
    Button,
    Accordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails,
    Box,
    TextField,
    IconButton,
} from "@mui/material";
import { Trash2 as Trash } from 'react-feather'
import { spacing } from "@mui/system";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import styled from "styled-components/macro";

const Card = styled(MuiCard)(spacing);

const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: 64px;
  padding-left: 16px;
  background: ${(props) => props.theme.seasPalette.blue};
  color: #FFF;
`;

type MetricSettingsProps = {
    title: string,
    expanded: boolean,
    metricList: string[],
    notifySettingsChanged: (metrics: string[]) => void,
}
const MetricSettings: React.FC<MetricSettingsProps> = ({
    title,
    expanded,
    metricList,
    notifySettingsChanged
}) => {
    metricList = metricList || [];
    const [expandedPanel, setExpandedPanel] = React.useState(expanded);
    const handleAccordionChange = (panel: boolean) => (event: any, isExpanded: any) => {
        setExpandedPanel(isExpanded ? panel : false);
    };

    const [metrics, setMetrics] = React.useState(metricList);
    const addMetric = (event: React.ChangeEvent<any>) => {
        let newState;
        newState = [ ...metrics, '' ];
        setMetrics(newState);
        notifySettingsChanged(newState);
    }
    const removeMetric = (index: number) => async (event: React.ChangeEvent<any>) => {
        let newState;
        newState = [ ...metrics.slice(0, index),  ...metrics.slice(index + 1)];
        setMetrics(newState);
        notifySettingsChanged(newState);
    }
    const handleMetricChange = async (event: React.ChangeEvent<any>) => {
        const { target: { name, value } } = event;
        let newState;
        let index = parseInt(name);
        newState = [ ...metrics.slice(0, index), value, ...metrics.slice(index + 1)];
        setMetrics(newState);
        notifySettingsChanged(newState);
    }

    const metricComponents = metrics.map((metric, index) => {
        return (
            <Grid container spacing={6} key={index}>
                <Grid item xs={11} sm={6}>
                    <Box
                        component="form"
                            sx={{
                                '& > :not(style)': { 
                                    m: 1, 
                                    width: {
                                        xs: '96%'
                                    }
                                },
                            }}
                        noValidate
                        autoComplete="off" >
                        <TextField
                            id={"metric"+index}
                            value={metric}
                            onChange={handleMetricChange}
                            size="small"
                            name={''+index} />
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <IconButton 
                        disabled={index === 0 && metrics.length === 1}
                        aria-label="delete"  
                        onClick={removeMetric(index)}>
                        <Trash />
                    </IconButton>
                </Grid>
            </Grid>
        )
    })
    return (
        <React.Fragment>
            <Card>
                <Accordion
                    expanded={expandedPanel}
                    onChange={handleAccordionChange(!expandedPanel)} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "#FFF"}} />} >
                        <Typography variant="h5">
                            { title }
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        { metricComponents }
                        <Typography variant='h6'>
                            <Button size='large' onClick={addMetric}>+ Add Metric</Button>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Card>
        </React.Fragment>
    )
};

export default MetricSettings;
