import React,  { useState, useEffect } from "react";
import { 
    FormControl, 
    MenuItem, 
    SelectChangeEvent, 
    TextField,
    Button,
    Grid,
    Divider as MuiDivider,
    IconButton,
} from "@mui/material";
import { Trash2 as Trash } from 'react-feather'
import styled from "styled-components/macro";
import { Box, spacing } from "@mui/system";
import { TableSettingProps, TableSettingRowProps } from "../../types/chart";

const Divider = styled(MuiDivider)(spacing);
const TableSettingRow: React.FC<TableSettingRowProps> = ({row, rowIndex, metrics, deleteEnabled, updateRows, deleteRow}) => {
    const handleMetricChange = async (event: React.ChangeEvent<any> | SelectChangeEvent) => {
        const { target: { name, value } } = event;
        setRowMetric({...rowMetric, [name]: value})
        updateRows(name, value, rowIndex);
    }
    const handleDeleteButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        deleteRow(rowIndex);
    };
    const [rowMetric, setRowMetric] = useState(row);
    useEffect(() => {
        if (row.id !== rowMetric.id || row.name !== rowMetric.name || row.key !== rowMetric.key || row.unit !== rowMetric.unit || row.type !== rowMetric.type) {
            setRowMetric(row)
        }
    }, [setRowMetric, rowMetric, row]);
    return (
      <React.Fragment>
          <Grid container spacing={6}>
              <Grid item xs={11}>
                <Box
                    component="form"
                        sx={{
                            '& > :not(style)': { 
                                m: 1, 
                                width: {
                                    sm: '48%',
                                    md: '23%',
                                    xs: '96%'
                                }
                            },
                        }}
                    noValidate
                    autoComplete="off" >
                    <FormControl>
                        <TextField
                            id="metric"
                            value={rowMetric.key}
                            onChange={handleMetricChange}
                            label="Metric"
                            size="small"
                            name="key"
                            select >
                            {metrics.map((metric, index) => (
                                <MenuItem key={index} value={metric}>{metric}</MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl>
                        <TextField 
                            id="display-name"
                            label="Display Name"
                            value={rowMetric.name}
                            onChange={handleMetricChange}
                            size="small"
                            name="name" />
                    </FormControl>
                    <FormControl>
                        <TextField
                            id="metric-type"
                            value={rowMetric.type}
                            onChange={handleMetricChange}
                            label="Type"
                            size="small"
                            name="type"
                            select >
                            <MenuItem value={"average"}>Average</MenuItem>
                            <MenuItem value={"total"}>Total</MenuItem>
                        </TextField>
                    </FormControl>
                    <TextField
                        id="unit"
                        label="Unit"
                        value={rowMetric.unit}
                        onChange={handleMetricChange}
                        size="small"
                        name="unit" />
                </Box>
              </Grid>
              <Grid item xs={1}>
                <IconButton 
                    disabled={!deleteEnabled}
                    aria-label="delete" 
                    sx={{mt: '23px', ml:'-25px'}} 
                    onClick={handleDeleteButtonClick}>
                    <Trash />
                </IconButton>
              </Grid>
          </Grid>
      </React.Fragment>
    )
}

const TableSetting: React.FC<TableSettingProps>  = ({tableSettings, metrics, onSettingsChange}) => {
    if (!tableSettings.metrics || tableSettings.metrics.length === 0) {
        tableSettings.metrics = [{ id: '1', key: '', name: '', type: '', unit: '' }];
    }
    const [tableMetrics, setTableMetrics] = React.useState(tableSettings.metrics);

    const onAddBtnClick = () => {
        setTableMetrics([...tableMetrics, { id: ""+tableMetrics.length+1, key: '', name: '', type: '', unit: '' }]);
    };

    const updateRows = (key: string, value: string, rowIndex: number) => {
        let newState;
        let newRow = { ...tableMetrics[rowIndex], [key]: value};
        newState = [ ...tableMetrics.slice(0, rowIndex), newRow, ...tableMetrics.slice(rowIndex + 1) ];
        setTableMetrics(newState);
        onSettingsChange({ ...tableSettings, metrics: [...newState]});
    }

    const deleteRow = (rowIndex: number) => {
        // Only cant delete if there is one row left
        if (tableMetrics.length === 1) return;
        let newState;
        newState = [ ...tableMetrics.slice(0, rowIndex), ...tableMetrics.slice(rowIndex + 1) ];
        setTableMetrics(newState);
        onSettingsChange({ ...tableSettings, metrics: [...newState]});
    }

    const tableRows = tableMetrics.map((row, index) => {
        return (
            <React.Fragment key={index}>
                <TableSettingRow row={row} rowIndex={index} metrics={metrics} updateRows={updateRows} deleteRow={deleteRow} deleteEnabled={index > 0 || tableMetrics.length > 1}/>
                <Divider my={3} />
            </React.Fragment>
        )
    });

    return (
      <React.Fragment>
          { tableRows }
          <Button onClick={onAddBtnClick}>+ Add Metric</Button>
      </React.Fragment>
    );
}

export default TableSetting;