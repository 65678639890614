import * as React from "react";
import styled, { withTheme } from "styled-components/macro";
import Chip from '@mui/material/Chip';

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";
import NavbarUserDropdown from "./NavbarUserDropdown";
import useAuth from "../../hooks/useAuth";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;


type NavbarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
    const { user } = useAuth();
    return (
        <React.Fragment>
            <AppBar style={{ background: '#2E3B55' }} position="sticky" elevation={0}>
                <Toolbar>
                    <Hidden mdUp>
                        <IconButton
                          color="inherit"
                          aria-label="Open drawer"
                          onClick={onDrawerToggle}
                          size="large"
                        >
                          <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <Grid item xs={12}/>
                    <Chip label={user?.username} style={{color:'lightgrey'}} variant="outlined" />
                    <NavbarUserDropdown />
                </Toolbar>
          </AppBar>
        </React.Fragment>
    );
};

export default withTheme(Navbar);
