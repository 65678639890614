import React, { useEffect } from 'react';
import useProjectData from "../../hooks/useProjectData";
import useNavigationData from "../../hooks/useNavigation";
import ManageUsersTable from "../../components/project/ManageUsersTable";


function ManageUsers() {
    const { projectSettings, userRole, userList, getUsers, userListLoading } = useProjectData();
    const { setProject } = useNavigationData();

    useEffect( () => {
        if (projectSettings) {
            setProject(projectSettings.name, userRole);
        }
    }, [projectSettings, setProject, userRole]);
    // for navigating to this page from any point.
    useEffect(() => {
        const load = async () => {
            try {
                await getUsers(projectSettings?.key!);
            } catch (error: any) {
                // Error loading projects, display something
            }
        }
        if (projectSettings && !userList && !userListLoading) {
            load();
        }
    }, [projectSettings, getUsers, userList, userListLoading]);

    if (projectSettings) {
        return (
            <React.Fragment>
                <ManageUsersTable userList={userList} />
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment />
        )
    }
}

export default ManageUsers;