import { useContext } from "react";
import { ProjectContext } from "../contexts/ProjectContext";

const useProjectData = () => {
  const context = useContext(ProjectContext);

  if (!context)
    throw new Error("ProjectContext must be placed within ProjectProvider");

  return context;
};

export default useProjectData;
