import React,  { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Card as MuiCard,
    FormControl,
    Divider as MuiDivider,
    SelectChangeEvent,
    Button,
    Accordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails,
    Box,
    TextField,
    IconButton,
} from "@mui/material";
import { Trash2 as Trash } from 'react-feather'
import { spacing } from "@mui/system";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import styled from "styled-components/macro";
import { ProjectStudy } from '../../types/project';

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: 64px;
  padding-left: 16px;
  background: ${(props) => props.theme.seasPalette.blue};
  color: #FFF;
`;

export type StudySettingRowProps = {
    study: ProjectStudy,
    studyIndex: number,
    updateStudies: (newStudy: ProjectStudy, studyIndex: number) => void,
    deleteStudy: (studyIndex: number) => void,
    deleteEnabled: boolean,
}

const StudySettingRow: React.FC<StudySettingRowProps> = ({study, studyIndex, deleteEnabled, updateStudies, deleteStudy}) => {
    const handleMetricChange = async (event: React.ChangeEvent<any> | SelectChangeEvent) => {
        const { target: { name, value } } = event;
        const newState = {...studySetting, [name]: value};
        setStudySetting(newState)
        updateStudies(newState, studyIndex);
    };
    const handleDeleteButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        deleteStudy(studyIndex);
    };
    const [studySetting, setStudySetting] = useState(study);
    useEffect(() => {
        if (study.name !== studySetting.name || study.key !== studySetting.key) {
            setStudySetting(study)
        }
    }, [setStudySetting, studySetting, study]);
    return (
      <React.Fragment>
          <Grid container spacing={6}>
              <Grid item xs={11}>
                <Box
                    component="form"
                        sx={{
                            '& > :not(style)': { 
                                m: 1, 
                                width: {
                                    sm: '48%',
                                    md: '32%',
                                    xs: '96%'
                                }
                            },
                        }}
                    noValidate
                    autoComplete="off" >
                    <FormControl>
                        <TextField
                            id="key"
                            label="Study Key"
                            value={study.key}
                            onChange={handleMetricChange}
                            size="small"
                            name="key"
                            />
                    </FormControl>
                    <FormControl>
                        <TextField 
                            id="name"
                            label="Study Name"
                            value={study.name}
                            onChange={handleMetricChange}
                            size="small"
                            name="name"
                            />
                    </FormControl>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <IconButton 
                    disabled={!deleteEnabled}
                    aria-label="delete" 
                    sx={{ ml:'-25px', width: 'auto'}} 
                    onClick={handleDeleteButtonClick}>
                    <Trash />
                </IconButton>
              </Grid>
          </Grid>
      </React.Fragment>
    )
}

type StudySettingsProps = {
    title: string,
    expanded: boolean,
    studyList: ProjectStudy[],
    notifySettingsChanged: (studies: ProjectStudy[]) => void,
}
const StudySettings: React.FC<StudySettingsProps> = ({
    title,
    expanded,
    studyList,
    notifySettingsChanged
}) => {
    studyList = studyList || [];
    const [expandedPanel, setExpandedPanel] = React.useState(expanded);
    const handleAccordionChange = (panel: boolean) => (event: any, isExpanded: any) => {
        setExpandedPanel(isExpanded ? panel : false);
    };

    const [studies, setStudies] = React.useState(studyList);

    const addStudy = (event: React.ChangeEvent<any>) => {
        let newState;
        newState = [ ...studies, { key:'', name:'' } ];
        setStudies(newState);
        notifySettingsChanged(newState);
    }

    const deleteStudy = (index: number) => {
        // Only cant delete if there is one row left
        if (studies.length === 1) return;
        let newState;
        newState = [ ...studies.slice(0, index),  ...studies.slice(index + 1)];
        setStudies(newState);
        notifySettingsChanged(newState);
    }

    const updateStudies = (newStudy: ProjectStudy, studyIndex: number) => {
        let newState;
        newState = [ ...studies.slice(0, studyIndex), newStudy, ...studies.slice(studyIndex + 1) ];
        setStudies(newState);
        notifySettingsChanged(newState);
    }

    
    const studyComponents = studies.map((study, index) => {
        return (
            <Grid item xs={12} key={index}>
                <StudySettingRow
                    study={study}
                    studyIndex={index}
                    updateStudies={updateStudies}
                    deleteStudy={deleteStudy}
                    deleteEnabled={index > 0 || studies.length > 1}
                    />
                <Divider my={3} />
            </Grid>
        )
    });

    return (
        <React.Fragment>
            <Card>
                <Accordion
                    expanded={expandedPanel}
                    onChange={handleAccordionChange(!expandedPanel)} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "#FFF"}}/>} >
                        <Typography variant="h5">
                            { title }
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Divider my={3}/>
                        <Grid container spacing={1}>   
                            { studyComponents }
                        </Grid>
                        <Typography variant='h6'>
                            <Button size='large' onClick={addStudy}>+ Add Study</Button>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Card>
        </React.Fragment>
    )
};

export default StudySettings;