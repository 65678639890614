import { useContext } from "react";
import { SessionContext } from "../contexts/SessionContext";

const useSessionData = () => {
  const context = useContext(SessionContext);

  if (!context)
    throw new Error("SessionContext must be placed within SessionProvider");

  return context;
};

export default useSessionData;
