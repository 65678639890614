import React from "react";
import styled from "styled-components/macro";

import { Card as MuiCard, Paper } from "@mui/material";
import Fab from '@mui/material/Fab';
import Tooltip from "@mui/material/Tooltip";

import { Download as DownloadIcon } from "@mui/icons-material";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarColumnsButton,
    GridToolbarFilterButton } from "@mui/x-data-grid";
import BottomNavigation from '@mui/material/BottomNavigation';
import { spacing } from "@mui/system";
import useProjectData from "../../hooks/useProjectData";
import { downloadDataFiles } from "../../utils/io";
import { DataFile } from "../../types/project";

const Card = styled(MuiCard)(spacing);

type WrapperProps = {
    height: number;
}

const TableWrapper = styled.div<WrapperProps>`
  height: ${(props) => props.height}px;
  width: 100%;
`;

const columns: GridColDef[] = [
    { field: "name", headerName: "Name", minWidth: 120, flex: 2, renderCell: (params: any) =>  (
            <Tooltip title={params.value} >
                <span style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>{params.value}</span>
            </Tooltip>
        ) },
    { field: "type", headerName: "Type", flex:0.8 },
    { field: "size", headerName: "Size", type: "number", flex: 0.8, renderCell: (params: any) => { 
        return (
            <React.Fragment>
                {formatBytes(params.value)}
            </React.Fragment>
        ) }},
    { field: "date", headerName: "Date", flex: 1 },
    { field: "participantID", headerName: "Participant", flex: 1 },
    { field: "deviceID", headerName: "Device", flex: 1 },
    { field: "sessionType", headerName: "Session", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 }
];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

function formatBytes(bytes: number) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

function DataTable() {
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [pageNumber, setPageNumber] = React.useState<number>(0);
    const [selectionModel, setSelectionModel] = React.useState<number[]>([]);
    const [filesSelected, setFilesSelected] = React.useState<number>(0);
    const { dataFiles, selectedProject } = useProjectData();
    const rows = dataFiles ? dataFiles?.map((val, index) => {
        return { id: index, ...val };
    }) : [];

    function downloadFiles() {
        // Make a list of selected file names
        let filenames: string[] = [];
        dataFiles?.forEach((file: DataFile, index: number) => {
            if (selectionModel.includes(index)) {
                filenames.push(file.name);
            }
        });
        downloadDataFiles(filenames, selectedProject);
    }


    function displayDownload() {
        if (filesSelected) {
            return (
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <BottomNavigation>
                        <Fab variant="extended" size="medium" color="primary"
                            style={{position: 'relative', top: '8px'}}
                            onClick={downloadFiles}>
                            <DownloadIcon sx={{ mr: 1 }} />
                            Download files
                        </Fab>
                    </BottomNavigation>
                </Paper>
            )
        }
    }

    function onPageChange(newPageNumber: number) {
        setPageNumber(newPageNumber);
        setSelectionModel([]);
    }

    function onFileSelected(ids: any) {
        const min = pageNumber * pageSize;
        const max = min + pageSize -1;
        // filter all ids that are between min and max and set it to selectionModel
        // only do this if the ids array is larger than the pageSize (i.e. if the select-all button is clicked)
        const filteredIds = ids.length > pageSize ? ids.filter((id: number, index: number) => index >= min && index <= max) : ids;
        setSelectionModel(filteredIds)
        const selectedIDs = new Set(filteredIds);
        const selectedRowData = rows.filter((row) => {
                return selectedIDs.has(row.id);
            }
        )
        if (selectedRowData.length) {
            setFilesSelected(selectedRowData.length);
        }
    }

    function onSortModelChanged() {
        setSelectionModel([]);
    }

    // Calculate height of data grid based on number of rows and page size
    // 166 value accounts for header and footer size, 48 is the height of each row in px
    const height = 166 + (rows.length < pageSize ? rows.length : pageSize)*48;
    return (
        <React.Fragment>
            <Card mb={6}>
                <Paper>
                    <TableWrapper height={height}>
                        <DataGrid
                            aria-label="data-table"
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            onPageChange={onPageChange}
                            rowsPerPageOptions={[10, 20, 30, 100]}
                            pagination
                            rows={rows}
                            columns={columns}
                            rowHeight={48}
                            checkboxSelection
                            selectionModel={selectionModel}
                            onSelectionModelChange={onFileSelected}
                            onSortModelChange={onSortModelChanged}
                            disableColumnMenu={true}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                            localeText={{
                                noRowsLabel: "No files",
                                footerTotalRows: 'Total Files:',
                                footerRowSelected: (count) => 
                                        count !== 1
                                        ? `${count.toLocaleString()} files selected`
                                        : `${count.toLocaleString()} file selected`,
                                MuiTablePagination: {
                                    labelRowsPerPage: "Files per page",
                                }
                                
                            }}
                        />
                    </TableWrapper>
                </Paper>
            </Card>
            {displayDownload()}
        </React.Fragment>
    );
}

export default DataTable;
