import { useContext } from "react";
import { ParticipantContext } from "../contexts/ParticipantContext";

const useParticipantData = () => {
  const context = useContext(ParticipantContext);

  if (!context)
    throw new Error("ParticipantContext must be placed within ParticipantProvider");

  return context;
};

export default useParticipantData;
