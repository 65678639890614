import { createContext, ReactNode, useReducer } from "react";

import { ActionMap } from "../types/auth";
import {
    SessionContextType,
    SessionState,
    NullableSessionData,
    SessionIdentifier,
} from "../types/session";

import axios from "../utils/axios";

const SELECT_SESSION = "SELECT_SESSION";
const LOAD_SESSION = "LOAD_SESSION";
const LOADING_SESSION = "LOADING_SESSION";

type SessionActionTypes = {
    [SELECT_SESSION]: {
        selectedSession: SessionIdentifier;
    };
    [LOAD_SESSION]: {
        sessionData: NullableSessionData;
    };
    [LOADING_SESSION]: undefined;
};

const initialState: SessionState = {
    selectedSession: { date: "", time: "" },
    sessionData: null,
    sessionDataLoading: false,
};

const SessionReducer = (
    state: SessionState,
    action: ActionMap<SessionActionTypes>[keyof ActionMap<SessionActionTypes>]
) => {
    switch (action.type) {
        case SELECT_SESSION:
            return {
                ...state,
                selectedSession: action.payload.selectedSession,
                sessionData: null,
            };
        case LOAD_SESSION:
            return {
                ...state,
                sessionData: action.payload.sessionData,
                sessionDataLoading: false,
            }
        case LOADING_SESSION:
            return {
                ...state,
                sessionDataLoading: true,
            }
        default:
            return state;
    }
};

const SessionContext = createContext<SessionContextType | null>(null);

function SessionProvider({ children }: { children: ReactNode }) {

    const [state, dispatch] = useReducer(SessionReducer, initialState);

    const selectSession = (date: string, time: string) => {
        if (state.selectedSession.date !== date || state.selectedSession.time !== time) {
            dispatch({
                type: SELECT_SESSION,
                payload: {
                    selectedSession: { date, time },
                },
            });
        }
    };

    const loadSession = async (project: string, participantID: string, date:string, time:string) => {
        dispatch({ type: LOADING_SESSION });
        const response = await axios.get("/api/session", {
            params: { project, participantID, date, time }
        })
        const { data } = response.data;
        dispatch({
            type: LOAD_SESSION,
            payload: {
                sessionData: data
            }
        })
    };

    return (
        <SessionContext.Provider
            value={{
                ...state,
                selectSession,
                loadSession,
            }}
        >
            {children}
        </SessionContext.Provider>
    )
};

export { SessionContext, SessionProvider };
