import React from "react";
import styled from "styled-components/macro";
import { Paper } from "@mui/material";
import Logo from "../../assets/move_lab_logo.png";
import SignInComponent from "../../components/auth/SignIn";
import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Brand = styled.img`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 372px;
  height: 113px;
  margin-top: 100px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
	const { isAuthenticated } = useAuth();
	if (isAuthenticated) {
		return <Navigate to="/projects/overview" />;
	}
  return (
    <React.Fragment>
        <Brand src={Logo}/>
        <Wrapper>
            <SignInComponent />
        </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
