import MetricTable from "../charts/MetricTable";
import { Grid } from "@mui/material";
import { ChartFilterElement } from "../../types/chart";
import ChartComponent from "../charts/ChartComponent";
import { DataAreaSettings, TableOptions } from '../../types/project';
import { ChartData } from '../../types/chart';

type DataAreaProps = {
    dataAreaSettings: DataAreaSettings,
    chartData: ChartData,
    chartFilter?: ChartFilterElement[],
    updateChartFilter?: (filter: ChartFilterElement[]) => void;
}

function DataArea({ dataAreaSettings, chartData, chartFilter, updateChartFilter }: DataAreaProps) {
    function onFilterChanged(filter: ChartFilterElement[]) {
        if (updateChartFilter) updateChartFilter(filter)
    }

    let dataAreaGraph;
    if (dataAreaSettings.type === 'table') {
        dataAreaGraph = <MetricTable 
                            height={600}
                            tableOptions={dataAreaSettings.options as TableOptions}
                            chartData={chartData} />
    }
    else { // for all other charts
        dataAreaGraph = <ChartComponent 
                            height={400}
                            dataAreaSettings={dataAreaSettings}
                            chartData={chartData}
                            chartFilter={chartFilter}
                            onFilterChanged={onFilterChanged} />
    }

    return (
        <Grid item xs={12}>
            { dataAreaGraph }
        </Grid>
    )

}

export default DataArea;