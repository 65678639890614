import React, { useState } from "react";
import { 
    FormControl, 
    Grid, 
    MenuItem, 
    SelectChangeEvent, 
    TextField, 
    Typography,
    IconButton,
} from "@mui/material";
import { XCircle } from 'react-feather';
import { Box } from "@mui/system";
import { ChartSettingProps } from "../../types/chart";

const ChartSetting: React.FC<ChartSettingProps>  = ({chartSettings, metrics, onSettingsChange}) => {
    // Check each key individually
    if (!chartSettings['xAxis']) {
        chartSettings['xAxis'] = '';
    }
    if (!chartSettings['series']) {
        chartSettings['series'] = '';
    }
    if (!chartSettings['xAxisType']) {
        chartSettings['xAxisType'] = '';
    }
    if (!chartSettings['yAxis']) {
        chartSettings['yAxis'] = {
            id: "",
            key: "",
            name: "",
            type: "",
            unit: ""
        };
    }
    else {
        chartSettings['yAxis'] = {
            id: chartSettings['yAxis'].id ? chartSettings['yAxis'].id : "",
            key: chartSettings['yAxis'].key ? chartSettings['yAxis'].key : "",
            name: chartSettings['yAxis'].name ? chartSettings['yAxis'].name : "",
            type: chartSettings['yAxis'].type ? chartSettings['yAxis'].type : "",
            unit: chartSettings['yAxis'].unit ? chartSettings['yAxis'].unit : ""
        };
    }
    if (!chartSettings['filter']) {
        chartSettings['filter'] = {
            key: "",
            op: "",
            value: "",
        };
    }
    else {
        chartSettings['filter'] = {
            key: chartSettings['filter'].key ? chartSettings['filter'].key : "",
            op: chartSettings['filter'].op ? chartSettings['filter'].op : "",
            value: chartSettings['filter'].value ? chartSettings['filter'].value : "",
        };
    }

    React.useEffect(() => {
        // reset the state here
    }, [chartSettings.type])

    const [chartOptions, setChartOptions] = useState(chartSettings);

    const handleMetricChange = async (event: React.ChangeEvent<any> | SelectChangeEvent) => {
        const { target: { name, value } } = event;
        let newState;
        switch (name) {
            case 'xAxis':
            case 'series':
            case 'xAxisType':
                newState = {...chartOptions, [name] : value };
                break;
            case 'yAxis.key':
                newState = {...chartOptions, 'yAxis': { ...chartOptions.yAxis, id: value + "_metric", key : value }};
                break;
            case 'yAxis.name':
                newState = {...chartOptions, 'yAxis': { ...chartOptions.yAxis, name : value }};
                break;
            case 'yAxis.type':
                newState = {...chartOptions, 'yAxis': { ...chartOptions.yAxis, type : value }};
                break;
            case 'yAxis.unit':
                newState = {...chartOptions, 'yAxis': { ...chartOptions.yAxis, unit : value }};
                break;
            case 'filter.key':
                newState = {...chartOptions, 'filter': { ...chartOptions.filter, key : value }};
                break;
            case 'filter.op':
                newState = {...chartOptions, 'filter': { ...chartOptions.filter, op : value }};
                break;
            case 'filter.value':
                newState = {...chartOptions, 'filter': { ...chartOptions.filter, value : value }};
                break;
            default:
                newState = { ...chartOptions };
        }
        await setChartOptions(newState);
        onSettingsChange(newState);
    };
    const handleFilterClearButtonClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let newState = {...chartOptions, 'filter': { key: "", op: "", value: "" }};
        setChartOptions(newState);
        onSettingsChange(newState);
    };
    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column">
                        <Typography variant="subtitle2">
                            Y-axis
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { 
                                m: 1, 
                                width: {
                                    sm: '48%',
                                    md: '23%',
                                    xs: '96%'
                                }
                            },
                        }}
                        noValidate
                        autoComplete="off" >
                        <FormControl>
                            <TextField
                                id="key"
                                value={ chartOptions.yAxis.key }
                                onChange={handleMetricChange}
                                label="Metric"
                                size="small"
                                name="yAxis.key"
                                select >
                                {metrics.map((metric, index) => (
                                    <MenuItem key={index} value={metric}>{metric}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                        <TextField 
                            id="display-name"
                            label="Display Name"
                            variant="outlined"
                            size="small"
                            defaultValue={chartOptions.yAxis.name}
                            onChange={handleMetricChange}
                            name="yAxis.name" />
                        <FormControl>
                            <TextField
                                id="yaxis-metric-type"
                                value={chartOptions.yAxis.type}
                                onChange={handleMetricChange}
                                label="Type"
                                size="small"
                                name="yAxis.type"
                                select >
                                <MenuItem value={"average"}>Average</MenuItem>
                                <MenuItem value={"total"}>Total</MenuItem>
                                <MenuItem value={"cumulative"}>Cumulative</MenuItem>
                            </TextField>
                        </FormControl>
                        <TextField 
                            id="unit"
                            label="Unit"
                            defaultValue={chartOptions.yAxis.unit}
                            size="small"
                            onChange={handleMetricChange}
                            name="yAxis.unit" />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column">
                        <Typography variant="subtitle2">
                            Filter (optional)
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10} container>
                    <Grid item xs={11}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { 
                                    m: 1, 
                                    width: {
                                        sm: '48%',
                                        md: '32%',
                                        xs: '96%'
                                    }
                                },
                            }}
                            noValidate
                            autoComplete="off" >
                            <FormControl>
                                <TextField
                                    id="filter-key"
                                    value={chartOptions.filter.key}
                                    onChange={handleMetricChange}
                                    label="Metric"
                                    size="small"
                                    name="filter.key"
                                    select >
                                    {metrics.map((metric, index) => (
                                        <MenuItem key={index} value={metric}>{metric}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <FormControl>
                                <TextField
                                    id="filter-op"
                                    value={chartOptions.filter.op}
                                    onChange={handleMetricChange}
                                    label="Operation"
                                    size="small"
                                    name="filter.op"
                                    select >
                                    <MenuItem value={"=="}>{"=="}</MenuItem>
                                    <MenuItem value={"!="}>{"!="}</MenuItem>
                                    <MenuItem value={">"}>{">"}</MenuItem>
                                    <MenuItem value={">="}>{">="}</MenuItem>
                                    <MenuItem value={"<"}>{"<"}</MenuItem>
                                    <MenuItem value={"<="}>{"<="}</MenuItem>
                                </TextField>
                            </FormControl>
                            <TextField 
                                id="filter.value"
                                label="Value"
                                value={chartOptions.filter.value}
                                size="small"
                                onChange={handleMetricChange}
                                name="filter.value" />
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton 
                            aria-label="clear"  
                            onClick={handleFilterClearButtonClick}>
                            <XCircle />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column" >
                        <Typography variant="subtitle2">
                            X-axis
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <FormControl sx={{m: 1,  
                                width: {
                                    sm: '47.5%',
                                    xs: '96%'
                                }}}>
                        <TextField
                            id="x-axis"
                            value={chartOptions.xAxis}
                            onChange={handleMetricChange}
                            label="Metric"
                            size="small"
                            name="xAxis"
                            select >
                            <MenuItem value={'date'}>Date</MenuItem>
                            <MenuItem value={'daysSinceFirstSession'}>Days Since First Session</MenuItem>
                            <MenuItem value={'session'}>Session Number</MenuItem>
                            <MenuItem value={'sessionType'}>Session Type</MenuItem>
                            <MenuItem value={'participantID'}>Participant ID</MenuItem>
                            {metrics.map((metric, index) => (
                                <MenuItem key={index} value={metric}>{metric}</MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl sx={{m: 1,  
                                width: {
                                    sm: '46%',
                                    xs: '96%'
                                }}}>
                        <TextField
                            id="x-axis"
                            value={chartOptions.xAxisType}
                            onChange={handleMetricChange}
                            label="Type"
                            size="small"
                            name="xAxisType"
                            select >
                            <MenuItem value={'value'}>Value</MenuItem>
                            <MenuItem value={'cumulative'}>Cumulative</MenuItem>
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column" >
                        <Typography variant="subtitle2">
                            Series
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <FormControl sx={{m: 1,  
                                width: {
                                    sm: '47.5%',
                                    xs: '48%'
                                }}}>
                        <TextField
                            id="series"
                            value={chartOptions.series}
                            onChange={handleMetricChange}
                            label="Series"
                            size="small"
                            name="series"
                            select >
                            <MenuItem value={'participantID'}>ParticipantID</MenuItem>
                            <MenuItem value={'date'}>Date</MenuItem>
                            <MenuItem value={'sessionType'}>SessionType</MenuItem>
                        </TextField>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ChartSetting;