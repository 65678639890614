import { 
    StatisticsAPIRequest, 
    StatisticsMetric, 
    ChartOptions, 
    MixedChartOptions, 
    DoughnutOptions, 
    TableOptions, 
    FilterOptions
} from '../types/project';

const chartFilterValid = (filter: FilterOptions) => {        
    if (!filter) return false;
    if (typeof filter !== 'object') {
        return false;
    } 
    if (!filter.key || typeof filter.key !== 'string') {
        return false;
    }
    if (filter.value === undefined || filter.value === null) {
        return false;
    }
    if (!filter.op) {
        if (!(filter.op === '>' ||
            filter.op === '>=' ||
            filter.op === '==' ||
            filter.op === '<' ||
            filter.op === '<=' ||
            filter.op === '!=')) {
            return false;
        }
    }
    return true;
}

const convertDataAreaToStatisticsAPIRequest = (project: string, options: ChartOptions | MixedChartOptions | DoughnutOptions | TableOptions) => {
    let request = {} as StatisticsAPIRequest;
    request.project = project;
    switch (options.type) {
        case 'bar':
        case 'scatter':
        case 'line':
            const chartOptions = options as ChartOptions;
            request.domain = chartOptions.xAxis;
            request.domainType = chartOptions.xAxisType;
            request.series = chartOptions.series;
            let chartMetric: StatisticsMetric = {
                id: chartOptions.yAxis.id,
                key: chartOptions.yAxis.key,
                type: chartOptions.yAxis.type,
            }
            if (chartFilterValid(chartOptions.filter)) chartMetric.filter = chartOptions.filter;
            request.metrics = [chartMetric];
            break;
        case 'mixed':
            const mixedOptions = options as MixedChartOptions;
            request.domain = mixedOptions.xAxis;
            request.domainType = mixedOptions.xAxisType;
            request.series = mixedOptions.series;
            let leftMetric: StatisticsMetric = {
                id: mixedOptions.leftYAxis.id,
                key: mixedOptions.leftYAxis.key,
                type: mixedOptions.leftYAxis.type,
            }
            if (chartFilterValid(mixedOptions.leftYAxisFilter)) leftMetric.filter = mixedOptions.leftYAxisFilter;
            let rightMetric: StatisticsMetric = {
                id: mixedOptions.rightYAxis.id,
                key: mixedOptions.rightYAxis.key,
                type: mixedOptions.rightYAxis.type,
            }
            if (chartFilterValid(mixedOptions.rightYAxisFilter)) rightMetric.filter = mixedOptions.rightYAxisFilter;
            request.metrics = [leftMetric, rightMetric];
            break;
        case 'doughnut':
            const doughnutOptions = options as DoughnutOptions;
            request.series = doughnutOptions.sectors;
            let doughnutMetric: StatisticsMetric = {
                id: doughnutOptions.metric.id,
                key: doughnutOptions.metric.key,
                type: doughnutOptions.metric.type,
            }
            if (chartFilterValid(doughnutOptions.filter)) doughnutMetric.filter = doughnutOptions.filter;
            request.metrics = [doughnutMetric];
            break;
        case 'table':
            const tableOptions = options as TableOptions;
            request.domain = 'date';
            request.series = 'participantID';
            let tableMetrics = [];
            for (let i = 0; i < tableOptions.metrics.length; i++) {
                let metric = tableOptions.metrics[i];
                tableMetrics.push({
                    id: metric.id,
                    key: metric.key,
                    type: metric.type,
                });
            }
            request.metrics = tableMetrics;
            break;
    }
    return request;
}

export {
    convertDataAreaToStatisticsAPIRequest
}
