import React from 'react';
import {
    Grid,
    Typography,
    Card as MuiCard,
    CardContent,
    TextField,
    MenuItem,
    FormControl,
    CardHeader,
    Accordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails,
    Divider as MuiDivider,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import styled from "styled-components/macro";
import { Box, spacing } from "@mui/system";
import TableSetting from "./TableSetting";
import ChartSetting from "./ChartSetting";
import {
    OverviewPageSettingType,
    ChartOptions,
    DataAreaOptionsType,
    TableOptions,
    DoughnutOptions,
    MixedChartOptions,
} from "../../types/project";
import DoughnutSetting from "./DoughnutSetting";
import MixedChartSetting from "./MixedChartSetting";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: 64px;
  padding-left: 16px;
  background: ${(props) => props.theme.seasPalette.blue};
  color: #FFF;
`;

type PageSettingProps = {
    settingsObject: OverviewPageSettingType,
    settingTitle: string,
    metrics: string[],
    expanded: boolean,
    notifySettingsChanged: (settings: OverviewPageSettingType) => void,
}

const PageSetting: React.FC<PageSettingProps> = ({
    settingsObject,
    settingTitle,
    metrics,
    expanded,
    notifySettingsChanged
}) => {
    // find number of data areas for the respective overview page
    const dataAreas = Object.keys(settingsObject).filter(k => k.includes('dataArea'));
    let areaTypesList: string[] = [];
    // get the default types from settings Object and assign to areaTypesList
    dataAreas.forEach((dataArea) => {
        let dataAreaKey = dataArea as keyof typeof settingsObject;
        areaTypesList.push(settingsObject[dataAreaKey].type);
    });

    const [dataAreaTypes, setDataAreaTypes] = React.useState(areaTypesList);
    const [expandedPanel, setExpandedPanel] = React.useState(expanded);
    const [pageSettings, setPageSettings] = React.useState(settingsObject)

    const handleAccordionChange = (panel: boolean) => (event: any, isExpanded: any) => {
        setExpandedPanel(isExpanded ? panel : false);
    };

    const isBasicChart = (chartType: string) => {
        return ['bar', 'line', 'scatter'].indexOf(chartType) !== -1;
    };

    const handleDataAreaTypeChange = (index: number) => (event: React.ChangeEvent<any>) => {
        const dataAreaName = event.target.name as keyof typeof settingsObject;
        // If old or new type isn't a basic chart
        // a new options object is created and the type is set to the selected type.
        if (!isBasicChart(pageSettings[dataAreaName].type) || !isBasicChart(event.target.value)) {
            const newOptions = {} as DataAreaOptionsType;
            newOptions.type = event.target.value;
            // rewrite options with the new options object
            pageSettings[dataAreaName].options = newOptions;
        }
        pageSettings[dataAreaName].type = event.target.value;

        setPageSettings(pageSettings);

        let newArr = [...dataAreaTypes]; // copying the old types array
        newArr[index] = event.target.value;
        setDataAreaTypes(newArr);

        notifySettingsChanged(pageSettings);
    };

    // decides which component (Chart, Doughnut, Mixed or Table) to display
    const displayDataAreaComponent = (dataArea: string) => {
        let dataAreaKey = dataArea as keyof typeof settingsObject;
        const dataAreaType = pageSettings[dataAreaKey].type;
        if (dataAreaType === "none") { // render empty 
            return ( <React.Fragment /> )
        }
        else if (dataAreaType === "table") { // render table setting for table component
            const tableSettingObject = pageSettings[dataAreaKey].options as TableOptions;
            return (
                <Grid item xs={12}>
                    <Card sx={{ border: "1px solid lightgrey" }}>
                        <CardHeader style={{backgroundColor: "aliceblue"}} title="Table Setting" />
                        <CardContent>
                            <TableSetting 
                                tableSettings={tableSettingObject} 
                                metrics={metrics}
                                onSettingsChange={(newValue) => {
                                    pageSettings[dataAreaKey].options = newValue;
                                    notifySettingsChanged(pageSettings);
                                }} />
                        </CardContent>
                    </Card>
                </Grid>
            )
        }
        else if (dataAreaType === "doughnut") { // render doughnut setting for doughnut component
            const doughnutSettingObject = pageSettings[dataAreaKey].options as DoughnutOptions;
            return (
                <Grid item xs={12}>
                    <Card sx={{ border: "1px solid lightgrey" }}>
                        <CardHeader style={{ backgroundColor: "aliceblue" }} title="Doughnut Chart Setting" />
                        <CardContent>
                            <DoughnutSetting 
                                doughnutSettings={doughnutSettingObject} 
                                metrics={metrics}
                                onSettingsChange={(newValue) => {
                                    pageSettings[dataAreaKey].options = newValue;
                                    notifySettingsChanged(pageSettings);
                                }} />
                        </CardContent>
                    </Card>
                </Grid>
            )
        }
        else if (dataAreaType === "mixed") { // render mixed setting for doughnut component
            const mixedSettingObject = pageSettings[dataAreaKey].options as MixedChartOptions;
            return (
                <Grid item xs={12}>
                    <Card sx={{ border: "1px solid lightgrey" }}>
                        <CardHeader style={{backgroundColor: "aliceblue"}} title="Mixed Chart Setting" />
                        <CardContent>
                            <MixedChartSetting 
                                chartSettings={mixedSettingObject} 
                                metrics={metrics}
                                onSettingsChange={(newValue) => {
                                    pageSettings[dataAreaKey].options = newValue;
                                    notifySettingsChanged(pageSettings);
                                }} />
                        </CardContent>
                    </Card>
                </Grid>
            )
        }
        else { // render default chart setting component
            const chartSettingObject = pageSettings[dataAreaKey].options as ChartOptions;
            return (
                <Grid item xs={12}>
                    <Card sx={{ border: "1px solid lightgrey" }}>
                        <CardHeader style={{backgroundColor: "aliceblue"}} title="Chart Setting" />
                        <CardContent>
                            <ChartSetting 
                                key={dataAreaType} 
                                chartSettings={chartSettingObject} 
                                metrics={metrics}
                                onSettingsChange={(newValue) => {
                                    pageSettings[dataAreaKey].options = newValue;
                                    notifySettingsChanged(pageSettings);
                                }} />
                        </CardContent>
                    </Card>
                </Grid>
            )
        }
    }

    const dataAreasList = dataAreas.map((dataArea, index) => {
        // convert key to human readable string
        const result = dataArea.replace(/([A-Z])/g, " $1");
        const areaName = result.charAt(0).toUpperCase() + result.slice(1);
        return (
            <React.Fragment key={settingTitle+dataArea}>
                <Card>
                    <CardContent>
                        <Grid container spacing={6}>
                            <Grid item xs={2}>
                                <Box
                                    height="100%"
                                    display="flex"
                                    justifyContent="center"
                                    flexDirection="column" >
                                    <Typography variant="subtitle2">
                                        { areaName }
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={10} >
                                <Box
                                    height="100%"
                                    display="flex"
                                    justifyContent="center"
                                    flexDirection="column" >
                                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                                        <TextField
                                            id={dataArea}
                                            value={dataAreaTypes[index]}
                                            onChange={handleDataAreaTypeChange(index)}
                                            label="Type"
                                            name={dataArea}
                                            size="small"
                                            select >
                                            <MenuItem value="none">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={"bar"}>Bar Chart</MenuItem>
                                            <MenuItem value={"line"}>Line Chart</MenuItem>
                                            <MenuItem value={"scatter"}>Scatter Plot</MenuItem>
                                            <MenuItem value={"doughnut"}>Doughnut Chart</MenuItem>
                                            <MenuItem value={"mixed"}>Mixed Chart</MenuItem>
                                            <MenuItem value={"table"}>Table</MenuItem>
                                        </TextField>
                                    </FormControl>
                                </Box>
                            </Grid>
                            { displayDataAreaComponent(dataArea) }
                        </Grid>
                    </CardContent>
                </Card>
                <Divider my={3} />
            </React.Fragment>
        )
    })

    return (
        <React.Fragment>
            <Card>
                <Accordion
                    expanded={expandedPanel}
                    onChange={handleAccordionChange(!expandedPanel)} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "#FFF"}}/>} >
                        <Typography variant="h5">
                            { settingTitle }
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        { dataAreasList }
                    </AccordionDetails>
                </Accordion>
            </Card>
        </React.Fragment>
    );
}

export default PageSetting;