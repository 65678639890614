import React, { useState } from "react";
import {
    FormControl, 
    Grid, 
    MenuItem, 
    SelectChangeEvent, 
    TextField, 
    Typography,
    Divider as MuiDivider,
    IconButton,
} from "@mui/material";
import { XCircle } from 'react-feather';
import styled from "styled-components/macro";
import { Box, spacing } from "@mui/system";
import { MixedChartSettingProps } from "../../types/chart";
const Divider = styled(MuiDivider)(spacing);

const MixedChartSetting: React.FC<MixedChartSettingProps>  = ({chartSettings, metrics, onSettingsChange}) => {
    // Populate any missing fields
    if (!chartSettings['xAxis']) {
        chartSettings['xAxis'] = '';
    }
    if (!chartSettings['series']) {
        chartSettings['series'] = '';
    }
    if (!chartSettings['xAxisType']) {
        chartSettings['xAxisType'] = '';
    }
    if (!chartSettings['rightYAxis']) {
        chartSettings['rightYAxis'] = {
            id: "",
            key: "",
            name: "",
            type: "",
            unit: ""
        };
    }
    else {
        chartSettings['rightYAxis'] = {
            id: chartSettings['rightYAxis'].id ? chartSettings['rightYAxis'].id : "",
            key: chartSettings['rightYAxis'].key ? chartSettings['rightYAxis'].key : "",
            name: chartSettings['rightYAxis'].name ? chartSettings['rightYAxis'].name : "",
            type: chartSettings['rightYAxis'].type ? chartSettings['rightYAxis'].type : "",
            unit: chartSettings['rightYAxis'].unit ? chartSettings['rightYAxis'].unit : ""
        };
    }
    if (!chartSettings['rightAxisType']) {
        chartSettings['rightAxisType'] = '';
    }
    if (!chartSettings['leftYAxis']) {
        chartSettings['leftYAxis'] = {
            id: "",
            key: "",
            name: "",
            type: "",
            unit: ""
        };
    }
    else {
        chartSettings['leftYAxis'] = {
            id: chartSettings['leftYAxis'].id ? chartSettings['leftYAxis'].id : "",
            key: chartSettings['leftYAxis'].key ? chartSettings['leftYAxis'].key : "",
            name: chartSettings['leftYAxis'].name ? chartSettings['leftYAxis'].name : "",
            type: chartSettings['leftYAxis'].type ? chartSettings['leftYAxis'].type : "",
            unit: chartSettings['leftYAxis'].unit ? chartSettings['leftYAxis'].unit : ""
        };
    }
    if (!chartSettings['leftAxisType']) {
        chartSettings['leftAxisType'] = '';
    }
    if (!chartSettings['leftYAxisFilter']) {
        chartSettings['leftYAxisFilter'] = {
            key: "",
            op: "",
            value: "",
        };
    }
    else {
        chartSettings['leftYAxisFilter'] = {
            key: chartSettings['leftYAxisFilter'].key ? chartSettings['leftYAxisFilter'].key : "",
            op: chartSettings['leftYAxisFilter'].op ? chartSettings['leftYAxisFilter'].op : "",
            value: chartSettings['leftYAxisFilter'].value ? chartSettings['leftYAxisFilter'].value : "",
        };
    }
    if (!chartSettings['rightYAxisFilter']) {
        chartSettings['rightYAxisFilter'] = {
            key: "",
            op: "",
            value: "",
        };
    }
    else {
        chartSettings['rightYAxisFilter'] = {
            key: chartSettings['rightYAxisFilter'].key ? chartSettings['rightYAxisFilter'].key : "",
            op: chartSettings['rightYAxisFilter'].op ? chartSettings['rightYAxisFilter'].op : "",
            value: chartSettings['rightYAxisFilter'].value ? chartSettings['rightYAxisFilter'].value : "",
        };
    }

    const [chartOptions, setChartOptions] = useState(chartSettings);

    const handleMetricChange = async (event: React.ChangeEvent<any>| SelectChangeEvent) => {
        const { target: { name, value } } = event;
        let newState;
        
        switch (name) {
            case 'xAxis':
            case 'series':
            case 'xAxisType':
            case 'leftAxisType':
            case 'rightAxisType':
                newState = {...chartOptions, [name] : value };
                break;
            case 'rightYAxis.key':
                newState = {...chartOptions, 'rightYAxis': { ...chartOptions.rightYAxis, id: value + "_right", key : value }};
                break;
            case 'rightYAxis.name':
                newState = {...chartOptions, 'rightYAxis': { ...chartOptions.rightYAxis, name : value }};
                break;
            case 'rightYAxis.type':
                newState = {...chartOptions, 'rightYAxis': { ...chartOptions.rightYAxis, type : value }};
                break;
            case 'rightYAxis.unit':
                newState = {...chartOptions, 'rightYAxis': { ...chartOptions.rightYAxis, unit : value }};
                break;
            case 'leftYAxis.key':
                newState = {...chartOptions, 'leftYAxis': { ...chartOptions.leftYAxis, id: value + "_left", key : value }};
                break;
            case 'leftYAxis.name':
                newState = {...chartOptions, 'leftYAxis': { ...chartOptions.leftYAxis, name : value }};
                break;
            case 'leftYAxis.type':
                newState = {...chartOptions, 'leftYAxis': { ...chartOptions.leftYAxis, type : value }};
                break;
            case 'leftYAxis.unit':
                newState = {...chartOptions, 'leftYAxis': { ...chartOptions.leftYAxis, unit : value }};
                break;
            case 'leftYAxisFilter.key':
                newState = {...chartOptions, 'leftYAxisFilter': { ...chartOptions.leftYAxisFilter, key : value }};
                break;
            case 'leftYAxisFilter.op':
                newState = {...chartOptions, 'leftYAxisFilter': { ...chartOptions.leftYAxisFilter, op : value }};
                break;
            case 'leftYAxisFilter.value':
                newState = {...chartOptions, 'leftYAxisFilter': { ...chartOptions.leftYAxisFilter, value : value }};
                break;
            case 'rightYAxisFilter.key':
                newState = {...chartOptions, 'rightYAxisFilter': { ...chartOptions.rightYAxisFilter, key : value }};
                break;
            case 'rightYAxisFilter.op':
                newState = {...chartOptions, 'rightYAxisFilter': { ...chartOptions.rightYAxisFilter, op : value }};
                break;
            case 'rightYAxisFilter.value':
                newState = {...chartOptions, 'rightYAxisFilter': { ...chartOptions.rightYAxisFilter, value : value }};
                break;
            default:
                newState = { ...chartOptions };
        }
        await setChartOptions(newState);
        onSettingsChange(newState);
    };
    const handleLeftFilterClearButtonClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let newState = {...chartOptions, 'leftYAxisFilter': { key: "", op: "", value: "" }};
        setChartOptions(newState);
        onSettingsChange(newState);
    };
    const handleRightFilterClearButtonClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let newState = {...chartOptions, 'rightYAxisFilter': { key: "", op: "", value: "" }};
        setChartOptions(newState);
        onSettingsChange(newState);
    };

    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column" >
                        <Typography variant="subtitle2">
                            Left Chart Type
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { 
                                m: 1, 
                                width: {
                                    sm: '47.5%',
                                    xs: '96%'
                                }
                            },
                        }}
                        noValidate
                        autoComplete="off" >
                        <FormControl>
                            <TextField
                                id="leftAxisType"
                                value={chartOptions.leftAxisType }
                                onChange={handleMetricChange}
                                label="Chart Type"
                                size="small"
                                name="leftAxisType" 
                                select >
                                <MenuItem value={"bar"}>Bar Chart</MenuItem>
                                <MenuItem value={"line"}>Line Chart</MenuItem>
                                <MenuItem value={"scatter"}>Scatter Plot</MenuItem>
                            </TextField>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column" >
                        <Typography variant="subtitle2">
                            Left Y-Axis
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { 
                                m: 1, 
                                width: {
                                    sm: '48%',
                                    md: '23%',
                                    xs: '96%'
                                }
                            },
                        }}
                        noValidate
                        autoComplete="off" >
                        <FormControl>
                            <TextField
                                id="key"
                                value={chartOptions.leftYAxis.key}
                                onChange={handleMetricChange}
                                label="Metric"
                                size="small"
                                name="leftYAxis.key"
                                select >
                                {metrics.map((metric, index) => (
                                    <MenuItem key={index} value={metric}>{metric}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                        <TextField 
                            id="name"
                            label="Display Name"
                            variant="outlined"
                            size="small"
                            defaultValue={chartOptions.leftYAxis.name}
                            onChange={handleMetricChange}
                            name="leftYAxis.name" />
                        <FormControl>
                            <TextField
                                id="yaxis-metric-type"
                                value={chartOptions.leftYAxis.type}
                                onChange={handleMetricChange}
                                label="Type"
                                size="small"
                                name="leftYAxis.type"
                                select >
                                <MenuItem value={"average"}>Average</MenuItem>
                                <MenuItem value={"total"}>Total</MenuItem>
                                <MenuItem value={"cumulative"}>Cumulative</MenuItem>
                            </TextField>
                        </FormControl>
                        <TextField
                            id="unit"
                            label="Unit"
                            defaultValue={chartOptions.leftYAxis.unit}
                            size="small"
                            onChange={handleMetricChange}
                            name="leftYAxis.unit" />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column">
                        <Typography variant="subtitle2">
                            Filter (optional)
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10} container>
                    <Grid item xs={11}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { 
                                    m: 1, 
                                    width: {
                                        sm: '48%',
                                        md: '32%',
                                        xs: '96%'
                                    }
                                },
                            }}
                            noValidate
                            autoComplete="off" >
                            <FormControl>
                                <TextField
                                    id="leftYAxisFilter.key"
                                    value={chartOptions.leftYAxisFilter.key}
                                    onChange={handleMetricChange}
                                    label="Metric"
                                    size="small"
                                    name="leftYAxisFilter.key"
                                    select >
                                    {metrics.map((metric, index) => (
                                        <MenuItem key={index} value={metric}>{metric}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <FormControl>
                                <TextField
                                    id="leftYAxisFilter.op"
                                    value={chartOptions.leftYAxisFilter.op}
                                    onChange={handleMetricChange}
                                    label="Operation"
                                    size="small"
                                    name="leftYAxisFilter.op"
                                    select >
                                    <MenuItem value={"=="}>{"=="}</MenuItem>
                                    <MenuItem value={"!="}>{"!="}</MenuItem>
                                    <MenuItem value={">"}>{">"}</MenuItem>
                                    <MenuItem value={">="}>{">="}</MenuItem>
                                    <MenuItem value={"<"}>{"<"}</MenuItem>
                                    <MenuItem value={"<="}>{"<="}</MenuItem>
                                </TextField>
                            </FormControl>
                            <TextField 
                                id="leftYAxisFilter.value"
                                label="Value"
                                value={chartOptions.leftYAxisFilter.value}
                                size="small"
                                onChange={handleMetricChange}
                                name="leftYAxisFilter.value" />
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton 
                            aria-label="clear"  
                            onClick={handleLeftFilterClearButtonClick}>
                            <XCircle />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}> 
                    <Divider my={3} />
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column" >
                        <Typography variant="subtitle2">
                            Right Chart Type
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { 
                                m: 1, 
                                width: {
                                    sm: '47.5%',
                                    xs: '96%'
                                }
                            },
                        }}
                        noValidate
                        autoComplete="off" >
                        <FormControl>
                            <TextField
                                id="rightAxisType"
                                value={chartOptions.rightAxisType }
                                onChange={handleMetricChange}
                                label="Chart Type"
                                size="small"
                                name="rightAxisType"
                                select >
                                <MenuItem value={"bar"}>Bar Chart</MenuItem>
                                <MenuItem value={"line"}>Line Chart</MenuItem>
                                <MenuItem value={"scatter"}>Scatter Plot</MenuItem>
                            </TextField>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column" >
                        <Typography variant="subtitle2">
                            Right Y-Axis
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { 
                                m: 1, 
                                width: {
                                    sm: '48%',
                                    md: '23%',
                                    xs: '96%'
                                }
                            },
                        }}
                        noValidate
                        autoComplete="off" >
                        <FormControl>
                            <TextField
                                id="key"
                                value={chartOptions.rightYAxis.key }
                                onChange={handleMetricChange}
                                label="Metric"
                                size="small"
                                name="rightYAxis.key"
                                select >
                                {metrics.map((metric, index) => (
                                    <MenuItem key={index} value={metric}>{metric}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                        <TextField 
                            id="display-name"
                            label="Display Name"
                            variant="outlined"
                            size="small"
                            defaultValue={chartOptions.rightYAxis.name}
                            onChange={handleMetricChange}
                            name="rightYAxis.name" />
                        <FormControl>
                            <TextField
                                id="yaxis-metric-type"
                                value={chartOptions.rightYAxis.type}
                                onChange={handleMetricChange}
                                label="Type"
                                size="small"
                                name="rightYAxis.type"
                                select >
                                <MenuItem value={"average"}>Average</MenuItem>
                                <MenuItem value={"total"}>Total</MenuItem>
                                <MenuItem value={"cumulative"}>Cumulative</MenuItem>
                            </TextField>
                        </FormControl>
                        <TextField
                            id="unit"
                            label="Unit"
                            defaultValue={chartOptions.rightYAxis.unit}
                            size="small"
                            onChange={handleMetricChange}
                            name="rightYAxis.unit" />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column">
                        <Typography variant="subtitle2">
                            Filter (optional)
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10} container>
                    <Grid item xs={11}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { 
                                    m: 1, 
                                    width: {
                                        sm: '48%',
                                        md: '32%',
                                        xs: '96%'
                                    }
                                },
                            }}
                            noValidate
                            autoComplete="off" >
                            <FormControl>
                                <TextField
                                    id="rightYAxisFilter.key"
                                    value={chartOptions.rightYAxisFilter.key}
                                    onChange={handleMetricChange}
                                    label="Metric"
                                    size="small"
                                    name="rightYAxisFilter.key"
                                    select >
                                    {metrics.map((metric, index) => (
                                        <MenuItem key={index} value={metric}>{metric}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <FormControl>
                                <TextField
                                    id="rightYAxisFilter.op"
                                    value={chartOptions.rightYAxisFilter.op}
                                    onChange={handleMetricChange}
                                    label="Operation"
                                    size="small"
                                    name="rightYAxisFilter.op"
                                    select >
                                    <MenuItem value={"=="}>{"=="}</MenuItem>
                                    <MenuItem value={"!="}>{"!="}</MenuItem>
                                    <MenuItem value={">"}>{">"}</MenuItem>
                                    <MenuItem value={">="}>{">="}</MenuItem>
                                    <MenuItem value={"<"}>{"<"}</MenuItem>
                                    <MenuItem value={"<="}>{"<="}</MenuItem>
                                </TextField>
                            </FormControl>
                            <TextField 
                                id="rightYAxisFilter.value"
                                label="Value"
                                value={chartOptions.rightYAxisFilter.value}
                                size="small"
                                onChange={handleMetricChange}
                                name="rightYAxisFilter.value" />
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton 
                            aria-label="clear"  
                            onClick={handleRightFilterClearButtonClick}>
                            <XCircle />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}> 
                    <Divider my={3} />
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column" >
                        <Typography variant="subtitle2">
                            X-Axis
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <FormControl 
                        sx={{m: 1,  
                        width: {
                            sm: '47.5%',
                            xs: '96%'
                        }}} >
                        <TextField
                            id="xAxis"
                            value={chartOptions.xAxis}
                            onChange={handleMetricChange}
                            label="Metric"
                            size="small"
                            name="xAxis"
                            select >
                            <MenuItem value={'date'}>Date</MenuItem>
                            <MenuItem value={'daysSinceFirstSession'}>Days Since First Session</MenuItem>
                            <MenuItem value={'session'}>Session Number</MenuItem>
                            <MenuItem value={'sessionType'}>Session Type</MenuItem>
                            <MenuItem value={'participantID'}>Participant ID</MenuItem>
                            {metrics.map((metric, index) => (
                                <MenuItem key={index} value={metric}>{metric}</MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl sx={{m: 1,  
                                width: {
                                    sm: '46%',
                                    xs: '96%'
                                }}}>
                        <TextField
                            id="x-axis"
                            value={chartOptions.xAxisType}
                            onChange={handleMetricChange}
                            label="Type"
                            size="small"
                            name="xAxisType"
                            select >
                            <MenuItem value={'value'}>Value</MenuItem>
                            <MenuItem value={'cumulative'}>Cumulative</MenuItem>
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column" >
                        <Typography variant="subtitle2">
                            Series
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <FormControl 
                        sx={{m: 1,  
                        width: {
                            sm: '47.5%',
                            xs: '96%'
                        }}} >
                        <TextField
                            id="series"
                            value={chartOptions.series}
                            onChange={handleMetricChange}
                            label="Series"
                            size="small"
                            name="series"
                            select >
                            <MenuItem value={'participantID'}>ParticipantID</MenuItem>
                            <MenuItem value={'date'}>Date</MenuItem>
                            <MenuItem value={'sessionType'}>SessionType</MenuItem>
                        </TextField>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default MixedChartSetting;