import { DisplayChartData } from "../types/chart";
import { ChartOptions, MixedChartOptions, DoughnutOptions, TableOptions } from "../types/project";

import axios from "./axios";

const convertChartToCSV = (data: DisplayChartData, options: ChartOptions) => {
    if (!data || !options) return "";
    let dataString = options?.series + "," + options?.xAxis + "," + options?.yAxis?.key + ",\n";
    for (let i = 0; i < data.datasets.length; i++) {
        const dataset = data.datasets[i];
        if (!dataset) continue;
        for (let j = 0; j < dataset.data.length; j++) {
            let dataPoint = dataset.data[j];
            if (!dataPoint || !dataPoint.x || !dataPoint.y) continue;
            dataString += dataset.label + "," + dataPoint.x + "," + dataPoint.y + "\n";
        }
    }
    return dataString;
}

const convertDoughnutChartToCSV = (data: DisplayChartData, options: DoughnutOptions) => {
    if (!data || !options) return "";
    let dataString = options?.sectors + "," + options?.metric?.key + ",\n";
    const dataset = data.datasets[0];
    if (!dataset || !data.labels) return dataString;
    for (let i = 0; i < dataset.data.length; i++) {
        let dataPoint = dataset.data[i];
        let dataLabel = data.labels[i];
        if (!dataPoint || !dataLabel) continue;
        dataString += dataLabel + "," + dataPoint + "\n";
    }
    return dataString;
}

const convertMixedChartToCSV = (data: DisplayChartData, options: MixedChartOptions) => {
    if (!data || !options) return "";
    let dataString = options?.series + "," + options?.xAxis + "," + options?.leftYAxis?.key + "," + options?.rightYAxis?.key + ",\n";
    for (let i = 0; i < data.datasets.length; i++) {
        const dataset = data.datasets[i];
        if (!dataset) continue;
        let label: string = dataset.label;
        let series = "";
        let offset = "";
        if (label?.endsWith(options?.leftYAxis?.key)) {
            series = label.substring(0, label.length - options?.leftYAxis?.key.length - 1);
        }
        else if (label?.endsWith(options?.rightYAxis?.key)) {
            series = label.substring(0, label.length - options?.rightYAxis?.key.length - 1);
            offset = ",";
        }
        else continue;
        for (let j = 0; j < dataset.data.length; j++) {
            let dataPoint = dataset.data[j];
            if (!dataPoint || !dataPoint.x || !dataPoint.y) continue;
            dataString += series + "," + dataPoint.x + "," + offset + dataPoint.y + "\n";
        }
    }
    return dataString;
}

const convertTableToCSV = (data: any, options: TableOptions) => {
    if (!data || !options) return "";
    let dataString = "participantID,";
    // Add header
    for (let i = 0; i < options.metrics.length; i++) {
        let metric = options.metrics[i];
        dataString += metric.name + " (" + metric.type + "),";
    }
    dataString += "\n";
    for (let i = 0; i < data.length; i++) {
        let dataPoint = data[i];
        let dataLabel = dataPoint?.participantID;
        if (!dataPoint || !dataLabel) continue;
        dataString += dataLabel + ",";
        for (let j = 0; j < options.metrics.length; j++) {
            let metric = options.metrics[j];
            let dataValue = dataPoint[metric?.name];
            if (dataValue) dataString += dataValue;
            dataString += ",";
        }
        dataString += "\n";
    }
    return dataString;
}

const createBlobUrl = (data: string, type: string = 'application/json') => {
    const dataBlob = new Blob([data], {type: type});
    return window.URL.createObjectURL(dataBlob);
}

const downloadBlobFile = (filename: string, href: string) => {
    const link = document.createElement('a');
    link.download = filename;
    link.href = href;
    link.click();
    link.remove();
}

function downloadDataFiles(filenames: string[], project: string) {
    filenames.forEach((file, i) => {
        setTimeout(() => {
            downloadDataFile(file, project)
        }, i * 50)
    })
}

function downloadDataFile(filename: string, project: string) {
    axios.get('/api/data/download', {
        params: {
            filename: filename,
            project: project
        },
        responseType: 'blob'
    })
    .then((response) => {
        let type = response.headers['content-type'];
        downloadBlobFile(filename, createBlobUrl(response.data, type));
    })
    .catch((error) => {
        console.log(error);
        let errorMsg = "";
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 500) {
                errorMsg = "Internal Server Error";
            }
            else if (error.response.status === 404) {
                errorMsg = "File Not Found";
            }
            else {
                errorMsg = "Unknown Server Error";
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            errorMsg = "Invalid Request";
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            errorMsg = "Unknown Error";
        }
        console.log(error.config);
        console.log(errorMsg);
        // this.setState((prevState) => ({
        //     ...prevState,
        //     loading: false,
        //     error: true,
        //     errormsg: errorMsg
        // }))
    })
}


export { convertChartToCSV, convertDoughnutChartToCSV, downloadBlobFile, downloadDataFile, downloadDataFiles, createBlobUrl, convertMixedChartToCSV, convertTableToCSV }
