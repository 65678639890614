import React, { useEffect } from "react";

import styled from "styled-components/macro";
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Grid,
    Box,
    Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useProjectData from '../../hooks/useProjectData';
import useSessionData from '../../hooks/useSessionData';
import useParticipantData from '../../hooks/useParticipantData';
import SessionDataFiles from '../../components/session/SessionDataFiles';
import useNavigationData from '../../hooks/useNavigation';
import { toAmPmTimeString, toReadableDateString } from "../../utils/datetime";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const Divider = styled(MuiDivider)(spacing);

type SessionOverviewCardProps = {
    date: string;
    time: string;
    device: string;
    type: string;
};

const SessionOverviewCard: React.FC<SessionOverviewCardProps> = ({
    date,
    time,
    device,
    type,
}) => {

    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Session Descriptors
                </Typography>
                <br />
                <Typography variant="h6" mb={3}>
                    <Box fontWeight="fontWeightRegular">Date: {toReadableDateString(date)}</Box>
                    <Box fontWeight="fontWeightRegular">Time: {toAmPmTimeString(time)}</Box>
                    <Box fontWeight="fontWeightRegular">DeviceID: {device}</Box>
                    <Box fontWeight="fontWeightRegular">SessionType: {type}</Box>
                </Typography>
            </CardContent>
        </Card>
    );
};

type SessionExtrasCardProps = {
    extras: any;
    metrics: string[];
};

const SessionExtrasCard: React.FC<SessionExtrasCardProps> = ({
    extras,
    metrics,
}) => {
    metrics = metrics || [];
    const extrasList = metrics.map((metric, index) => {
        if (extras?.[metric]) {
            let metricValue = extras[metric];
            if (typeof metricValue === 'string' && !Number.isNaN(Number(metricValue))) {
                metricValue = Number(metricValue);
            }
            if (typeof metricValue === 'number' && !Number.isInteger(metricValue)) {
				metricValue = metricValue.toFixed(3);	
            }
            return (
                <Box fontWeight="fontWeightRegular" key={index}>
                    {metric}: {metricValue}
                </Box>
            )
        }
        else {
            return <React.Fragment key={index}/>;
        }
    });

    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Session Metrics
                </Typography>
                <br />
                <Typography variant="h6" mb={3}>
                    {extrasList}
                </Typography>
            </CardContent>
        </Card>
    );
};

function SessionOverview() {
    const { setSession } = useNavigationData();
    const { selectedSession, loadSession, sessionData, sessionDataLoading } = useSessionData();
    const { selectedProject, projectSettings } = useProjectData();
    const { selectedParticipant } = useParticipantData();

    useEffect(() => {
        setSession(selectedSession.date); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const load = async () => {
            loadSession(selectedProject, selectedParticipant, selectedSession.date, selectedSession.time);
        }
        if (!sessionData && !sessionDataLoading) {
            load();
        }
    }, [selectedProject, selectedParticipant, loadSession, sessionData, sessionDataLoading, selectedSession]);

    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                Session Overview
            </Typography>

            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    { 
                        sessionData && 
                        <SessionOverviewCard 
                            date={sessionData.date} 
                            time={sessionData.time} 
                            device={sessionData.deviceID} 
                            type={sessionData.sessionType} />
                    }
                    { 
                        !sessionData && 
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Session Data
                                </Typography>
                                <br />
                                <Typography variant="h6" mb={3}>
                                    <Box fontWeight="fontWeightRegular">No Data to display</Box>
                                </Typography>
                            </CardContent>
                        </Card>
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    <SessionExtrasCard  
                        extras={sessionData?.extras}
                        metrics={projectSettings?.metrics || []} />
                </Grid>
                <Grid item xs={12}>
                    {
                        sessionData && 
                        <SessionDataFiles project={selectedProject} session={sessionData} />
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default SessionOverview;
