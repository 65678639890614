import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components/macro";
import Chart from "react-chartjs-2";
import {CardContent, CardHeader as MuiCardHeader, Divider} from "@mui/material";
import { MixedChartOptions } from '../../types/project';
import { ChartData, ChartSeries } from '../../types/chart';
import FileDownloadButton from '../data/FileDownloadButton';
import { createBlobUrl, convertMixedChartToCSV } from '../../utils/io';
import { getDateTimeString } from '../../utils/datetime';

type WrapperProps = {
    height: number;
}

const ChartWrapper = styled.div<WrapperProps>`
  height: ${(props) => props.height}px;
`;

const CardHeader = styled(MuiCardHeader)`
  min-height: 50px;
  background: ${(props) => props.theme.seasPalette.blue};
  color: #FFF;
`;

type MixedChartProps = {
    height: number,
    chartOptions: MixedChartOptions,
    chartData: ChartData,
    isFiltered: ( id: string ) => boolean,
}

function createDiagonalPattern(color = 'black') {
    let shape = document.createElement('canvas')
    shape.width = 10
    shape.height = 10
    let c : CanvasRenderingContext2D | null = shape.getContext('2d');
    if (c) {
        c.strokeStyle = color;
        c.beginPath();
        c.moveTo(2, 0);
        c.lineTo(10, 8);
        c.stroke();
        c.beginPath();
        c.moveTo(0, 8);
        c.lineTo(2, 10);
        c.stroke();
        return c.createPattern(shape, 'repeat');
    }
}

const generateMixedChartData = (data: ChartData, chartOptions: MixedChartOptions, colors: string[], isFiltered: (id: string) => boolean) => {
    let dataSets = [];
    let labels: string[] = [];
    let xAxisType = 'category';
    const getSeriesData = (series: ChartSeries, leftSeriesData: any[], rightSeriesData: any[]) => {
        for (let j = 0; j < series.plots.length; j++) {
            let dataPoint = series.plots[j];
            if (isFiltered(dataPoint.participantID)) continue;
            const domainValue = dataPoint[chartOptions.xAxis];            
            if ((typeof domainValue === 'number' || !Number.isNaN(parseFloat(domainValue))) && 
                chartOptions.xAxis !== "daysSinceFirstSession") {
                xAxisType = 'linear';
            }
            if (!domainValue && domainValue !== 0) continue;
            if (labels.indexOf(domainValue) === -1) labels.push(domainValue);
            const leftMetricValue = dataPoint[chartOptions.leftYAxis.id];
            const rightMetricValue = dataPoint[chartOptions.rightYAxis.id];
            if (leftMetricValue) {
                leftSeriesData.push({
                    x: domainValue,
                    y: leftMetricValue
                });
            }
            if (rightMetricValue) {
                rightSeriesData.push({
                    x: domainValue,
                    y: rightMetricValue
                });
            }
        }
    }
    data = data || [];
    for (let i = 0; i < data.length; i++) {
        let series = data[i];
        if(chartOptions.series === 'participantID' && isFiltered(series.name)) continue;
        let leftSeriesData: any = [];
        let rightSeriesData: any = [];
        getSeriesData(series, leftSeriesData, rightSeriesData);
        let leftSeriesOptions: any = {
            type: chartOptions.leftAxisType,
            label: series.name + '_' + chartOptions.leftYAxis.key,
            data: leftSeriesData,
            yAxisID: 'y1',
        }
        let rightSeriesOptions: any = {
            type: chartOptions.rightAxisType,
            label: series.name + '_' + chartOptions.rightYAxis.key,
            data: rightSeriesData,
            yAxisID: 'y2',
        }

        switch (chartOptions.rightAxisType) {
            case 'bar':
                rightSeriesOptions.backgroundColor = colors[i % colors.length];
                rightSeriesOptions.borderColor = colors[i % colors.length];
                rightSeriesOptions.barPercentage = 0.75;
                rightSeriesOptions.categoryPercentage = 0.5;
                if (chartOptions.leftAxisType === 'bar') {
                    rightSeriesOptions.backgroundColor = createDiagonalPattern(colors[i % colors.length]);
                    rightSeriesOptions.borderColor = colors[i % colors.length];
                    rightSeriesOptions.borderWidth = 1;
                }
                break;
            case 'line':
                rightSeriesOptions.fill = false;
                rightSeriesOptions.borderColor = colors[i % colors.length];
                rightSeriesOptions.backgroundColor = colors[i % colors.length];
                if (chartOptions.leftAxisType === 'line') {
                    rightSeriesOptions.borderDash = [6,3];
                }
                break;
            case 'scatter':
                rightSeriesOptions.fill = false;
                rightSeriesOptions.borderColor = "#000";
                rightSeriesOptions.backgroundColor = colors[i % colors.length];
                if (chartOptions.leftAxisType === 'scatter') {
                    rightSeriesOptions.elements = {
                        point: {
                            pointStyle: 'cross'
                        }
                    }
                    rightSeriesOptions.borderColor = colors[i % colors.length];
                }
                break;
        }
        switch (chartOptions.leftAxisType) {
            case 'bar':
                leftSeriesOptions.backgroundColor = colors[i % colors.length];
                leftSeriesOptions.borderColor = colors[i % colors.length];
                leftSeriesOptions.barPercentage = 0.75;
                leftSeriesOptions.categoryPercentage = 0.5;
                break;
            case 'line':
                leftSeriesOptions.fill = false;
                leftSeriesOptions.borderColor = colors[i % colors.length];
                leftSeriesOptions.backgroundColor = colors[i % colors.length];
                break;
            case 'scatter':
                leftSeriesOptions.fill = false;
                leftSeriesOptions.borderColor = "#000";
                leftSeriesOptions.backgroundColor = colors[i % colors.length];
                break;
        }
        if (leftSeriesData.length > 0) {
            dataSets.push(leftSeriesOptions);
        }
        if (rightSeriesData.length > 0) {
            dataSets.push(rightSeriesOptions);
        }
    }

    dataSets.sort((a, b) => {
        if (a.type === b.type) return 0;
        if (a.type === 'bar') return 1;
        if (b.type === 'bar') return -1;
        return a.type === 'line' ? 1 : -1;
    });
    return {
        labels: labels.sort((a: string, b: string) => {
            // Check if a and b are numbers
            if ((!Number.isNaN(Number(a))) && (!Number.isNaN(Number(b)))) {
                return Number(a) < Number(b) ? -1 : 1;
            }
            return a > b ? 1 : -1;
        }),
        datasets: dataSets,
        xAxisType: xAxisType,
    }
}

function MixedChart({ height, chartOptions, chartData, isFiltered }: MixedChartProps) {
    const theme = useContext(ThemeContext);
    const xAxisLabel: string = chartOptions.xAxis;            
    let leftAxisTypeSignifier;
    switch (chartOptions.leftAxisType) {
        case 'bar':
            if (chartOptions.rightAxisType === 'bar') {
                leftAxisTypeSignifier = "Solid Bar";
            }
            else {
                leftAxisTypeSignifier = "Bar";
            }
            break;
        case 'line':
            if (chartOptions.rightAxisType === 'line') {
                leftAxisTypeSignifier = "Solid Line";
            }
            else {
                leftAxisTypeSignifier = "Line";
            }
            break;
        case 'scatter':
            if (chartOptions.rightAxisType === 'scatter') {
                leftAxisTypeSignifier = "Circle";
            }
            else {
                leftAxisTypeSignifier = "Scatter";
            }
            break;
    }
    let rightAxisTypeSignifier;
    switch (chartOptions.rightAxisType) {
        case 'bar':
            if (chartOptions.leftAxisType === 'bar') {
                rightAxisTypeSignifier = "Diagonal Bar";
            }
            else {
                rightAxisTypeSignifier = "Bar";
            }
            break;
        case 'line':
            if (chartOptions.leftAxisType === 'line') {
                rightAxisTypeSignifier = "Dashed Line";
            }
            else {
                rightAxisTypeSignifier = "Line";
            }
            break;
        case 'scatter':
            if (chartOptions.leftAxisType === 'scatter') {
                rightAxisTypeSignifier = "Cross";
            }
            else {
                rightAxisTypeSignifier = "Scatter";
            }
            break;
    }
    const leftYAxisLabel = chartOptions.leftYAxis.name + ` [${leftAxisTypeSignifier}]`;
    const rightYAxisLabel = chartOptions.rightYAxis.name + ` [${rightAxisTypeSignifier}]`;
    const chartTitle: string = `${chartOptions.leftYAxis.name} - ${chartOptions.rightYAxis.name}`;
    const mixedChartData = generateMixedChartData(chartData, chartOptions, theme.charts.colors, isFiltered);
    const mixedChartOptions = {
        animation: {
            duration: 0
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                align: 'left',
                labels: {                          
                    filter: (item: any, chart: any) => {
                        if (item.text.includes(chartOptions.rightYAxis.key)) return false;
                            item.text = item.text.substring(0, item.text.length - chartOptions.leftYAxis.key.length - 1);
                        return true;
                    }
                },
                onClick: (event: any) => {}
            },
        },
        scales: {
            y1: {
                grid: {
                    display: true,
                },
                position: 'left',
                stacked: false,
                ticks: {
                    stepSize: 2,
                },
                title: {
                    display: true,
                    text: leftYAxisLabel,
                },
            },
            y2: {
                type: "linear",
                display: true,
                position: 'right',
                borderDash: [5, 5],
                grid: {
                    color: "rgba(0,0,0,0)",
                    fontColor: "#fff",
                },
                title: {
                    display: true,
                    text: rightYAxisLabel,
                },
            },
            x: {
                type: mixedChartData.xAxisType,
                stacked: false,
                grid: {
                    color: "transparent",
                },
                title: {
                    display: true,
                    text: xAxisLabel
                }
            },
        },
    };

    // Data File Info
    const filename = chartOptions?.leftYAxis?.key + "-" + chartOptions?.rightYAxis?.key + "-" + chartOptions?.xAxis + "-" + chartOptions?.series + "-" + getDateTimeString() + ".csv";
    const href = createBlobUrl(convertMixedChartToCSV(mixedChartData, chartOptions));

    return (
        <React.Fragment>
            <CardHeader
                title={chartTitle}
                action={<FileDownloadButton filename={filename} href={href}/>}
            />
            <Divider />
            <CardContent>
                <ChartWrapper height={height}>
                    <Chart type="line" data={mixedChartData} options={mixedChartOptions} />
                </ChartWrapper>
            </CardContent>
        </React.Fragment>
    );
}

export default MixedChart;
