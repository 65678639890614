import React, {ChangeEvent} from 'react';
import {
    Grid,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Typography, Button,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ChartFilterElement } from '../../types/chart';

type FilterDetailsProps = {
    filterKey: keyof ChartFilterElement,
    filterOptions: ChartFilterElement[],
    onChange: (id: string, value: boolean) => void,
}

const FilterDetails: React.FC<FilterDetailsProps> = ({filterKey, filterOptions, onChange}) => {
    function onCheckedChanged(event: ChangeEvent<HTMLInputElement>, checked:boolean) {
        onChange(event.target.id, checked);
    }
    const filterOptionsList = filterOptions?.map((option, index) => {
        const filterValue = option[filterKey];
        return (
            <Grid item xs={12} sm={6} md={3} key={index}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={!option.filtered} onChange={onCheckedChanged} id={`${filterValue}`}/>} label={`${filterValue}`} />
                </FormGroup>
            </Grid>
        );
    });

    return (
        <Grid container spacing={3}
              sx={{
                  marginTop: '2px',
                  marginRight: 10,
                  marginLeft: 1,
                  bgcolor: '#F5F5F5',
                  border: '1px solid',
                  borderColor: '#E0E0E0',
                  borderRadius: 2,
              }}
        >
            {filterOptionsList}
        </Grid>
    );
}

type DataFilterProps = {
    filterKey: keyof ChartFilterElement,
    filterTitle: string,
    filterOptions: ChartFilterElement[],
    onFilterChanged: (filter: ChartFilterElement[]) => void;
}

const DataFilter: React.FC<DataFilterProps> = ({ filterTitle, filterKey, filterOptions, onFilterChanged }) => {
    const [showFilterList, setShowFilterList] = React.useState(true);
    const handleClick = () => {
        setShowFilterList(!showFilterList);
    };

    
    function onItemChanged(id: string, value: boolean) {
        if (!filterOptions || filterOptions.length === 0) return false;
        // Toggle filter value for id
        const newArr = filterOptions.map((item: any) => {
            item.filtered = (item.participantID === id) ? !value : item.filtered;
            return item;
        });
        onFilterChanged(newArr);
    }

    const showParticipantList = () => {
        if (!showFilterList) {
            return ( <React.Fragment /> )
        }
        else {
            return (
                <FilterDetails
                    filterKey={filterKey}
                    filterOptions={filterOptions}
                    onChange={onItemChanged}/>
            )
        }
    }
    return (
        <Grid container spacing={3} style={{'margin': '10px'}}>
            <Button
                size="small"
                variant="text"
                onClick={handleClick} >
                <Typography variant="h6">
                    {'Filter Participants'}
                </Typography>
                <ArrowDropDownIcon />
            </Button>
            { showParticipantList() }
        </Grid>
    );
}

export default DataFilter;