import React from "react";
import styled from "styled-components/macro";
import Chart from "react-chartjs-2";

import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

type WrapperProps = {
    height: number;
}

const ChartWrapper = styled.div<WrapperProps>`
  height: ${(props) => props.height}px;
`;

type LineChartProps = {
    height: number,
    title: string,
    data: any,
    type: string,
}

function SimpleChart({ height, title, data, type }: LineChartProps) {
    const options =  {
        animation: {
            duration: 0
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                },
                title: {
                    display: false,
                },
            },
            y: {
                type: "linear",
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
                title: {
                    display: false,
                },
                beginAtZero: true,
            },
        },
    };
  return (
        <Card mb={1}>
        <CardContent>
            <Typography variant="body2" sx={{textAlign:'center', mb: 5}}>
                {title}
            </Typography>
            <ChartWrapper height={height}>
                <Chart type={type} data={data} options={options} />
            </ChartWrapper>
        </CardContent>
        </Card>
  );
}

export default SimpleChart;
