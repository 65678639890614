import React from 'react';
import {ProjectSessionTemplate} from '../../types/project';
import {
    Grid,
    Typography,
    Card as MuiCard,
    CardContent,
    Button,
    Accordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails,
    Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import styled from "styled-components/macro";
import TemplateSettings from "./TemplateSettings";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: 64px;
  padding-left: 16px;
  background: ${(props) => props.theme.seasPalette.blue};
  color: #FFF;
`;

type TemplateSettingsSectionProps = {
    templates: ProjectSessionTemplate[],
    metrics: string[],
    title: string,
    expanded: boolean,
    notifySettingsChanged: (templates: ProjectSessionTemplate[]) => void,
}

const TemplateSettingsSection: React.FC<TemplateSettingsSectionProps> = ({
    templates,
    metrics,
    title,
    expanded,
    notifySettingsChanged
}) => {

    if (!templates) {
        templates =  [];
    }
    const [expandedPanel, setExpandedPanel] = React.useState(expanded);
    const handleAccordionChange = (panel: boolean) => (event: any, isExpanded: any) => {
        setExpandedPanel(isExpanded ? panel : false);
    };

    const notifyTemplateChanged = (index: number, template: ProjectSessionTemplate) => {
        let newState;
        newState = [ ...projectTemplates ];
        newState[index] = template;
        setProjectTemplates(newState);
        notifySettingsChanged(newState);
    }
    const [projectTemplates, setProjectTemplates] = React.useState(templates);
    const onAddBtnClick = () => {
        let newState;
        newState = [ ...projectTemplates, { sessionType: '', metrics: [] } ];
        setProjectTemplates(newState);
        notifySettingsChanged(newState);
    };

    const deleteTemplate = (templateIndex: number) => {
        let newState;
        newState = [ ...projectTemplates.slice(0, templateIndex), ...projectTemplates.slice(templateIndex + 1) ];
        setProjectTemplates(newState);
        notifySettingsChanged(newState);
    }

    const templateComponents = projectTemplates.map((template: ProjectSessionTemplate, index: number) => {
        return (
            <React.Fragment key={''+index}>
                <Card>
                    <CardContent>
                        <Grid container spacing={6}>
                            <TemplateSettings templateIndex={index} templateSettings={template} metrics={metrics} onSettingsChange={notifyTemplateChanged} deleteTemplate={deleteTemplate}/> 
                        </Grid>
                    </CardContent>
                </Card>
                <Divider my={3} />
            </React.Fragment>
        )
    });

    return (
        <React.Fragment>
            <Card>
                <Accordion
                    expanded={expandedPanel}
                    onChange={handleAccordionChange(!expandedPanel)} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "#FFF"}} />} >
                        <Typography variant="h5">
                            { title }
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        { templateComponents }
                        <Typography variant='h6'>
                            <Button size='large' onClick={onAddBtnClick}>+ Add Template</Button>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Card>
        </React.Fragment>
    );

};

export default TemplateSettingsSection;