import React, { useState } from "react";
import { 
    FormControl, 
    Grid, 
    MenuItem, 
    SelectChangeEvent, 
    TextField, 
    Typography,
    IconButton,
} from "@mui/material";
import { XCircle } from 'react-feather';
import { Box } from "@mui/system";
import { DoughnutOptions } from "../../types/project";

type DoughnutSettingProps = {
    doughnutSettings: DoughnutOptions,
    metrics: string[],
    onSettingsChange: (newSettings: any) => void
}

const DoughnutSetting: React.FC<DoughnutSettingProps>  = ({doughnutSettings, metrics, onSettingsChange}) => {
    // Populate any missing fields
    if (!doughnutSettings['sectors']) {
        doughnutSettings['sectors'] = '';
    }
    if (!doughnutSettings['metric']) {
        doughnutSettings['metric'] = {
            id: "",
            key: "",
            name: "",
            type: "",
            unit: ""
        };
    }
    else {
        doughnutSettings['metric'] = {
            id: doughnutSettings['metric'].id ? doughnutSettings['metric'].id : "",
            key: doughnutSettings['metric'].key ? doughnutSettings['metric'].key : "",
            name: doughnutSettings['metric'].name ? doughnutSettings['metric'].name : "",
            type: doughnutSettings['metric'].type ? doughnutSettings['metric'].type : "",
            unit: doughnutSettings['metric'].unit ? doughnutSettings['metric'].unit : ""
        };
    }
    if (!doughnutSettings['filter']) {
        doughnutSettings['filter'] = {
            key: "",
            op: "",
            value: "",
        };
    }
    else {
        doughnutSettings['filter'] = {
            key: doughnutSettings['filter'].key ? doughnutSettings['filter'].key : "",
            op: doughnutSettings['filter'].op ? doughnutSettings['filter'].op : "",
            value: doughnutSettings['filter'].value ? doughnutSettings['filter'].value : "",
        };
    }

    const [doughnutState, setDoughnutState] = useState(doughnutSettings);

    const handleMetricChange = async (event: React.ChangeEvent<any>| SelectChangeEvent) => {
        const { target: { name, value } } = event;
        let newState;
        switch (name) {
            case 'sectors':
                newState = {...doughnutState, [name] : value };
                break;
            case 'metric.key':
                newState = {...doughnutState, 'metric': { ...doughnutState.metric, id: value + "_metric", key : value }};
                break;
            case 'metric.name':
                newState = {...doughnutState, 'metric': { ...doughnutState.metric, name : value }};
                break;
            case 'metric.type':
                newState = {...doughnutState, 'metric': { ...doughnutState.metric, type : value }};
                break;
            case 'metric.unit':
                newState = {...doughnutState, 'metric': { ...doughnutState.metric, unit : value }};
                break;
            case 'filter.key':
                newState = {...doughnutState, 'filter': { ...doughnutState.filter, key : value }};
                break;
            case 'filter.op':
                newState = {...doughnutState, 'filter': { ...doughnutState.filter, op : value }};
                break;
            case 'filter.value':
                newState = {...doughnutState, 'filter': { ...doughnutState.filter, value : value }};
                break;
            default:
                newState = { ...doughnutState };
        }
        await setDoughnutState(newState);
        onSettingsChange(newState);
    };
    const handleFilterClearButtonClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let newState = {...doughnutState, 'filter': { key: "", op: "", value: "" }};
        await setDoughnutState(newState);
        onSettingsChange(newState);
    };

    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column" >
                        <Typography variant="subtitle2">
                            Metric
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { 
                                m: 1, 
                                width: {
                                    sm: '48%',
                                    md: '23%',
                                    xs: '96%'
                                }
                            },
                        }}
                        noValidate
                        autoComplete="off" >
                        <FormControl>
                            <TextField
                                id="metric.key"
                                value={doughnutState.metric.key }
                                onChange={handleMetricChange}
                                label="Metric"
                                size="small"
                                name="metric.key"
                                select >
                                {metrics.map((metric, index) => (
                                    <MenuItem key={index} value={metric}>{metric}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                        <TextField 
                            id="display-name"
                            label="Display Name"
                            variant="outlined"
                            size="small"
                            defaultValue={doughnutState.metric.name}
                            onChange={handleMetricChange}
                            name="metric.name" />
                        <FormControl>
                            <TextField
                                id="doughnut-metric-type"
                                value={doughnutState.metric.type}
                                onChange={handleMetricChange}
                                label="Type"
                                size="small"
                                name="metric.type"
                                select >
                                <MenuItem value={"average"}>Average</MenuItem>
                                <MenuItem value={"total"}>Total</MenuItem>
                            </TextField>
                        </FormControl>
                        <TextField
                            id="unit"
                            label="Unit"
                            defaultValue={doughnutState.metric.unit}
                            fullWidth
                            size="small"
                            onChange={handleMetricChange}
                            name="metric.unit" />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column">
                        <Typography variant="subtitle2">
                            Filter (optional)
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10} container>
                    <Grid item xs={11}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { 
                                    m: 1, 
                                    width: {
                                        sm: '48%',
                                        md: '32%',
                                        xs: '96%'
                                    }
                                },
                            }}
                            noValidate
                            autoComplete="off" >
                            <FormControl>
                                <TextField
                                    id="filter-key"
                                    value={doughnutState.filter.key}
                                    onChange={handleMetricChange}
                                    label="Metric"
                                    size="small"
                                    name="filter.key"
                                    select >
                                    {metrics.map((metric, index) => (
                                        <MenuItem key={index} value={metric}>{metric}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <FormControl>
                                <TextField
                                    id="filter-op"
                                    value={doughnutState.filter.op}
                                    onChange={handleMetricChange}
                                    label="Operation"
                                    size="small"
                                    name="filter.op"
                                    select >
                                    <MenuItem value={"=="}>{"=="}</MenuItem>
                                    <MenuItem value={"!="}>{"!="}</MenuItem>
                                    <MenuItem value={">"}>{">"}</MenuItem>
                                    <MenuItem value={">="}>{">="}</MenuItem>
                                    <MenuItem value={"<"}>{"<"}</MenuItem>
                                    <MenuItem value={"<="}>{"<="}</MenuItem>
                                </TextField>
                            </FormControl>
                            <TextField 
                                id="filter.value"
                                label="Value"
                                value={doughnutState.filter.value}
                                size="small"
                                onChange={handleMetricChange}
                                name="filter.value" />
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton 
                            aria-label="clear"  
                            onClick={handleFilterClearButtonClick}>
                            <XCircle />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column" >
                        <Typography variant="subtitle2">
                            Sectors
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { 
                                m: 1, 
                                width: {
                                    sm: '47.5%',
                                    xs: '96%'
                                }
                            },
                        }}
                        noValidate
                        autoComplete="off" >
                        <FormControl>
                            <TextField
                                id="doughnut-sectors"
                                value={doughnutState.sectors}
                                onChange={handleMetricChange}
                                label="Sectors"
                                size="small"
                                name="sectors"
                                select >
                                <MenuItem value={'date'}>Date</MenuItem>
                                <MenuItem value={'sessionType'}>Session Type</MenuItem>
                                <MenuItem value={'participantID'}>Participant ID</MenuItem>
                            </TextField>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default DoughnutSetting;