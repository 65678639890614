import {Link} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {Formik} from "formik";
import {Alert as MuiAlert, Button, TextField as MuiTextField} from "@mui/material";
import React from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import {spacing} from "@mui/system";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

type SignUpProps = {
    email?: string,
    onSignUp: (username: string, password: string) => void;
    hideHome? : boolean
}

function SignUp({ email, onSignUp, hideHome }: SignUpProps) {
    const { signUp } = useAuth();
    if (!email) {
        email = "";
    }

    const homeButton = () => {
        if (hideHome) {
            return (
                <React.Fragment/>
            )
        }
        else {
            return (
                <Button
                    component={Link}
                    to="/"
                    fullWidth
                    color="primary"
                >
                    Home
                </Button>
            )
        }
    }
    return (
        <Formik
            initialValues={{
                username: "",
                email: email,
                password: "",
                confirmPassword: "",
                submit: false,
            }}
            validationSchema={Yup.object().shape({
                username: Yup.string()
                    .matches(/^[a-zA-Z0-9]+$/, "No special characters or spaces are allowed")
                    .min(4, "Minimum 4 characters")
                    .max(30, "Maximum 30 characters")
                    .required("Username is required"),
                email: Yup.string()
                    .email("Must be a valid email")
                    .max(255, "Maximum 255 characters")
                    .required("Email is required"),
                password: Yup.string()
                    .matches(/^[a-zA-Z0-9_!@#$%^&*]+$/, "Passwords may only contain numbers, letters, _, !, @, #, $, %, ^, &, or *")
                    .min(6, "Minimum 6 characters")
                    .max(255, "Maximum 255 characters")
                    .required("Password is required"),
                confirmPassword: Yup.string()
                    .required("Re-type password")
                    .when("password", {
                        is: (val: any) => (val && val.length > 0 ? true : false),
                        then: Yup.string().oneOf(
                            [Yup.ref("password")],
                            "Both password needs to be the same"
                        ),
                    }),
            })}

            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    await signUp(values.username, values.email, values.password);
                    onSignUp(values.username, values.password);

                } catch (error: any) {
                    const message = error.raw || "Something went wrong";
                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                }
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  isValid,
              }) => (
                <form onSubmit={handleSubmit}>
                    {errors.submit && (
                        <Alert mt={2} mb={1} severity="warning">
                            {errors.submit}
                        </Alert>
                    )}
                    <TextField
                        type="text"
                        name="username"
                        label="Username"
                        value={values.username}
                        error={Boolean(touched.username && errors.username)}
                        fullWidth
                        helperText={touched.username && errors.username}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                    />
                    <TextField
                        type="email"
                        name="email"
                        label="Email"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                    />
                    <TextField
                        type="password"
                        name="password"
                        label="Password"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                    />
                    <TextField
                        type="password"
                        name="confirmPassword"
                        label="Confirm password"
                        value={values.confirmPassword}
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        fullWidth
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!isValid}
                    >
                        Sign up
                    </Button>
                    { homeButton() }
                </form>
            )}
        </Formik>
    );
}

export default SignUp;