import React, { useEffect } from 'react';
import useProjectData from "../../hooks/useProjectData";
import useNavigationData from "../../hooks/useNavigation";

import {
    Typography as MuiTypography,
    Divider as MuiDivider,
} from "@mui/material";

import DataTable from "../../components/table/DataTable";
import styled from "styled-components/macro";
import { spacing, SpacingProps } from "@mui/system";
import { DataFileOptions } from '../../types/project';

interface TypographyProps extends SpacingProps {
    component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Divider = styled(MuiDivider)(spacing);

function ProjectData() {

    const { 
        getDataFiles,
        dataFiles,
        dataFilesLoading,
        selectedProject,
        userRole,
        participantList,
        projectSettings,
    } = useProjectData();
    const { setProject } = useNavigationData();
    
    useEffect(() => {
        if (projectSettings) {
            setProject(projectSettings.name, userRole);
        }
    }, [projectSettings, setProject, userRole]);

    useEffect(() => {
        const load = async () => {
            let options: DataFileOptions = {
                project: selectedProject,
            }
            if (participantList) {
                const participantIDs: string[] = [];
                for (let i = 0; i < participantList.length; i++) {
                    let participant = participantList[i];
                    if (participant && participant.participantID) {
                        participantIDs.push(participantList[i].participantID);
                    }
                }
                if (participantIDs.length > 0) options.keywords = participantIDs;
            }
            await getDataFiles(options);
        }
        if (!dataFiles && !dataFilesLoading) {
            load();
        }
    }, [getDataFiles, dataFiles, dataFilesLoading, selectedProject, participantList]);

    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
            Data Files
            </Typography>
            <Divider my={6} />
            <DataTable />
        </React.Fragment>
    );
}

export default ProjectData;