import React, { useEffect, useContext } from "react";

import { ThemeContext } from "styled-components/macro";
import styled from "styled-components/macro";
import { PermissionLevel } from "../../utils/constants";
import useAuth from "../../hooks/useAuth";
import {
    Card as MuiCard,
    CardActionArea,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Grid,
    Box,
    Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useProjectData from "../../hooks/useProjectData";
import { useNavigate } from "react-router-dom";
import useNavigationData from "../../hooks/useNavigation";
import useParticipantData from '../../hooks/useParticipantData';
import DataArea from "../../components/common/DataArea";
import SimpleChart from "../../components/charts/SimpleChart";
import { DataAreaSettings, ProjectOverviewSettings } from '../../types/project';
import { convertDataAreaToStatisticsAPIRequest as createChartAPIRequest } from "../../utils/charts";
import { ChartFilterElement } from "../../types/chart";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;
const Divider = styled(MuiDivider)(spacing);
const SectionTitle = styled.div`
  background: ${(props) => props.theme.seasPalette.blue};
  color: #FFF;
  margin: 24px -48px 0px -48px;
  padding: 24px 0px 24px 48px;
`;

type ParticipantCardProps = {
    image?: string;
    title: string;
    plot: any;
    stats: { name: string, value: string }[];
    handleClick: (project: string) => void;
};

const ParticipantCard: React.FC<ParticipantCardProps> = ({
        title,
        plot,
        stats,
        handleClick,
    }) => {
    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleClick(title);
    };

    const participantStats = stats.map((stat, index) => {
        return (
            <Typography variant="h6" mb={3} key={index}>
                <Box fontWeight="fontWeightRegular">{stat.value} {stat.name}</Box>
            </Typography>
        );
    });

    return (
        <Card>
            <CardActionArea onClick={onClick}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <br />
                    <Grid container spacing={6}>
                        <Grid item xs={4} lg={4} xl={4}>        
                            {participantStats}
                        </Grid>
                        <Grid item xs={8} >
                            <SimpleChart height={100} title={'10 Meter Walk Test Speed'} data={plot} type={'line'}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

function generateSimpleChartData(data: any, theme: any) {
    data = data || {};
    if (!data.plots || data.plots.length < 2) return [];
    // Determine how many data points and how much to compress
    let first = new Date(data.plots[0].date.replace(/-/g,'/'));
    let last = new Date(data.plots[data.plots.length-1].date.replace(/-/g,'/'));
    let dateRange = (last.getTime()-first.getTime())/(24*60*60*1000);
    let dataCollapsed;
    if (dateRange > 365) {
        dataCollapsed = collapseData(data.plots, 'year');
    }
    else if (dateRange > 50) {
        dataCollapsed = collapseData(data.plots, 'month');
    }
    else {
        dataCollapsed = collapseData(data.plots, 'date');
    }

    return {
        labels: dataCollapsed.labels,
        datasets: [{
            label: data.project,
            fill: false,
            borderColor: theme.seasPalette.skyBlue,
            backgroundColor: theme.seasPalette.skyBlue,
            data: dataCollapsed.data,
            elements: {
                point: {
                    radius: 0
                }
            }
        }],
    }
}
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function getLabel(date: Date, type: string) {
    switch (type) {
        case 'year':
            return date.getFullYear();
        case 'month':
            return monthNames[date.getMonth()]?.substring(0,3) + " '" + date.getFullYear().toString().substring(2);
        case 'date':
        default:
            return date.getDate() + " " + monthNames[date.getMonth()]?.substring(0,3);
    }
}

function collapseData(data: any, type: string) {
    let currentDate;
    let labels = [];
    let currentValue = 0;
    let currentCount = 0;
    let dataSet = [];
    let currentLabel;
    const metric = '10MWTSpeed';
    for (let i = 0; i < data.length; i++) {
        currentDate = new Date(data[i].date.replace(/-/g,'/'));
        let label = getLabel(currentDate, type);
        if (label !== currentLabel) {
            if (i !== 0) {
                // ignore first element
                if (currentValue > 0) {
                    labels.push(currentLabel);
                    dataSet.push({
                        x: currentLabel,
                        y: currentValue / Math.max(1, currentCount),
                    })
                }
            }
            currentLabel = label;
            currentValue = 0;
            currentCount = 0;
        }
        if (data[i][metric]) {
            currentValue += data[i][metric];
            currentCount++;
        }
    }
    if (currentValue > 0) {
        labels.push(currentLabel);
        dataSet.push({
            x: currentLabel,
            y: currentValue / Math.max(1, currentCount),
        })
    }
    return {
        labels: labels,
        data: dataSet
    };
}

function getParticipantTotal(data: Record<string, any>[], metric: string) {
    let currentValue = 0;
    for (let i = 0; i < data.length; i++) {
        if (data[i][metric]) {
            currentValue += data[i][metric];
        }
    }
    return currentValue;
}

function ProjectOverview() {
    const navigate = useNavigate();
    const theme = useContext(ThemeContext);
    const { user } = useAuth();
    const { setProject } = useNavigationData();
    const { selectParticipant } = useParticipantData();
    const { 
        participantList, 
        participantListLoading,
        loadParticipants, 
        projectSettings, 
        userRole, 
        selectedProject, 
        loadChartOneData,
        chartOneLoading, 
        chartOneData,
        chartOneFilter,
        updateChartOneFilter,
        loadChartTwoData, 
        chartTwoLoading,
        chartTwoData,
        chartTwoFilter,
        updateChartTwoFilter,
    } = useProjectData();

    useEffect(() => {
        // User is not authorized for this page, navigate to participant page
        if (userRole < PermissionLevel.Clinician) {
            let participantID = user ? user.username : "";
            selectParticipant(participantID);
            navigate("/participant/overview");
        }
    }, [userRole, user, navigate, selectParticipant]);

    const handleClick = (participant: string) => {
        selectParticipant(participant);
        navigate("/participant/overview");
    };
    // for navigating to this page from any point.
    useEffect(() => {
        if (projectSettings) {
            setProject(projectSettings.name, userRole);
        }
    }, [projectSettings, setProject, userRole]);

    useEffect(() => {
        const load = async () => {
            await loadParticipants(selectedProject);
        };
        if (!participantList && !participantListLoading) {
            load();
        }
    }, [loadParticipants, participantList, participantListLoading, selectedProject]);

    useEffect(() => {
        if (participantList && !chartOneFilter) {
            let filteredParticipantList: ChartFilterElement[] = [];
            filteredParticipantList = participantList.map(p => {
                return {'participantID': p.participantID, 'filtered': true};
            });
            for (let i = 0; i < 3; i++) {
                if (i < filteredParticipantList.length) {
                    filteredParticipantList[i].filtered = false;
                }
            }
            updateChartOneFilter(filteredParticipantList);
        }
    }, [participantList, chartOneFilter, updateChartOneFilter]);

    useEffect(() => {
        if (participantList && !chartTwoFilter) {
            let filteredParticipantList: ChartFilterElement[] = [];
            filteredParticipantList = participantList?.map(p => {
                return {'participantID': p.participantID, 'filtered': true};
            });
            for (let i = 0; i < 3; i++) {
                if (i < filteredParticipantList.length) {
                    filteredParticipantList[i].filtered = false;
                }
            }
            updateChartTwoFilter(filteredParticipantList);
        }
    }, [participantList, chartTwoFilter, updateChartTwoFilter]);

    useEffect(() => {
        const load = async () => {
            const chartOneOptions = projectSettings!.settings.projectOverview.dataAreaOne.options;
            let apiRequestOptions = createChartAPIRequest(selectedProject, chartOneOptions);
            await loadChartOneData(apiRequestOptions);
        };
        if (!chartOneData && !chartOneLoading && participantList) {
            load();
        }
    }, [projectSettings, loadChartOneData, chartOneData, chartOneLoading, selectedProject, participantList]);

    useEffect(() => {
        const load = async () => {
            const chartTwoOptions = projectSettings!.settings.projectOverview.dataAreaTwo.options;
            let apiRequestOptions = createChartAPIRequest(selectedProject, chartTwoOptions);
            await loadChartTwoData(apiRequestOptions);
        };
        if (!chartTwoData && !chartTwoLoading) {
            load();
        }
    }, [projectSettings, loadChartTwoData, chartTwoData, chartTwoLoading, selectedProject]);

    let participantGrid;
    if (participantList && participantList.length > 0) {
        participantGrid = participantList.map((participant) => {
            return (
                <Grid item xs={12} md={6} lg={4} xl={3} key={participant.participantID}>
                    <ParticipantCard
                        title={participant.participantID}
                        plot={generateSimpleChartData(participant, theme)}
                        stats={[
                            { name: 'Training Days', value: `${participant.trainingDays}`}, 
                            { name: 'Assisted Steps', value: `${getParticipantTotal(participant.plots, 'assistedSteps')}`}
                        ]}
                        handleClick={handleClick}
                    />
                </Grid>
            );
        });
    }
    else {
        participantGrid = (
            <Grid item xs={12} lg={12} xl={12}>
                <Card>
                    <CardContent>{"No participants to show!"}</CardContent>
                </Card>
            </Grid>
        )
    }

    const dataAreasList = projectSettings ? Object.keys(projectSettings!.settings.projectOverview).filter(k => k.includes('dataArea')) as (keyof ProjectOverviewSettings)[] : [];
    const dataAreas = dataAreasList.map((area, index) => {
        let dataAreaSettings: DataAreaSettings = projectSettings!.settings.projectOverview[area] as DataAreaSettings;
        switch (area) {
            case 'dataAreaOne':
                return <DataArea 
                            key={index}
                            dataAreaSettings={dataAreaSettings}
                            chartData={chartOneData || []}
                            chartFilter={chartOneFilter || []}
                            updateChartFilter={updateChartOneFilter} />
            case 'dataAreaTwo':
                return <DataArea 
                            key={index}
                            dataAreaSettings={dataAreaSettings}
                            chartData={chartTwoData || []}
                            chartFilter={chartTwoFilter || []}
                            updateChartFilter={updateChartTwoFilter} />
            default:
                return <React.Fragment key={index} />
        }  
    })

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h3" gutterBottom display="inline">
                    {projectSettings!.name}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider my={6} />
            </Grid>
            <Grid item xs={12} container spacing={6}>
                { dataAreas }
            </Grid>
            <Grid item xs={12}>
                <SectionTitle>
                    <Typography variant='h1'>
                        Participants
                    </Typography>
                </SectionTitle>
            </Grid>
            <Grid item container sx={{mt: '24px'}} spacing={6}>
                {participantGrid}
            </Grid>
        </Grid>
    );
}

export default ProjectOverview;
