import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Divider as MuiDivider,
    Card as MuiCard,
    CardContent,
    TextField as MuiTextField,
    Paper,
    Box,
    Button, CardHeader as MuiCardHeader,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import _ from 'lodash';
import PageSetting from "../../components/setting/PageSetting";
import TemplateSettingsSection from "../../components/setting/TemplateSettingsSection";
import { Save as SaveIcon } from "@mui/icons-material";
import useProjectData from "../../hooks/useProjectData";
import useNavigationData from "../../hooks/useNavigation";
import { OverviewPageSettingType, ParticipantOverviewSettings, ProjectOverviewSettings, ProjectSessionTemplate, ProjectStudy } from '../../types/project';
import DeviceSettings from '../../components/setting/DeviceSettings';
import MetricSettings from '../../components/setting/MetricSettings';
import StudySettings from '../../components/setting/StudySettings';

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const CardHeader = styled(MuiCardHeader)`
  min-height: 64px;
  background: ${(props) => props.theme.seasPalette.blue};
  color: #FFF;
`;

function ProjectSettings() {
    const { projectSettings, setProjectSettings, userRole } = useProjectData();
    const { setProject } = useNavigationData();
    // for navigating to this page from any point.
    useEffect(() => {
        if (projectSettings) {
            setProject(projectSettings.name, userRole);
        }
    }, [projectSettings, setProject, userRole]);
    // deep clone settings object
    let projectCopy = _.cloneDeep(projectSettings);
    const [newProjectSettings, setNewProjectSettings] = useState(projectCopy);

    const handleProjectNameChange = (event: React.ChangeEvent<any>) => {
        newProjectSettings!.name = event.target.value;
        setNewProjectSettings(newProjectSettings);
        setSettingsHaveChanged(true);
    };

    const handleProjectOverallChange = (event: React.ChangeEvent<any>) => {
        newProjectSettings!.description = event.target.value;
        setNewProjectSettings(newProjectSettings);
        setSettingsHaveChanged(true);
    };

    const [settingsHaveChanged, setSettingsHaveChanged] = useState(false);

    const saveProjectSettings = () => {
        // save to database
        if (newProjectSettings === null) return;
        setProjectSettings(newProjectSettings);
        setSettingsHaveChanged(false);
    }

    const projectOverviewChanged = (settings: OverviewPageSettingType) => {
        if (!newProjectSettings) return;
        newProjectSettings.settings.projectOverview = settings as ProjectOverviewSettings;
        setNewProjectSettings(newProjectSettings);
        setSettingsHaveChanged(true);
    }
    const participantOverviewChanged = (settings: OverviewPageSettingType) => {
        if (!newProjectSettings) return;
        newProjectSettings.settings.participantOverview = settings as ParticipantOverviewSettings;
        setNewProjectSettings(newProjectSettings);
        setSettingsHaveChanged(true);
    }
    const templatesChanged = (templates: ProjectSessionTemplate[]) => {
        if (!newProjectSettings) return;
        newProjectSettings.templates = templates;
        setNewProjectSettings(newProjectSettings);
        setSettingsHaveChanged(true);
    }
    const devicesChanged = (devices: string[]) => {
        if (!newProjectSettings) return;
        newProjectSettings.devices = devices;
        setNewProjectSettings(newProjectSettings);
        setSettingsHaveChanged(true);
    }
    const studiesChanged = (studies: ProjectStudy[]) => {
        if (!newProjectSettings) return;
        newProjectSettings.studies = studies;
        setNewProjectSettings(newProjectSettings);
        setSettingsHaveChanged(true);
    }
    const metricsChanged = (metrics: string[]) => {
        if (!newProjectSettings) return;
        newProjectSettings.metrics = metrics;
        setNewProjectSettings(newProjectSettings);
        setSettingsHaveChanged(true);
    }

    if (newProjectSettings) {
        return (
            <React.Fragment>
                <Helmet title="Settings" />
                <Typography variant="h3" gutterBottom display="inline">
                    Settings
                </Typography>
                <Divider my={6} />
                <Card mb={6}>
                    <CardHeader 
                        title="Overall"
                        titleTypographyProps={{ variant: 'h5' }} />
                    <CardContent>
                        <Grid container spacing={6}>
                            <Grid item xs={2}>
                                <Box
                                    height="100%"
                                    display="flex"
                                    justifyContent="center"
                                    flexDirection="column" >
                                    <Typography variant="subtitle2">
                                        Name
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    id="project-name"
                                    defaultValue={newProjectSettings!.name}
                                    variant="outlined"
                                    fullWidth
                                    my={2}
                                    size="small"
                                    name="name"
                                    onChange={handleProjectNameChange} />
                            </Grid>
                            <Grid item xs={2}>
                                <Box
                                    height="100%"
                                    display="flex"
                                    justifyContent="center"
                                    flexDirection="column" >
                                    <Typography variant="subtitle2">
                                        Description
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    id="project-description"
                                    defaultValue={newProjectSettings!.description}
                                    variant="outlined"
                                    fullWidth
                                    my={2}
                                    size="small"
                                    name="description"
                                    onChange={handleProjectOverallChange} />
                            </Grid>
                            <Grid item xs={2}>
                                <Box
                                    height="100%"
                                    display="flex"
                                    justifyContent="center"
                                    flexDirection="column" >
                                    <Typography variant="subtitle2">
                                        Key
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    id="project-key"
                                    defaultValue={newProjectSettings!.key}
                                    variant="outlined"
                                    fullWidth
                                    my={2}
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <MetricSettings 
                            metricList={newProjectSettings!.metrics}
                            title={"Metrics"} 
                            expanded 
                            notifySettingsChanged={metricsChanged}/>
                    </Grid>
                    <Grid item xs={12}>
                        <PageSetting settingsObject={newProjectSettings!.settings.projectOverview}
                                     settingTitle="Project Overview" metrics={newProjectSettings.metrics} expanded={true} notifySettingsChanged={projectOverviewChanged} />
                    </Grid>
                    <Grid item xs={12}>
                        <PageSetting settingsObject={newProjectSettings!.settings.participantOverview}
                                     settingTitle="Participant Overview" metrics={newProjectSettings.metrics} expanded={true} notifySettingsChanged={participantOverviewChanged}/>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <PageSetting settingsObject={savedProjectSettings!.settings.sessionOverview}*/}
                    {/*                 settingTitle="Session Overview" metrics={newProjectSettings.metrics} expanded={true} />*/}
                    {/*</Grid>*/} 
                    <Grid item xs={12}>
                        <DeviceSettings 
                            deviceList={newProjectSettings!.devices}
                            title={"Devices"} 
                            expanded 
                            notifySettingsChanged={devicesChanged}/>
                    </Grid>
                    <Grid item xs={12}>
                        <StudySettings 
                            studyList={newProjectSettings!.studies}
                            title={"Studies"} 
                            expanded 
                            notifySettingsChanged={studiesChanged}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TemplateSettingsSection 
                            templates={newProjectSettings!.templates} 
                            metrics={newProjectSettings!.metrics}
                            title={"Templates"} 
                            expanded 
                            notifySettingsChanged={templatesChanged} />
                    </Grid>
                </Grid>             
                { settingsHaveChanged && 
                    <Paper sx={{ borderTop: '1px solid cornflowerblue', borderRadius: '0px', position: 'fixed', bottom: 0, right: 16, zIndex: 100, left: { xs: 0, md: 258 }}} elevation={3}>
                            <Button
                                sx={{width:'200px', ml: `calc(50% - 100px)`, mt: '25px', mb: '25px' }}
                                variant="contained"
                                color="primary"
                                onClick={saveProjectSettings}
                                startIcon={<SaveIcon sx={{ mr: 1 }} />} >
                                Save changes 
                            </Button>
                    </Paper>
                }
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
}

export default ProjectSettings;