import { createContext, ReactNode, useReducer } from "react";

import { ActionMap } from "../types/auth";
import {
    ProjectContextType,
    ProjectState,
    ProjectList,
    ProjectSettingType,
    DataFileOptions,
    DataFile,
    UserList,
    ParticipantList, 
    ProjectSetting,
    StatisticsAPIRequest,
} from "../types/project";

import axios from "../utils/axios";
import { ChartFilterElement, ChartData, ChartSeries } from "../types/chart";
import { Participant } from '../types/participant';
import { getDateStringDifference } from "../utils/datetime";

const INITIALIZE = "INITIALIZE";
const LOAD_PROJECTS = "LOAD_PROJECTS";
const SET_PROJECT_SETTINGS = "SET_PROJECT_SETTINGS";
const LOADING_PROJECTS = "LOADING_PROJECTS";
const SELECT_PROJECT = "SELECT_PROJECT";
const GET_DATA_FILES = "GET_DATA_FILES";
const LOADING_DATA_FILES = "LOADING_DATA_FILES";
const LOAD_PARTICIPANTS = "LOAD_PARTICIPANTS";
const LOAD_CHART_ONE_DATA = "LOAD_CHART_ONE_DATA";
const LOAD_CHART_TWO_DATA = "LOAD_CHART_TWO_DATA";
const LOADING_PARTICIPANTS = "LOADING_PARTICIPANTS";
const LOADING_CHART_ONE_DATA = "LOADING_CHART_ONE_DATA";
const LOADING_CHART_TWO_DATA = "LOADING_CHART_TWO_DATA";
const LOADING_PROJECT_USERS = "LOADING_PROJECT_USERS";
const UPDATE_PARTICIPANTS = "UPDATE_PARTICIPANTS";
const UPDATE_CHART_ONE_FILTER = "UPDATE_CHART_ONE_FILTER";
const UPDATE_CHART_TWO_FILTER = "UPDATE_CHART_TWO_FILTER";
const GET_PROJECTS_USERS = "GET_PROJECTS_USERS";


type ProjectActionTypes = {
    [INITIALIZE]: {
        projectList: ProjectList;
    };
    [LOAD_PROJECTS]: {
        projectList: ProjectList;
    };
    [LOADING_PARTICIPANTS]: undefined;
    [SELECT_PROJECT]: {
        selectedProject: string;
        projectSettings: ProjectSettingType;
        userRole: number;
    };
    [LOAD_PARTICIPANTS]: {
        participantList: ParticipantList;
    };
    [GET_DATA_FILES]: {
        dataFiles: DataFile[];
    };
    [GET_PROJECTS_USERS]: {
        userList: UserList;
    };
    [SET_PROJECT_SETTINGS]: {
        projectSettings: ProjectSetting;
    };
    [LOAD_CHART_ONE_DATA]: {
        chartData: any;
    };
    [LOADING_CHART_ONE_DATA]: undefined;
    [LOAD_CHART_TWO_DATA]: {
        chartData: any;
    };
    [LOADING_CHART_TWO_DATA]: undefined;
    [UPDATE_CHART_ONE_FILTER]: {
        filter: ChartFilterElement[];
    };
    [UPDATE_CHART_TWO_FILTER]: {
        filter: ChartFilterElement[];
    };
    [UPDATE_PARTICIPANTS]: {
        participantList: ParticipantList;
    };
    [LOADING_DATA_FILES]: undefined;
    [LOADING_PROJECTS]: undefined;
    [LOADING_PROJECT_USERS]: undefined;
};

const initialState: ProjectState = {
    userRole: -1,
    projectList: [],
    projectListLoading: false,
    selectedProject: "",
    participantList: null,
    participantListLoading: false,
    projectSettings: null,
    dataFiles: null,
    dataFilesLoading: false,
    userList: null,
    userListLoading: false,
    chartOneData: null,
    chartOneFilter: null,
    chartTwoData: null,
    chartTwoFilter: null,
    chartOneLoading: false,
    chartTwoLoading: false,
};

const ProjectReducer = (
    state: ProjectState,
    action: ActionMap<ProjectActionTypes>[keyof ActionMap<ProjectActionTypes>]
) => {
    switch (action.type) {
        case SET_PROJECT_SETTINGS:
            return {
                ...state,
                projectSettings: action.payload.projectSettings,
                projectList: [],
                userList: [],
                participantList: null,
                chartOneData: null,
                chartTwoData: null,
                dataFiles: null,
            }
        case LOAD_PROJECTS:
            return {
                ...state,
                projectList: action.payload.projectList,
                projectListLoading: false,
            };
        case SELECT_PROJECT:
            return {
                ...state,
                selectedProject: action.payload.selectedProject,
                projectSettings: action.payload.projectSettings,
                userRole: action.payload.userRole,
                participantList: null,
                chartOneData: null,
                chartTwoData: null,
                dataFiles: null,
            };
        case GET_DATA_FILES:
            return {
                ...state,
                dataFiles: action.payload.dataFiles,
                dataFilesLoading: false,
            };
        case GET_PROJECTS_USERS:
            return {
                ...state,
                userList: action.payload.userList,
                userListLoading: false,
            };
        case LOAD_PARTICIPANTS:
            return {
                ...state,
                participantList: action.payload.participantList,
                participantListLoading: false,
            };
        case LOADING_PARTICIPANTS:
            return {
                ...state,
                participantListLoading: true,
            }
        case LOAD_CHART_ONE_DATA:
            return {
                ...state,
                chartOneData: action.payload.chartData,
                chartOneLoading: false
            };
        case LOAD_CHART_TWO_DATA:
            return {
                ...state,
                chartTwoData: action.payload.chartData,
                chartTwoLoading: false
            };
        case LOADING_CHART_ONE_DATA:
            return {
                ...state,
                chartOneLoading: true,
            };
        case LOADING_CHART_TWO_DATA:
            return {
                ...state,
                chartTwoLoading: true,
            };
        case UPDATE_CHART_ONE_FILTER:
            return {
                ...state,
                chartOneFilter: action.payload.filter,
            };
        case UPDATE_CHART_TWO_FILTER:
            return {
                ...state,
                chartTwoFilter: action.payload.filter,
            };
        case LOADING_DATA_FILES:
            return {
                ...state,
                dataFilesLoading: true,
            };
        case LOADING_PROJECTS:
            return {
                ...state,
                projectListLoading: true,
            };
        case LOADING_PROJECT_USERS:
            return {
                ...state,
                userListLoading: true,
            };
        default:
            return state;
    }
};

const ProjectContext = createContext<ProjectContextType | null>(null);

function ProjectProvider({ children }: { children: ReactNode }) {
    const [state, dispatch] = useReducer(ProjectReducer, initialState);

    const loadProjects = async (projects: string[]) => {
        dispatch({ type: LOADING_PROJECTS });
        const response = await axios.get("/api/project/summaries", {
            params: { projects: projects },
        });
        const { projectList } = response.data;
        dispatch({
            type: LOAD_PROJECTS,
            payload: {
                projectList,
            },
        });
    };

    const setProjectSettings = async (project: ProjectSettingType) => {
        dispatch({
            type: SET_PROJECT_SETTINGS,
            payload: {
                projectSettings: project,
            },
        });
        await axios.post("/api/project", {
            project
        });
    };

    const getUsers = async (project: string) => {
        dispatch({ type: LOADING_PROJECT_USERS });
        const response = await axios.get("/api/project/listUsers", {
            params: { project: project },
        });
        const { userlist } = response.data;
        dispatch({
            type: GET_PROJECTS_USERS,
            payload: {
                userList: userlist,
            },
        });
    }

    const getDataFiles = async (options: DataFileOptions) => {
        dispatch({ type: LOADING_DATA_FILES });
        const response = await axios.get("/api/data/listFiles", {
            params: options,
        });
        const { data } = response.data;
        dispatch({
            type: GET_DATA_FILES,
            payload: {
                dataFiles: data,
            },
        });
    };

    const selectProject = async (projectKey: string, role: number) => {
        if (state.selectedProject !== projectKey) {
            // Only dispatch a new project, if the selected project is different than the current project selected
            // This will cause less reloading of resources if selecting the same project twice in a row
            const response = await axios.get("/api/project", {
                params: { project: projectKey },
            });
            const projectSettings = response.data.project;
            dispatch({
                type: SELECT_PROJECT,
                payload: {
                    selectedProject: projectKey,
                    projectSettings,
                    userRole: role,
                },
            });
        }
    };

    const loadParticipants = async (project: string) => {
        dispatch({ type: LOADING_PARTICIPANTS });
        const response = await axios.get("/api/project/statistics", {
            params: { 
                project: project,
                domain: 'date',
                metrics: [
                    {
                        id: "10MWTSpeed",
                        key: "10MWTSpeed",
                        type: "average"
                    },
                    {
                        id: "assistedSteps",
                        key: "assistedSteps",
                        type: "total"
                    }
                ],
            },
        });
        let { data } = response.data as { data: ChartData };
        let participants: ParticipantList = [];
        if (data) {
            // Convert to Participant type
            participants = data.map((item: ChartSeries) => {
                return {
                    participantID: item.name,
                    plots: item.plots,
                    sessions: item.sessions,
                    trainingDays: item.trainingDays,
                }
            })
            participants.sort((a: Participant, b: Participant) => {
                let aDate = "01-01-2000";
                if (a.plots) {
                    for (let i = a.plots.length - 1; i >= 0; i--) {
                        let dataPoint = a.plots[a.plots.length-1];
                        if (dataPoint.date) {
                            aDate = dataPoint.date;
                            break;
                        }
                    }
                }
                let bDate = "01-01-2000";
                if (b.plots) {
                    for (let i = b.plots.length - 1; i >= 0; i--) {
                        let dataPoint = b.plots[b.plots.length-1];
                        if (dataPoint.date) {
                            bDate = dataPoint.date;
                            break;
                        }
                    }
                }
                let dateDifference = getDateStringDifference(aDate, bDate);
                return dateDifference;
            })
        }
        dispatch({
            type: LOAD_PARTICIPANTS,
            payload: {
                participantList: participants,
            },
        });
    };

    const loadChartOneData = async (request: StatisticsAPIRequest) => {
        dispatch({ type: LOADING_CHART_ONE_DATA });
        const response = await axios.get("/api/project/statistics", { params: { ...request } });
        const { data } = response.data;
        dispatch({
            type: LOAD_CHART_ONE_DATA,
            payload: {
                chartData: data,
            },
        });
    };

    const loadChartTwoData = async (request: StatisticsAPIRequest) => {
        dispatch({ type: LOADING_CHART_TWO_DATA });
        const response = await axios.get("/api/project/statistics", { params: { ...request } });
        const { data } = response.data;
        dispatch({
            type: LOAD_CHART_TWO_DATA,
            payload: {
                chartData: data,
            },
        });
    };

    const updateChartOneFilter = (filter: ChartFilterElement[]) => {
        dispatch({
            type: UPDATE_CHART_ONE_FILTER,
            payload: {
                filter: filter,
            },
        });
    }

    const updateChartTwoFilter = (filter: ChartFilterElement[]) => {
        dispatch({
            type: UPDATE_CHART_TWO_FILTER,
            payload: {
                filter: filter,
            },
        });
    }

    const uploadSession = async (project: string, session: any) => {
        const response = await axios.post("/api/session", { project, session });
        const { data } = response.data;
        return data;
    }

    const sendInvite = async (project: string, userDetails: any) => {
        const response = await axios.post("/api/project/inviteUser", { project, userDetails})
        return response.data.pendingInvite;
    }

    const addUserToProject = async (user: any, project: string) => {
        const response = await axios.post("/api/project/acceptInvite", {user, project});
        return response.data.userAdded;
    }

    const updateProjectUsers = async (project: string, users: UserList) => {
        const response = await axios.post("/api/project/updateUsers", {
            project, users
        });
        return response.data.error;
    };

    return (
        <ProjectContext.Provider
            value={{
                ...state,
                setProjectSettings,
                loadProjects,
                loadParticipants,
                getDataFiles,
                getUsers,
                selectProject,
                loadChartOneData,
                loadChartTwoData,
                updateChartOneFilter,
                updateChartTwoFilter,
                uploadSession,
                sendInvite,
                addUserToProject,
                updateProjectUsers,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
}

export { ProjectContext, ProjectProvider };
