const toAmPmTimeString = (time: string) => {
    const parts = time.split('-');
    if (parts.length !== 3) return time;
    const dateProxy = new Date(2000, 1, 1, parseInt(parts[0]), parseInt(parts[1]), parseInt(parts[2]));
    return dateProxy.toLocaleTimeString();
};

const toReadableDateString = (date: string) => {
    const parts = date.split('-');
    if (parts.length !== 3) return date;
    const dateProxy = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]));
    return dateProxy.toDateString();
}

const getDateStringDifference = (first: string, second: string) => {
    let firstDate = new Date(first);
    let secondDate = new Date(second);
    return Math.floor((secondDate.getTime() - firstDate.getTime()) / (60*60*24*1000));
}

const getDateDifference = (firstDate: Date, secondDate: Date) => {
    return Math.floor((secondDate.getTime() - firstDate.getTime()) / (60*60*24*1000));
}

const getDateTimeString = () => {
    return (new Date()).toJSON().split('.')[0].replace(/[:-]/g, '');
}

export { toAmPmTimeString, toReadableDateString, getDateDifference, getDateStringDifference, getDateTimeString }