import React from "react";
import styled from "styled-components/macro";
import { Card as MuiCard } from "@mui/material";
import { spacing } from "@mui/system";
import DataFilter from "../common/DataFilter";
import BarChart from "./BarChart";
import DoughnutChart from "./DoughnutChart";
import LineChart from "./LineChart";
import ScatterPlot from "./ScatterPlot";
import MixedChart from "./MixedChart";
import { DataAreaSettings, ChartOptions, MixedChartOptions, DoughnutOptions } from '../../types/project';
import { ChartData, ChartFilterElement } from '../../types/chart';

const Card = styled(MuiCard)(spacing);

type ChartProps = {
    height: number,
    dataAreaSettings: DataAreaSettings,
    chartData: ChartData,
    chartFilter?: ChartFilterElement[],
    onFilterChanged: (filter: ChartFilterElement[]) => void,
}

const ChartComponent = ({height, dataAreaSettings, chartData, chartFilter, onFilterChanged }: ChartProps) => {
    chartFilter = chartFilter || [];
    const showDataFilter = () => {
        if (chartFilter && chartFilter!.length > 0) {
            return (
                <DataFilter filterTitle={'Participants'}
                            filterKey={'participantID'}
                            filterOptions={chartFilter}
                            onFilterChanged={onFilterChanged}/>
            )
        } 
        else {
            return (<React.Fragment />)
        }
    }
    
    const isFiltered = (id: string) => {
        if (!id) return false;
        if (!chartFilter || chartFilter.length === 0) return false;
        for (let i = 0; i < chartFilter.length; i++) {
            if (chartFilter[i].participantID === id) {
                return !!chartFilter[i].filtered;
            }
        }
        return false;
    }

    let getChart;
    switch (dataAreaSettings.type) {
        case 'line':
            getChart = <LineChart 
                            height={400}
                            chartOptions={dataAreaSettings.options as ChartOptions}
                            chartData={chartData}
                            isFiltered={isFiltered} />
            break;
        case 'bar':
            getChart = <BarChart 
                            height={height} 
                            chartOptions={dataAreaSettings.options as ChartOptions}
                            chartData={chartData}
                            isFiltered={isFiltered} />
            break;
        case 'scatter':
            getChart = <ScatterPlot 
                            height={400}
                            chartOptions={dataAreaSettings.options as ChartOptions}
                            chartData={chartData}
                            isFiltered={isFiltered} />
            break;
        case 'mixed':
            getChart = <MixedChart 
                            height={400} 
                            chartOptions={dataAreaSettings.options as MixedChartOptions}
                            chartData={chartData}
                            isFiltered={isFiltered} />
            break;
        case 'doughnut':
            getChart = <DoughnutChart
                            height={400} 
                            doughnutOptions={dataAreaSettings.options as DoughnutOptions}
                            chartData={chartData}
                            isFiltered={isFiltered} />
            break;
        default:
            getChart = <React.Fragment />;
            break;
    }
    return (
        <Card mb={1}>
            { getChart }
            { showDataFilter() }
        </Card>
    );
};

export default ChartComponent;
